import {
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import React, { Fragment, } from "react";

import dayjs from "dayjs";
import DocumentViewer from "../Shared/DocumentViewer";
import { currencySymbolFormat } from "../../Utils/GeneralUtils";

const PaymentCardMediaItem = ({
  title,
  totalJobLines,
  totalExchangedJobLines,
  currency = "NZD",
}) => {
  const formatDate = (date) => {
    if (date) {
      return dayjs(date).format("DD/MM/YYYY");
    }
    return date;
  };

  return (
    <Fragment>
      <Card
        // sx={{ minWidth: minWidth ?? 230 }}
        style={{
          width: "-webkit-fill-available",
          margin: "0 7.5px",
          padding: 10,
          paddingBottom: 10,
          border: "1px solid rgba(0, 0, 0, 0.12)",
        }}
        variant="outlined"
      >
        <CardContent style={{ padding: "unset", paddingBottom: "unset" }}>
          <Typography variant="h5" component="div">
            {title}
          </Typography>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {"Date: "}
              {"Billed as run"}
            </Typography>
          </div>
          <div style={{ display: "flex", gap: 5 }}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {"Price:  "}
              {totalJobLines?.toLocaleString("en-NZ", {
                style: "currency",
                currency: "NZD",
                minimumFractionDigits: 2,
              })}
            </Typography>
            {currency !== "NZD" && (
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {" - "}
                {currencySymbolFormat(totalExchangedJobLines, currency)}
              </Typography>
            )}
          </div>
        </CardContent>
      </Card>
    </Fragment>
  );
};

export default PaymentCardMediaItem;
