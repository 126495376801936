import React, { Fragment } from "react";
import { TextField } from "@mui/material";
import "../../custom.scss";
import PropTypes from "prop-types";

/* Note: No need to use this component if you are using a multiline textfield already */
const SpellCheckTextField = (props) => {
  const {
    id,
    keyProp = id,
    className,
    label,
    type = "text",
    value,
    handleOnChange,
    disabled = false,
    variant = "filled",
    size = "medium",
    fullWidth = false,
    placeholder = label,
    handleJobNameUpdate,
    onBlur = false,
    error, 
    errorMessage,
  } = props;

  const inputProps = {
    lang: "en",
    spellcheck:true
  };
  return (
    <Fragment>
      <TextField
        id={id}
        key={keyProp}
        className={`${className}`}
        label={label}
        type={type}
        value={value}
        variant={variant}
        size={size}
        fullWidth={fullWidth}
        onChange={(e) => {
          var newValue = e.target.value.replace(/(\r\n|\n|\r)/gm, " ");
          handleOnChange(newValue);
        }}
        onBlur={onBlur && handleJobNameUpdate}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={disabled}
        multiline={true}
        rows={1}
        placeholder={placeholder}
        error={error ? error : false}
        helperText={errorMessage ? errorMessage : null}
        inputProps={{
          spellCheck: true,
          lang: "en"
        }}
      />
    </Fragment>
  );
};

export default SpellCheckTextField;
