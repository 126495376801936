import { Button, Typography } from "@mui/material";
import React, { useState } from "react";
import SplitInvoiceWizardStep1 from "./SplitInvoiceWizardStep1";
import SplitInvoiceWizardStep2 from "./SplitInvoiceWizardStep2";

const SplitInvoiceWizard = ({
  maxTotal,
  handleCompleteWizard,
  setWizardTotal,
  wizardTotal,
  setFinalInvoiceDate,
  setChosenType,
  setChosenValue,
  currency = "NZD"
}) => {

  const [isStep1Completed, setIsStep1Completed] = useState(false);
  const [isStep2Completed, setIsStep2Completed] = useState(false);
  return (
    <div style={{padding: 25}}>
      {!isStep1Completed && (
        <SplitInvoiceWizardStep1
          maxTotal={maxTotal}
          wizardTotal={wizardTotal}
          setWizardTotal={setWizardTotal}
          setIsStep1Completed={setIsStep1Completed}
          setChosenType={setChosenType}
          setChosenValue={setChosenValue}
          currency={currency}
        />
      )}
      {(isStep1Completed && !isStep2Completed) && (
        <SplitInvoiceWizardStep2
          setFinalInvoiceDate={setFinalInvoiceDate}
          setIsStep2Completed={setIsStep2Completed}
          handleCompleteWizard={handleCompleteWizard}
        />
      )}
    </div>
  );
};

export default SplitInvoiceWizard;
