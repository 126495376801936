import { Card, CardContent, IconButton, Typography } from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import {
  Edit as EditIcon,
  PictureAsPdf as PictureAsPdfIcon,
} from "@mui/icons-material";
import Close from "@mui/icons-material/Close";
import dayjs from "dayjs";
import { currencySymbolFormat } from "../../Utils/GeneralUtils";

const RetainerPaymentCardItem = ({ job, invoices, title, isReadOnly, currency = "NZD", }) => {
  const formatDate = (date) => {
    if (date) {
      return dayjs(date).format("DD/MM/YYYY");
    }
    return date;
  };

  const validateIfInvoiceIsValid = () => {
    if (job && job.completionDate == null) {
      return false;
    }
    return true;
  };

  return (
    <Fragment>
      <Card
        // sx={{ minWidth: minWidth ?? 230 }}
        style={{
          width: "-webkit-fill-available",
          margin: "0 7.5px",
          padding: 10,
          paddingBottom: 10,
          border:
            validateIfInvoiceIsValid() === true
              ? "1px solid rgba(0, 0, 0, 0.12)"
              : "1px solid rgb(211, 47, 47)",
        }}
        variant="outlined"
      >
        <CardContent style={{ padding: "unset", paddingBottom: "unset" }}>
          <Typography variant="h5" component="div">
            {title}
          </Typography>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {"First Invoice Date: "}
              {job.completionDate
                ? formatDate(job.completionDate)
                : !isReadOnly
                ? "Required Date*"
                : "Acceptance Date"}
            </Typography>
          </div>
          <div style={{ display: "flex" }}>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {"Last Invoice Date:  "}
              {job.retainerEndDate
                ? formatDate(job.retainerEndDate)
                : !isReadOnly
                ? "Required Date*"
                : "Required Date*"}
            </Typography>
          </div>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {"Total Number of Invoices:  "}
            {invoices?.length}
          </Typography>
          <div style={{ display: "flex", gap: 5 }}>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {"Price:  "}
              {job.total?.toLocaleString("en-NZ", {
                style: "currency",
                currency: "NZD",
                minimumFractionDigits: 2,
              })}
              
            </Typography>
            {currency !== "NZD" && (
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {" - "}
                  {currencySymbolFormat(job.exchangedTotal, currency)}
                </Typography>
              )}
          </div>
          
        </CardContent>
      </Card>
    </Fragment>
  );
};

export default RetainerPaymentCardItem;
