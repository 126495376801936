import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import Close from "@mui/icons-material/Close";
import dayjs from "dayjs";
import InternalRevenueTable from "./InternalRevenueTable";
import UsedInternalRevenueTable from "./UsedInternalRevenueTable";

const InternalRevenueDialog = ({
  handleClose,
  open,
  internalRevenue,
  handleUseInternalRevenue,
  handleRemoveInternalRevenue,
  isSavingInternalRevenue,
  internalBillingRevenueUsage,
  isSavingInternalRevenueUsage,
  totalAllocated,
  subtotal
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title-contact"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle className="sales-dialog-title">
        Internal Budget
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers style={{ background: "#f4f4f4" }}>
        <div style={{ display: "grid", gap: 10 }}>
          {internalRevenue.length > 0 && (
            <InternalRevenueTable
              internalRevenue={internalRevenue}
              handleUseInternalRevenue={handleUseInternalRevenue}
              handleRemoveInternalRevenue={handleRemoveInternalRevenue}
              isSavingInternalRevenue={isSavingInternalRevenue}
              totalAllocated={totalAllocated}
              subtotal={subtotal}
            />
          )}
          {internalBillingRevenueUsage.length > 0 && (
            <UsedInternalRevenueTable
              internalBillingRevenueUsage={internalBillingRevenueUsage}
              isSavingInternalRevenueUsage={isSavingInternalRevenueUsage}
              handleRemoveInternalRevenue={handleRemoveInternalRevenue}
              totalAllocated={totalAllocated}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className="job-button">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InternalRevenueDialog;
