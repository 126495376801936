import React, { useEffect, useState } from "react";
import {
    Typography,
    Grid,
    TextField,
    CircularProgress
} from "@mui/material";
import InfluencerCard from "./InfluencerCard";
import axios from "axios";

const InfluencerSearch = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [influencer, setInfluencer] = useState(null);
    const [loading, setLoading] = useState(false);

    const searchInfluencer = () => {
        setLoading(true)
        axios.get(`Influencer/GetInstagramBasic/${searchTerm}`)
        .then(res => {
            setInfluencer(res.data.data);
            console.log(res.data.data);
        }).catch(e => {
            console.log(e);
        }).finally(() => setLoading(false))
    }

    return (
        <div style={{ margin: "0px 20%" }}>
            <Grid container style={{ padding: "15px 0px" }}>
                <Grid item xs={4} md={4} lg={4}>
                    <Typography
                        variant="h5"
                        gutterBottom
                        component="div"
                        alignSelf={"center"}
                    >
                        Instagram Search
                    </Typography>
                </Grid>
                <Grid item xs={8} md={8} lg={8}>
                    <div style={{ width: "100%" }}>
                        <TextField
                            label="Search Instagram Id"
                            size="small"
                            fullWidth={true}
                            variant="filled"
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                            }}
                            value={searchTerm}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    e.preventDefault();
                                    searchInfluencer();
                                }
                            }}
                            disabled={loading}
                        />
                    </div>
                </Grid>
            </Grid>
            <div id="profile-container">
                {influencer !== null && influencer !== "" && !loading && (
                    <InfluencerCard influencer={influencer}/>
                )}
                {loading && <CircularProgress />}
            </div>
        </div>
    )
}

export default InfluencerSearch;