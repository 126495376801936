import React, { Fragment, useState } from "react";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  Autocomplete,
  Chip,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import JobLinesTable from "../JobLinesTable";
import { Draggable, Droppable } from "react-beautiful-dnd";
import SocialitesJobLinesTable from "./SocialitesJobLinesTable";
import "./socialitesJobLinesAccordionItem.scss";
import {
  calculateJoblinesTotal,
  getCategoryTypes,
  getProductCategoryByOrganisation,
} from "../../../Utils/JobHelpers";
import {
  EditorState,
  convertToRaw,
  compositeDecorator,
  convertFromHTML,
  ContentState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import MarginDisplay from "../../Shared/MarginDisplay";
import ConfirmationDialog from "../../Shared/ConfirmationDialog";
import axios from "axios";
import { updateAllJobLinesStore } from "../../../reduxActions/job";
import { connect } from "react-redux";
import { currencySymbolFormat } from "../../../Utils/GeneralUtils";

const SocialitesJobLinesAccordionItem = (props) => {
  const {
    updateAllJobLinesStore,
    currency,
    productCategories,
    companies,
    setTempCategory,
    setOpenConfirmCategoryDeleteDialog,
  } = props;
  const [contextMenu, setContextMenu] = React.useState(null);
  const [tempType, setTempType] = React.useState(null);
  const [openConfirmDeleteItems, setOpenConfirmDeleteItems] =
    React.useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [editorState, seteditorState] = useState(EditorState.createEmpty());
  const [showDescription, setShowDescription] = useState(false);

  useEffect(() => {
    // pull through default job description
    if (props.category?.description) {
      const blocksFromHTML = convertFromHTML(props.category?.description);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      seteditorState(EditorState.createWithContent(state, compositeDecorator));
      setShowDescription(true);
    }
  }, []);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const displayLines = () => {
    setIsExpanded(!isExpanded);
  };

  const changeCategoryRetained = (e) => {
    e.preventDefault();
    props.handleOnChangeCategory({
      ...props.category,
      isRetainer: !props.category.isRetainer,
    });
    handleCloseContextMenu();
  };

  const onEditorStateChange = (editorState) => {
    let categoryDescription = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    props.handleOnChangeCategory({
      ...props.category,
      description: categoryDescription,
    });
    seteditorState(editorState);
  };

  const handleConfirmChangeItemType = async () => {
    props.handleOnChangeCategory({
      ...props.category,
      type: tempType,
    });

    if (props.jobLines?.length > 0) {
      axios
        .delete(`Job/DeleteJobLinesByCategoryGuid/${props.category.guid}`)
        .then(async () => {
          await updateAllJobLinesStore(
            props.job.jobLines.filter(
              (jl) => jl.jobCategoryGuid !== props.category.guid
            )
          );
          handleCloseRemoveJobLinesDialog();
        });
    }
  };

  const handleCloseRemoveJobLinesDialog = () => {
    setTempType(null);
    setOpenConfirmDeleteItems(false);
  };

  const renderCategory = () => {
    var categories = getProductCategoryByOrganisation(productCategories);
    var index = categories.findIndex((c) => c.name === props.category.type);
    return index < 0 ? "" : categories[index].name;
  };

  return (
    <>
      {openConfirmDeleteItems && tempType && (
        <ConfirmationDialog
          open={openConfirmDeleteItems}
          handleClose={handleCloseRemoveJobLinesDialog}
          submitLabel={"Confirm"}
          title={"Please confirm to proceed!"}
          description={
            "This action will remove all resources allocated to this block."
          }
          handleSubmit={handleConfirmChangeItemType}
        />
      )}

      <Fragment>
        <div
          key={props.category.guid}
          //style={{ display: "flex" }}
          className="socialites-service-header-container"
        >
          <div className="socialites-accordion-item-expanded-icon text-left no-padding">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                displayLines();
              }}
            >
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </div>
          <div style={{ gridColumn: "span 2" }} className="socialites-accordion-item-name-input-container">
            <TextField
              className="socialites-accordion-item-name-input"
              key={"category-title" + props.category.guid}
              disabled={props.isReadOnly}
              id="title"
              label="Heading Name"
              variant="filled"
              fullWidth={true}
              value={props.category?.name || ""}
              onChange={(e) =>
                props.handleOnChangeCategory({
                  ...props.category,
                  name: e.target.value,
                })
              }
            />
          </div>
          <div
            className="socialites-accordion-item-total-header-input-container"
            style={{ gridColumn: "span 3" }}
          >
            <TextField
              className="quote-category-text-field"
              label="DEPARTMENT"
              fullWidth={true}
              variant="filled"
              value={renderCategory()}
              onChange={() => {}}
              disabled
            />
          </div>

          <div
            className={"socialites-accordion-item-totalline"
            }
            onContextMenu={handleContextMenu}
          >
            {calculateJoblinesTotal(props.jobLines, "total")?.toLocaleString(
              "en-UK",
              {
                style: "currency",
                currency: currency || "NZD",
                minimumFractionDigits: 2,
              }
            )}
          </div>
          <div
            className={"socialites-accordion-item-totalmargin"}
            onContextMenu={handleContextMenu}
          >
            <MarginDisplay
              total={calculateJoblinesTotal(props.jobLines, "total")}
              margin={calculateJoblinesTotal(props.jobLines, "margin")}
              currency={currency}
            />
          </div>
          {!props.isReadOnly && (
            <div className="socialites-items-accordion-remove">
              <IconButton
                className="opacity-icon"
                onClick={() => {
                  setTempCategory(props.category);
                  setOpenConfirmCategoryDeleteDialog(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
              <DragIndicatorIcon className="drag-drop-icon opacity-icon" />
            </div>
          )}
          {/* <div className={`socialites-accordion-item-total-header-container ${currency !== "NZD" ? "socialites-accordion-item-total-header-foreign-container" : ""}`}>
                    
                  </div> */}
        </div>
        {isExpanded && (
          <div className="socialites-category-description-container">
            <div></div>
            <div
              className="socialites-accordion-item-description-textfield-description"
              style={
                showDescription
                  ? { gridColumn: "span 7" }
                  : { display: "none", gridColumn: "span 7" }
              }
            >
              <Editor
                editorState={editorState}
                wrapperClassName="socialites-accordion-item-description-text-editor-wrapper"
                editorClassName="socialites-accordion-item-description-text-editor"
                onEditorStateChange={onEditorStateChange}
                handlePastedText={() => false}
                readOnly={props.isReadOnly}
                toolbar={{
                  options: ["inline", "fontSize", "list"],
                  inline: {
                    options: ["bold", "italic", "underline"],
                  },
                  list: {
                    options: ["unordered", "ordered"],
                  },
                }}
              />
            </div>
            <div></div>
          </div>
        )}
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ padding: 0 }} colSpan={11}>
                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                  <SocialitesJobLinesTable
                    category={props.category}
                    products={props.products}
                    jobLines={props.jobLines}
                    handleChangeProduct={props.handleChangeProduct}
                    handleOnChangeJobLine={props.handleOnChangeJobLine}
                    handleAddNewJobLineButton={props.handleAddNewJobLineButton}
                    handleDeleteJobLine={props.handleDeleteJobLine}
                    getMargin={props.getMargin}
                    handleChangeProductRateType={
                      props.handleChangeProductRateType
                    }
                    isReadOnly={props.isReadOnly}
                    forceSaveDraftJob={props.forceSaveDraftJob}
                    company={companies.find(
                      (c) => c.id === props.job.companyId
                    )}
                    handleOpenBookingFeeDialog={
                      props.handleOpenBookingFeeDialog
                    }
                    showDescription={showDescription}
                    setShowDescription={setShowDescription}
                  />
                </Collapse>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Fragment>
    </>
  );
};
const mapStateToProps = (state) => ({
  currency: state.job.job.currency,
  productCategories: state.product.productCategories,
  companies: state.company.companies,
});

export default connect(mapStateToProps, {
  updateAllJobLinesStore,
})(SocialitesJobLinesAccordionItem);
