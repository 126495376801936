import React from "react";
import { currencySymbolFormat } from "../../../Utils/GeneralUtils";
import { connect } from "react-redux";
import { Typography } from "@mui/material";
import { calculateJoblinesTotal } from "../../../Utils/JobHelpers";

const ExchangedTotal = ({ currency, jobLines }) => {
 
  return (
    <div
      style={{
        display: "grid",
        justifyItems: "flex-end",
        gridTemplateColumns: "1fr",
        marginTop: 15
      }}
    >
      <div className="exchanged-grand-total">
        <div>{`Total: `}</div>
        <div style={{ marginLeft: "25px" }}>
          {currencySymbolFormat(calculateJoblinesTotal(jobLines, "exchangedTotal"), currency)}
        </div>
      </div>
      <div className="exchanged-grand-total">
        <Typography variant="caption">
          Client will only view amount in {currency}
        </Typography>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currency: state.job.job.currency,
  jobLines: state.job.job.jobLines,
});

export default connect(mapStateToProps, {})(ExchangedTotal);
