import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import {
  EditorState,
  convertToRaw,
  compositeDecorator,
  convertFromHTML,
  ContentState
} from "draft-js";
import { Button, TextField, Typography, Divider } from "@mui/material";
import axios from "axios";
import "../admin.scss";
import Snack from "../../Shared/Snack";

export const Defaults = () => {
  const [editorState, seteditorState] = useState(EditorState.createEmpty());
  const [systemDefaults, setSystemDefaults] = useState({
    threshold: 0,
    jobDescription: "",
  });
  const [openSnack, setopenSnack] = useState(false);
  const [snackSeverity, setsnackSeverity] = useState("info");
  const [snackText, setsnackText] = useState(false);

  const onEditorStateChange = (editorState) => {
    let jobDescription = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    setSystemDefaults({
      ...systemDefaults,
      jobDescription: jobDescription,
    });
    seteditorState(editorState);
  };

  useEffect(() => {
    axios
      .get("SystemDefaults/GetSystemDefaults")
      .then(({ data }) => {
        setSystemDefaults({
            ...systemDefaults,
            jobDescription: data.jobDescription,
            threshold: data.threshold
        });
        const blocksFromHTML = convertFromHTML(data.jobDescription);
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        seteditorState(
          EditorState.createWithContent(state, compositeDecorator)
        );
      })
      .catch((res) => {
        console.log(res);
      });
  }, []);

  const handleChange = (name, value) => {
    setSystemDefaults({
      ...systemDefaults,
      [name]: value,
    });
  };

  const saveValues = () => {
    axios
      .post("SystemDefaults/PostSystemDefaults", systemDefaults)
      .then(() => {
        setopenSnack(true);
        setsnackSeverity("success");
        setsnackText("Defaults saved successfully");
      })
      .catch((err) => {
        setopenSnack(true);
        setsnackSeverity("error");
        setsnackText("Defaults failed to save");
      });
  };

  return (
    <div>
      <Snack
        open={openSnack}
        setOpen={setopenSnack}
        severity={snackSeverity}
        text={snackText}
      />
      <div>
        <Typography variant="subtitle1">Default Treshold</Typography>
      </div>
      <div
        className="system-default-div"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div>
          <TextField
            label={"Treshhold value"}
            type="number"
            id="treshold-field"
            value={systemDefaults.threshold}
            variant="filled"
            size="small"
            style={{ width: "300px" }}
            onChange={(e) => handleChange("threshold", e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div>
          <Button variant="contained" onClick={saveValues}>
            Save
          </Button>
        </div>
      </div>
      <div>
        <Divider />
        <div className="after-divider">
          <Typography variant="subtitle1">Default Job Description</Typography>
        </div>
        <div className="system-default-div">
          <Editor
            editorState={editorState}
            wrapperClassName="jobdescription-text-editor-wrapper"
            editorClassName="jobdescription-text-editor"
            onEditorStateChange={onEditorStateChange}
            handlePastedText={() => false}
            toolbar={{
              options: [
                "inline",
                "fontSize",
                "fontFamily",
                "list",
                "textAlign",
                "colorPicker",
                "emoji",
                "history",
              ],
              inline: {
                options: ["bold", "italic", "underline", "strikethrough"],
              },
              list: {
                options: ["unordered", "ordered"],
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Defaults;
