import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import "./company.scss";
import { Close } from "@mui/icons-material";
import { addCompany, updateCompanyStore } from "../../reduxActions/company";
import { connect } from "react-redux";
import CompanyForm from "./CompanyForm";
import ContactTable from "../Admin/Company/ContactTable";
import CompanyActivity from "./CompanyActivity";

const CompanyDialog = ({
  open,
  selectedCompany,
  setselectedCompany,
  handleCloseNewCompanyDialog,
  handleCloseNewCompanyDialogAfterCreated,
  handleNewCompanyCreated,
  handleNewContactCreated,
  addCompany,
  updateCompanyStore,
  selectedOrganisation,
  updateMultiPropertiesCompany,
  handleCloseNewCompanyDialogAfterUpdated
}) => {
  const [activeTab, setActiveTab] = useState("COMPANY_DETAILS");

  return (
    <div >
      <Dialog
        open={open}
        onClose={handleCloseNewCompanyDialog}
        aria-labelledby="form-dialog-title-company"
        maxWidth="xl"
      >
        <DialogTitle
          id="form-dialog-title-company"
          className="sales-dialog-title company-dialog-container"
        >
          <div className="company-dialog-tabs-container">
            <Button
              className={`company-dialog-tabs-button sales-button ${activeTab === "COMPANY_DETAILS"
                ? "company-dialog-tabs-button-active"
                : ""
                }`}
              variant="contained"
              onClick={() => setActiveTab("COMPANY_DETAILS")}
            >
              Company Details
            </Button>
            {selectedCompany.id && selectedOrganisation?.id !== 1 && (
              <Button
                className={`company-dialog-tabs-button sales-button ${activeTab === "MSA"
                  ? "company-dialog-tabs-button-active"
                  : ""
                  }`}
                variant="contained"
                onClick={() => setActiveTab("MSA")}
              >
                MSA
              </Button>
            )}
            {selectedCompany.id && (
              <React.Fragment>
                <Button
                  className={`company-dialog-tabs-button sales-button ${activeTab === "CONTACTS"
                    ? "company-dialog-tabs-button-active"
                    : ""
                    }`}
                  variant="contained"
                  onClick={() => setActiveTab("CONTACTS")}
                >
                  Contacts
                </Button>
                <Button
                  className={`company-dialog-tabs-button sales-button ${activeTab === "ACTIVITY"
                    ? "company-dialog-tabs-button-active"
                    : ""
                    }`}
                  variant="contained"
                  onClick={() => setActiveTab("ACTIVITY")}
                >
                  Activity
                </Button>
              </React.Fragment>
            )}
          </div>
          {/* {selectedCompany?.id ? "Update Company" : "Create New Company"} */}
          <IconButton
            aria-label="close"
            onClick={handleCloseNewCompanyDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className="company-dialog-container">
          {activeTab === "COMPANY_DETAILS" && (
            <CompanyForm
              selectedCompany={selectedCompany}
              setselectedCompany={setselectedCompany}
              handleCloseNewCompanyDialog={handleCloseNewCompanyDialog}
              handleCloseNewCompanyDialogAfterCreated={
                handleCloseNewCompanyDialogAfterCreated
              }
              handleNewCompanyCreated={handleNewCompanyCreated}
              handleNewContactCreated={handleNewContactCreated}
              addCompany={addCompany}
              updateCompanyStore={updateCompanyStore}
              selectedOrganisation={selectedOrganisation}
              updateMultiPropertiesCompany={updateMultiPropertiesCompany}
              handleCloseNewCompanyDialogAfterUpdated={handleCloseNewCompanyDialogAfterUpdated}
            />
          )}
          {selectedCompany.id && selectedOrganisation?.id !== 1 && activeTab === "MSA" && !selectedCompany.hasSignedMsa &&
            <div className="msa-iframe-height">
              <iframe
                src={`https://docs.google.com/document/d/${selectedCompany.msaid}`}
                title="Magnesium Service - Contract"
                width="100%"
                height="100%"
                className="msa-iframe-scale-height"
              ></iframe>
            </div>}
          {selectedCompany.id && selectedOrganisation?.id !== 1 && activeTab === "MSA" && selectedCompany.hasSignedMsa &&
            <div className="msa-iframe-height">
              <iframe
                src={`https://docs.google.com/document/d/${selectedCompany.msaid}/preview `}
                title="Magnesium Service - Contract"
                width="100%"
                height="100%"
                className="msa-iframe-scale-height"
              ></iframe>
            </div>}
          {selectedCompany.id && activeTab === "CONTACTS" && <div className="add-company-dialog-height"><ContactTable companyId={selectedCompany.id} /></div>}
          {selectedCompany.id && activeTab === "ACTIVITY" && <div className="add-company-dialog-height"><CompanyActivity company={selectedCompany} /></div>}
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedOrganisation: state.organisation.selectedOrganisation,
});

export default connect(mapStateToProps, {
  addCompany,
  updateCompanyStore,
})(CompanyDialog);
