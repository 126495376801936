import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  Divider,
  IconButton,
  Stack,
  Switch,
} from "@mui/material";
import AccountingCard from "./AccountingCard";
import "./company.scss";
import { Close } from "@mui/icons-material";
import axios from "axios";
import SpellCheckTextField from "../Shared/SpellCheckTextField";
import Snack from "../Shared/Snack";
import { validateEmail } from "../../Utils/JobHelpers";
import { hasElements, isEmpty } from "../../Utils/GeneralUtils";
import { addCompany, updateCompanyStore } from "../../reduxActions/company";
import { connect } from "react-redux";

const errorState = {
  registeredName: "",
  address: "",
  name: "",
  primaryAccountsEmail: "",
};

const AddNewCompanyDialog = (props) => {
  const {
    open,
    selectedCompany,
    setselectedCompany,
    handleCloseNewCompanyDialog,
    handleCloseNewCompanyDialogAfterCreated,
    handleNewCompanyCreated,
    handleNewContactCreated,
    addCompany,
    updateCompanyStore,
    selectedOrganisation,
  } = props;

  const [contact, setcontact] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    personCategory: "Client",
    isOnboardingRequired: true,
  });
  const [openSnack, setopenSnack] = useState(false);
  const [snackSeverity, setsnackSeverity] = useState("info");
  const [snackText, setsnackText] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [invalidEmailMessage, setInvalidEmailMessage] = useState(null);
  const [errorForm, setErrorForm] = useState(errorState);

  const handleOnChangeCompany = (field, value) => {
    if (field === "companyLocation" && value) {
      setselectedCompany({
        ...selectedCompany,
        [field]: value,
        isNzbusiness: value === "NEW_ZEALAND" ? true : false,
        gstregistered: true,
        currency: "NZD",
        registeredName: ""
      });
    } else {
      setselectedCompany({
        ...selectedCompany,
        [field]: value,
      });
    }
  };

  const handleOnChangeContact = (field, value) => {
    setcontact({
      ...contact,
      [field]: value,
    });
  };

  const handleSaveCompany = () => {
    if (validateForm()) {
      if (selectedCompany.id) {
        updateCompany();
      } else {
        if (contact.firstName && contact.lastName && contact.emailAddress) {
          createCompany();
        } else {
          setsnackText("Please enter all fields with *");
          setsnackSeverity("error");
          setopenSnack(true);
        }
      }
    }
  };

  const createCompany = () => {
    let contactClone = { ...contact };
    contactClone.fullName = contact.firstName + " " + contact.lastName;
    contactClone.organisationFk = selectedOrganisation.id;
    axios
      .post("Company/CreateCompany", {
        ...selectedCompany,
        person: [contactClone],
        organisationFk: selectedOrganisation.id,
      })
      .then(({ data }) => {
        handleNewCompanyCreated(data);
        handleNewContactCreated(data.person.length > 0 && data.person[0]);
        addCompany(data);
        handleCloseNewCompanyDialogAfterCreated();
        setsnackText("Company Created");
        setsnackSeverity("info");
        setopenSnack(true);
      })
      .catch((err) => {
        setsnackText(err.response.data);
        setsnackSeverity("error");
        setopenSnack(true);
      });
  };

  const updateCompany = () => {
    let _company = { ...selectedCompany };

    if (
      !hasElements(selectedCompany.person) &&
      !isEmpty(contact.firstName) &&
      !isEmpty(contact.lastName) &&
      !isEmpty(contact.emailAddress)
    ) {
      let contactClone = { ...contact };
      contactClone.fullName = contact.firstName + " " + contact.lastName;
      contactClone.organisationFk = selectedOrganisation.id;
      _company = { ...selectedCompany, person: [contactClone] };
    }

    axios
      .post("Company/UpdateCompany", _company)
      .then(({ data }) => {
        updateCompanyStore(selectedCompany);
        handleCloseNewCompanyDialogAfterCreated();
        setsnackText("Company Updated");
        setsnackSeverity("info");
        setopenSnack(true);
      })
      .catch((err) => {
        setsnackText(err.response.data);
        setsnackSeverity("error");
        setopenSnack(true);
      });
  };

  const handleEmailChange = (emailAddress) => {
    handleOnChangeContact("emailAddress", emailAddress);
    if (validateEmail(emailAddress)) {
      setInvalidEmailMessage(null);
      setIsInvalidEmail(false);
    } else {
      setInvalidEmailMessage("Invalid Email Address");
      setIsInvalidEmail(true);
    }
  };

  const validateForm = () => {
    const tempError = { ...errorForm };
    let validForm = true;
    if (isEmpty(selectedCompany.name) === true) {
      tempError.name = "Required field";
      validForm = false;
    } else {
      tempError.name = "";
    }
    if (
      isEmpty(selectedCompany.registeredName) === true &&
      selectedCompany.companyLocation !== "INTERNATIONAL"
    ) {
      tempError.registeredName = "Required field";
      validForm = false;
    } else {
      tempError.registeredName = "";
    }

    if (
      isEmpty(selectedCompany.address) === true &&
      selectedCompany.companyLocation !== "INTERNATIONAL"
    ) {
      tempError.address = "Required field";
      validForm = false;
    } else {
      tempError.address = "";
    }

    if (isEmpty(selectedCompany.primaryAccountsEmail) !== true && !validateEmail(selectedCompany.primaryAccountsEmail)) {
      tempError.primaryAccountsEmail = "Please enter a valid email";
      validForm = false;
    } else {
      tempError.primaryAccountsEmail = "";
    }

    if (!validForm) {
      setErrorForm(tempError);
    }
    return validForm;
  };

  return (
    <div>
      <Snack
        open={openSnack}
        setOpen={setopenSnack}
        severity={snackSeverity}
        text={snackText}
      />
      <Dialog
        open={open}
        onClose={handleCloseNewCompanyDialog}
        aria-labelledby="form-dialog-title-company"
        maxWidth="xl"
      >
        <DialogTitle
          id="form-dialog-title-company"
          className="sales-dialog-title"
        >
          {selectedCompany?.id ? "Update Company" : "Create New Company"}
          <IconButton
            aria-label="close"
            onClick={handleCloseNewCompanyDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div className="add-company-dialog-company-root">
            <div className="add-company-dialog-company-container">
              <div className="add-company-dialog-section-title">
                <Typography variant="button">Company</Typography>
                <Typography variant="caption">
                  (What we will call in our systems)
                </Typography>
              </div>
              <div className="">
                <div className="newcompany-textfield flex-row-components">
                  <SpellCheckTextField
                    id="company-name"
                    label="Company Name *"
                    error={errorForm.name}
                    errorMessage={errorForm.name}
                    size="small"
                    fullWidth={true}
                    value={selectedCompany?.name}
                    handleOnChange={(newValue) =>
                      handleOnChangeCompany("name", newValue)
                    }
                  />
                </div>
              </div>
            </div>

            {(!selectedCompany.id || !hasElements(selectedCompany.person)) && (
              <div className="add-company-dialog-contact-container">
                <div className="add-company-dialog-section-title">
                  <Typography variant="button">Contact</Typography>
                  <Typography variant="caption">
                    (The person who will approve the quote)
                  </Typography>
                </div>
                <div className="add-company-dialog-contact-form">
                  <SpellCheckTextField
                    id="label-name"
                    label="First Name *"
                    size="small"
                    fullWidth={true}
                    value={contact.firstName}
                    handleOnChange={(newValue) =>
                      handleOnChangeContact("firstName", newValue)
                    }
                  />
                  <SpellCheckTextField
                    id="last-name"
                    label="Last Name *"
                    size="small"
                    fullWidth={true}
                    value={contact.lastName}
                    handleOnChange={(newValue) =>
                      handleOnChangeContact("lastName", newValue)
                    }
                  />
                  {/* There was no value in the orginal textfield so I added it hopefully it doesn't break the code- Jethro */}
                  <SpellCheckTextField
                    id="email-name"
                    label="Email Address *"
                    size="small"
                    fullWidth={true}
                    value={contact.emailAddress}
                    handleOnChange={(newValue) => handleEmailChange(newValue)}
                    error={isInvalidEmail}
                    errorMessage={invalidEmailMessage}
                  />
                  {/* There was no value in the orginal textfield so I added it hopefully it doesn't break the code- Jethro */}
                  <SpellCheckTextField
                    id="phone-number"
                    label="Phone Number"
                    size="small"
                    fullWidth={true}
                    value={contact.phoneNumber}
                    handleOnChange={(newValue) =>
                      handleOnChangeContact("phoneNumber", newValue)
                    }
                  />
                </div>
              </div>
            )}
            <div className="add-company-dialog-accounting-container">
              <div className="add-company-dialog-section-title">
                <Typography variant="button">Accounting</Typography>
              </div>
              <AccountingCard
                handleOnChangeCompany={handleOnChangeCompany}
                company={selectedCompany}
                errorForm={errorForm}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className="sales-button"
            onClick={handleCloseNewCompanyDialog}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            className="sales-button"
            variant="contained"
            onClick={handleSaveCompany}
          >
            {selectedCompany.id ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedOrganisation: state.organisation.selectedOrganisation,
});

export default connect(mapStateToProps, {
  addCompany,
  updateCompanyStore,
})(AddNewCompanyDialog);
