import { Typography } from "@mui/material";
import React, { useState, useEffect, } from "react";
import Spinner from "../../../Shared/Spinner";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";

const MarginExemptionLandingPage = (props) => {
    const { id, type } = useParams();
    const history = useHistory();

    const [processing, setProcessing] = useState(true);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        axios.patch(`MarginExemption/ApproveExemption/${id}/${type}`)
            .then(res => {
                setSuccess(true);
                //history.push("exemptions")
            })
            .catch(e => console.log(e))
            .finally(() => {
                setProcessing(false)
            })
    }, []);

    return (
        <div style={{ margin: "0px 20%" }}>
            {processing ?
                <React.Fragment>
                    <Spinner />
                    <div style={{ marginTop: 15, textAlign: "center" }}>
                        <Typography variant="h4">
                            Processing your request.
                        </Typography>
                        <Typography variant="h5">
                            This shouldn't take too long.
                        </Typography>
                    </div>
                </React.Fragment>
                : <div style={{ marginTop: 15, textAlign: "center" }}>
                    {(success ?
                        <Typography variant="h4">
                            Exemption has been applied.
                        </Typography>
                        :
                        <Typography variant="h4">
                            An error occured. Please try again
                        </Typography>
                    )}
                </div>
            }
        </div>
    )
}

export default MarginExemptionLandingPage;