import { Typography } from "@mui/material";
import React from "react";
import SpinnerV2 from "./SpinnerV2";

const ProcessingSpinnerV2 = ({
  duration,
  unitTime
}) => {
  return (
    <div>
      <SpinnerV2 />
      <div style={{marginTop: 15,textAlign: "center"}}>
        <Typography variant="h6">
          Processing your request. 
        </Typography>
        <Typography variant="h6">
          This action can take up to {duration} {unitTime}. 
        </Typography>
      </div>
    </div>
  );
};

export default ProcessingSpinnerV2;