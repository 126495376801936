import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import "./contact.scss";
import axios from "axios";
import SpellCheckTextField from "../Shared/SpellCheckTextField";
import { validateEmail } from "../../Utils/JobHelpers";
import Snack from "../Shared/Snack";

const emptyContact = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  phoneNumber: "",
  companyFk: 0,
  isOnboardingRequired: true,
  personCategory: "Client",
};
const AddNewContactDialog = (props) => {
  const {
    open,
    firstName,
    lastName,
    companyId,
    handleCloseNewContactDialog,
    handleNewContactCreated,
    handleCloseNewContactDialogAfterCreated,
    existingContact,
    selectedOrganisation
  } = props;

  const [contact, setcontact] = useState(emptyContact);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [invalidEmailMessage, setInvalidEmailMessage] = useState(null);
  const [openSnack, setopenSnack] = useState(false);
  const [snackSeverity, setsnackSeverity] = useState("info");
  const [snackText, setsnackText] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOnChange = (field, value) => {
    setcontact({
      ...contact,
      [field]: value,
    });
  };

  const handleCreateNewContact = async () => {
    let contactClone = { ...contact };
    contactClone.fullName = contactClone.firstName + " " + contactClone.lastName;
    contactClone.organisationFk = selectedOrganisation.id;
    setLoading(true);
    axios
      .post(`Contact/CreateContact`, contactClone)
      .then(({ data }) => {
        //snack for new contact created
        handleNewContactCreated(data);
        handleCloseNewContactDialogAfterCreated();
      })
      .catch((err) => {
        setsnackText(err.response.data);
        setsnackSeverity("error");
        setopenSnack(true);
      }).finally(() => {
        setLoading(false);
      });
  };

  const updateContact = async () => {
    let contactClone = { ...contact };
    contactClone.fullName = contactClone.firstName + " " + contactClone.lastName;
    setLoading(true);
    axios
      .put(`Contact/UpdateContact`, contactClone)
      .then(({ data }) => {
        //snack for new contact created
        handleNewContactCreated(data);
        handleCloseNewContactDialogAfterCreated();
      })
      .catch((err) => {
        setsnackText(err.response.data);
        setsnackSeverity("error");
        setopenSnack(true);
      }).finally(() => {
        setLoading(true);
      });
  };

  const handleSaveContact = () => {
    if (contact.id) {
      updateContact();
    } else {
      handleCreateNewContact();
    }
  };

  useEffect(() => {
    setcontact({
      ...contact,
      firstName: firstName,
      lastName: lastName,
      companyFk: companyId,
    });
  }, []);

  useEffect(() => {
    if (existingContact && existingContact.id) {
      setcontact(existingContact);
    }
  }, [existingContact]);

  const handleEmailChange = (emailAddress) => {
    handleOnChange("emailAddress", emailAddress)
    if (validateEmail(emailAddress)) {
      setInvalidEmailMessage(null)
      setIsInvalidEmail(false)
    } else {
      setInvalidEmailMessage("Invalid Email Address")
      setIsInvalidEmail(true)
    }
  }

  return (
    <div>
      <Snack
        open={openSnack}
        setOpen={setopenSnack}
        severity={snackSeverity}
        text={snackText}
      />
      <Dialog
        open={open}
        onClose={() => {
          if (loading) {
            return
          }
          handleCloseNewContactDialog()
        }}
        aria-labelledby="form-dialog-title-contact"
      >
        <DialogTitle
          id="form-dialog-title-company"
          className="sales-dialog-title contact-dialog-container"
        >
          <div className="contact-dialog-container-title">
            {contact.id ? "Update New Contact" : "Create New Contact"}
            <Typography variant="caption">
              (The person who will approve the quote)
            </Typography>
          </div>
          <IconButton
            aria-label="close"
            onClick={() => {
              if (loading) {
                return
              }
              handleCloseNewContactDialog()
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className="contact-dialog-container">
          <div className="flex-div">
            <div className="newcontact-textfield-name flex-row-components">
              <SpellCheckTextField
                id="contact-first-name"
                label="First Name"
                size="small"
                fullWidth={true}
                value={contact?.firstName}
                handleOnChange={(newValue) => handleOnChange("firstName", newValue)}
                disabled={loading}
              />
            </div>
            <div className="newcontact-textfield-name flex-row-components">
              <SpellCheckTextField
                id="contact-last-name"
                label="Last Name"
                size="small"
                fullWidth={true}
                value={contact?.lastName}
                handleOnChange={(newValue) => handleOnChange("lastName", newValue)}
                disabled={loading}
              />
            </div>
          </div>
          <div className="flex-div">
            <div className="newcontact-textfield flex-row-components">
              <SpellCheckTextField
                id="contact-email"
                label="Email"
                size="small"
                fullWidth={true}
                value={contact?.emailAddress}
                handleOnChange={(newValue) => handleEmailChange(newValue)}
                error={isInvalidEmail}
                errorMessage={invalidEmailMessage}
                disabled={loading}
              />
            </div>
          </div>
          <div className="flex-div">
            <div className="newcontact-textfield flex-row-components">
              <SpellCheckTextField
                id="contact-phone"
                label="Phone Number"
                size="small"
                fullWidth={true}
                value={contact?.phoneNumber}
                handleOnChange={(newValue) => handleOnChange("phoneNumber", newValue)}
                disabled={loading}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className="contact-dialog-container">
          {!loading && <Button onClick={handleCloseNewContactDialog} color="primary">
            Cancel
          </Button>}
          {
            loading ?
              <div style={{ padding: 10 }}>
                <CircularProgress />
              </div> :
              <Button
                onClick={handleSaveContact}
                color="primary"
                disabled={
                  contact?.firstName === "" ||
                  contact?.lastName === "" ||
                  contact?.emailAddress === "" ||
                  contact?.phoneNumber === "" ||
                  loading
                }
              >
                {contact.id ? "Update" : "Create"}
              </Button>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedOrganisation: state.organisation.selectedOrganisation,
});

export default connect(mapStateToProps, {

})(AddNewContactDialog);
