import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import PaymentCards from "../Jobs/PaymentCards";
import axios from "axios";
import LoadingTableSkeleton from "../Shared/LoadingTableSkeleton";

const InvoicePreviewDialog = (props) => {
  const { open, job, handleClose } = props;

  const [invoices, setInvoices] = useState([]);
  const [jobDto, setJobDto] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const jobUrl = axios.get(`job/GetJobById/${job.id}`);
    const invoiceUrl = axios.get(`Invoice/GetInvoices/${job.id}`);
    axios
      .all([jobUrl, invoiceUrl])
      .then(
        axios.spread((jobRes, invoiceRes) => {
          setInvoices(invoiceRes.data);
          setJobDto(jobRes.data);
        })
      )
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={(e, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="invoice-dialog-title"
        aria-describedby="invoice-dialog-description"
      >
        <DialogTitle id="invoice-dialog-title">Invoices</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", minWidth: 230 }}>
            {loading ? (
              <LoadingTableSkeleton
                loadingMessage="Searching Invoices..."
                colspan="2"
              />
            ) : (
              <PaymentCards
                job={jobDto}
                paymentType={jobDto?.paymentType}
                invoices={invoices}
                isReadOnly={true}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className="default-button"
            variant="contained"
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InvoicePreviewDialog;
