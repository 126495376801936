import { CircularProgress, TextField, Autocomplete } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";

const JobAutocomplete = ({ organisationId, handleOnChange, variant,  }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  let timer = null;

  const fetchOptions = async (keyword) => {
    setLoading(true);
    try {
      const response = await axios.get("Job/SearchJobs", {
        params: {
          organisationId,
          users: null,
          companyId: null,
          keyword,
          team: null,
          includeApproved: true,
          status: "draft,checked,sent,approved",
          page: 0,
          pageSize: 50,
        },
      });
      console.log();
      setOptions(response.data.jobs);
    } catch (error) {
      console.error("Error fetching options:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = async (event, value) => {
    // Implement debouncing to delay API call
    clearTimeout(timer); // Clear previous timeout
    if (value) {
      timer = setTimeout(() => {
        fetchOptions(value);
      }, 500);
    } else {
      setOptions([]);
    }
  };

  return (
    <div>
      <Autocomplete
        options={options}
        loading={loading}
        getOptionLabel={(option) => option.projectCode
            ? `[${option.projectCode}] ${option.companyName} - ${option.jobName} ${option.additional ? "(Additional)" : ""}`
            : `${option.companyName} - ${option.jobName} ${option.additional ? " (Additional)" : ""}`}
        renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.projectCode
                  ? `[${option.projectCode}] ${option.companyName} - ${option.jobName}`
                  : `${option.companyName} - ${option.jobName}`}{" "}
                  {option.status && (
                  <div className="additional-badge-wrapper-home">
                    <div className="badge additional-green-badge h-auto capitalize">
                        {option.status}
                    </div>
                  </div>
                )}
                {option.additional && (
                  <div className="additional-badge-wrapper-home">
                    <div className="badge additional-black-badge h-auto">
                      Additional
                    </div>
                  </div>
                )}
              </li>
            );
          }}
        onInputChange={handleInputChange}
        onChange={(e, newValue) => {
          handleOnChange(newValue || null);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search for job"
            variant={variant ? variant : "filled"}
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress size={12} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export default JobAutocomplete;
