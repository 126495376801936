import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import AddNewContactDialog from "../../Contacts/AddNewContactDialog";
import {
    Edit as EditIcon,
    Delete as DeleteIcon,
} from '@mui/icons-material';
import {
    Button,
    IconButton,
    Typography
} from "@mui/material";
import Snack from "../../Shared/Snack";
import ConfirmationDialog from "../../Shared/ConfirmationDialog";

const ContactTable = (props) => {
    const {
        companyId,
        selectedOrganisation
    } = props;

    const [contacts, setContacts] = useState([]);
    const [fetchContacts, setFetchContacts] = useState(true);

    const [openCreateContactDialog, setOpenCreateContactDialog] = useState(false);
    const [selectedContact, setSelectedContact] = useState(null);
    const [openSnack, setopenSnack] = useState(false);
    const [snackSeverity, setsnackSeverity] = useState("info");
    const [snackText, setsnackText] = useState(false);
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);


    const emptyContact = {
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        companyFk: 0,
        isOnboardingRequired: true,
        personCategory: "Client",
        selectedOrganisation: selectedOrganisation.id
    };

    const handleCloseNewContactDialog = () => {
        setOpenCreateContactDialog(false);
        setSelectedContact(null);
    }

    const handleCloseNewContactDialogAfterCreated = () => {
        setFetchContacts(true);
        setOpenCreateContactDialog(false);
    };

    const handleNewContactCreated = (contact) => {
        setSelectedContact(contact);
        setsnackText("Contact Created");
        setsnackSeverity("info");
        setopenSnack(true);
    };

    const archiveContact = () => {
        axios.post(`Contact/ArchiveContact/${selectedContact?.id}`, {
            id: selectedContact?.id
        })
            .then(res => {
                setSelectedContact(null);
                setFetchContacts(true);
                setOpenDeleteConfirmation(false)
            }).catch(e => {
                console.error(e)
            })
    }

    useEffect(() => {
        // if (fetchContacts) {
        //     let isMounted = true;
        //     setFetchContacts(false);
        //     axios.get(`Contact/GetCompanyContacts/${companyId}`)
        //     .then(res => {
        //         if (isMounted) setContacts(res.data)
        //     }).catch(e => {
        //         console.error(e)
        //     })
        //     return () => { isMounted = false };
        // }
        if (fetchContacts) {
            setFetchContacts(false);
            axios.get(`Contact/GetCompanyContacts/${companyId}`)
                .then(res => {
                    setContacts(res.data)
                }).catch(e => {
                    console.error(e)
                })
        }
    }, [fetchContacts]);

    return (
        <div style={{ padding: 10 }}>
            {openCreateContactDialog && (
                <AddNewContactDialog
                    open={openCreateContactDialog}
                    handleCloseNewContactDialog={handleCloseNewContactDialog}
                    firstName={selectedContact?.firstName}
                    lastName={selectedContact?.lastName}
                    companyId={companyId}
                    existingContact={selectedContact}
                    setselectedContact={setSelectedContact}
                    handleCloseNewContactDialogAfterCreated={handleCloseNewContactDialogAfterCreated}
                    handleNewContactCreated={handleNewContactCreated}
                />
            )}
            {openDeleteConfirmation && (
                <ConfirmationDialog
                    open={openDeleteConfirmation}
                    handleClose={() => {
                        setOpenDeleteConfirmation(false)
                    }}
                    submitLabel={"Confirm"}
                    title={"Please confirm to proceed"}
                    description={
                        `This will archive this contact. Are you sure?`
                    }
                    handleSubmit={() => {
                        //setOpenDeleteConfirmation(false)
                        archiveContact(selectedContact.id)
                    }}
                />
            )}
            <div id="add-contact-container" style={{ float: "right", marginBottom: 10 }}>
                <Button size="small" color="primary" variant="contained" className="default-button"
                    onClick={() => {
                        setSelectedContact(emptyContact);
                        setOpenCreateContactDialog(true);
                    }}
                >
                    Create Contact
                </Button>
            </div>
            <table className="table table-striped table-hover text-nowrap mb-0 table-sm">
                <thead className="thead-magnesium">
                    <tr>
                        <th className="text-center">FirstName</th>
                        <th className="text-center">Last Name</th>
                        <th className="text-center">Email</th>
                        <th className="text-center">PhoneNumber</th>
                        <th className="text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {contacts.length === 0 && (
                        <tr>
                            <td className="text-center" colSpan={5}>No Contacts</td>
                        </tr>
                    )}
                    {contacts.length > 0 && contacts.map(contact => {
                        if (!contact.isArchived) {
                            return (
                                <tr>
                                    <td className="text-center">{contact.firstName}</td>
                                    <td className="text-center">{contact.lastName}</td>
                                    <td className="text-center">{contact.emailAddress}</td>
                                    <td className="text-center">{contact.phoneNumber}</td>
                                    <td>
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <IconButton
                                                className="default-icon"
                                                onClick={e => {
                                                    setSelectedContact(contact);
                                                    setOpenCreateContactDialog(true)
                                                }}
                                            >
                                                <EditIcon style={{ color: "black" }} />
                                            </IconButton>
                                            <IconButton
                                                className="default-icon"
                                                onClick={e => {
                                                    setSelectedContact(contact);
                                                    setOpenDeleteConfirmation(true)
                                                }}
                                            >
                                                <DeleteIcon style={{ color: "black" }} />
                                            </IconButton>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }
                    })}
                </tbody>
            </table>
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedOrganisation: state.organisation.selectedOrganisation,
});

export default connect(mapStateToProps, {

})(ContactTable);