import dayjs from "dayjs";

export const updateJobNameToIncludeMonthYear = (
  jobName,
  completionDate,
  expectedTransmissionDate,
  shootDays
) => {
  let _jobName = jobName;
  let jobNameArr = [];
  const _date = shootDays == 0 ? completionDate : expectedTransmissionDate;
  if (_date) {
    jobNameArr = _jobName.split("-").map((j, i, arr) => {
      if (arr.length === 1) {
        return `${j.trim()} - ${dayjs(_date).format("MMM YYYY")}`;
      } else {
        if (i === arr.length - 2) {
          return j.trim();
        }
        if (i === arr.length - 1) {
          return ` - ${dayjs(_date).format("MMM YYYY")}`;
        }
      }
      return j;
    });
  }
  return jobNameArr.length > 0 ? jobNameArr.join("") : jobName;
};

export const checkIfContainsMonthYear = (jobName) => {
  const _jobName = jobName.split(" - ")[0];

  const monthRegex =
    /((?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Sept|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?))/gi;
  const currentyear = dayjs().format("YYYY");
  const nextyear = dayjs().add(1, "year").format("YYYY");
  const yearRegex = new RegExp(`\\b${currentyear}\\b|\\b${nextyear}\\b`, "gi");
  if (_jobName.match(monthRegex) || _jobName.match(yearRegex)) {
    return true;
  }
  return false;
};

export const calculateJobTotal = (jobLines) => {
  if (jobLines && jobLines.length > 0) {
    return jobLines.reduce((a, b) => a + (b.total || 0), 0);
  } else {
    return 0;
  }
};

export const calculateTotalMargin = (jobLines) => {
  if (jobLines && jobLines.length > 0) {
    return jobLines.reduce((a, b) => a + (b.margin || 0), 0);
  } else {
    return 0;
  }
};

export const validateEmail = (email) => {
  var regexp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email ? regexp.test(String(email).toLowerCase()) : false;
};

export const convertPaymentType = (paymentType, order) => {
  switch (paymentType) {
    case "HALF_DEPOSIT":
      return order === 1 ? "50% Deposit" : "Balance Payment";
    case "FULL_POST_PAYMENT":
      return "Full Post Payment";
    case "FULL_PRE_PAYMENT":
      return "Full Pre Payment";
    case "ONE_THIRD_DEPOSIT":
      return order === 1 ? "33% Deposit" : "Balance Payment";
    case "END_OF_MONTH_BILLING":
      return "End Of Month Billing";
    default:
      return ""
  }
};


export const calculateJoblinesTotal = (jobLines, field) => {
  return jobLines.reduce((a, b) => a + b[field], 0);
};


export const getProductCategoryByOrganisation = (productCategories) => {
  // Use a Set to filter out duplicate objects
  const uniqueCategories = new Set(productCategories.map(p => JSON.stringify({ id: p.id, name: p.name, organisationId: p.organisationId, organisationName: p.organisationId === 1 ? "Magnesium" : "Thompson Spencer" })));

  // Map the unique objects back to JSON and parse them to return the original objects
  const categories = Array.from(uniqueCategories)
    .map(JSON.parse)
    .sort((a, b) => {
      // Sort by the 'organisation' property
      if (a.organisationName > b.organisationName) return -1;
      if (a.organisationName < b.organisationName) return 1;
      return 0;
    });

  return categories;
};
export const getCategoryTypes = (products) => {
  // Use a Set to filter out duplicate objects
  const uniqueCategories = new Set(products.map(p => JSON.stringify({ type: p.type, organisation: p.organisationId === 1 ? "Magnesium" : "Thompson Spencer" })));

  // Map the unique objects back to JSON and parse them to return the original objects
  const categories = Array.from(uniqueCategories)
    .map(JSON.parse)
    .sort((a, b) => {
      // Sort by the 'organisation' property
      if (a.organisation > b.organisation) return -1;
      if (a.organisation < b.organisation) return 1;
      return 0;
    });;

  return categories;
};

export const getCategoryTypeByName = (name, products) => {
  return getCategoryTypes(products).find(c => c.type === name)?.type
};

export const getYearsDropdownStringArray = (currentYear, lookAhead) => {
  var yearArr = [];
  for (var i = 0; i < lookAhead; i++) {
    yearArr.push(`${currentYear + i}`);
  }

  return yearArr;
}

export const getMonthsArr = () => {
  return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
}

export const get12MonthsAheadArr = () => {
  var now = dayjs();
  var lastDay = now.daysInMonth();
  var startDate = dayjs(`${now.format("YYYY")}-${now.format("MM")}-${lastDay}`)

  var dateArr = [];
  for (var i = 0; i < 12; i++) {
    if (i !== 0) {
      var tempDate = dayjs(`${startDate.format("YYYY")}-${startDate.format("MM")}-01`);
      tempDate = tempDate.add(1, 'month');
      lastDay = tempDate.daysInMonth();
      startDate = dayjs(`${tempDate.format("YYYY")}-${tempDate.format("MM")}-${lastDay}`)
    }
    dateArr.push({
      display: `${startDate.format("MMMM")} ${startDate.format("YYYY")}`,
      value: startDate.format("YYYY-MM-DD")
    })
  }

  return dateArr;
}

export const get12MonthsAheadAnd1MonthBackArr = () => {
  var now = dayjs();
  var firstDayOfMonth = dayjs(`${now.format("YYYY")}-${now.format("MM")}-01`)
  var lastDay = firstDayOfMonth.add(-1, 'day');
  var startDate = dayjs(`${lastDay.format("YYYY")}-${lastDay.format("MM")}-25`)
  var dateArr = [];
  for (var i = 0; i < 13; i++) {
    if (i !== 0) {
      var tempDate = dayjs(`${startDate.format("YYYY")}-${startDate.format("MM")}-01`);
      tempDate = tempDate.add(1, 'month');
      startDate = dayjs(`${tempDate.format("YYYY")}-${tempDate.format("MM")}-25`)
    }
    dateArr.push({
      display: `${startDate.format("MMMM")} ${startDate.format("YYYY")}`,
      value: startDate.format("YYYY-MM-DD")
    })
  }

  return dateArr;
}

export const removeLeadingZeros = (value) => {
  var valueToCheck = value;
  if (typeof (value) === "number") {
    valueToCheck = value.toString();
  }

  if (typeof (valueToCheck) === "string") {
    while (valueToCheck.length > 1 && valueToCheck.charAt(0) === '0') {
      valueToCheck = valueToCheck.substring(1);
    }
  }
  return valueToCheck;
}

export const getMonthsArray = (numMonths) => {
  const monthsArray = [];

  for (let i = 1; i <= numMonths; i++) {
    const monthObject = { id: i, value: `${i} Month${i > 1 ? 's' : ''}` };
    monthsArray.push(monthObject);
  }

  return monthsArray;
}

export const checkIfValidMiscJobLine = (jobLine) => {
  if (jobLine && jobLine.description) {
    return (jobLine.name === "Miscellaneous Cost" || jobLine.name === "Influencer - Expenses & Reimbursements")
      && ["production", "influencer", "media"].some(v => jobLine.description.toLowerCase().includes(v));
  } else {
    return false;
  }
}

export const checkIfJobIsReadOnly = (status, org1, org2) => {
  if (status === "archived" || status === "approved") {
    return true;
  } else {
    return false;
  }
}

export const getReadOnlyText = (status) => {
  if (status === "archived") {
    return `This job has been archived and cannot be edited`;
  }
  return ""
}

export const getOrganisationName = (id) => {
  switch (id) {
    case 1:
      return "Magnesium";
    case 2:
      return "Socialites";
    case 3:
      return "The Social Club";
    case 4:
      return "Flying Tiger";
    case 5:
      return "People Influence";
    case 6:
      return "Media X";
    default:
      return "";
  }
}

export const getInternalCompanyId = () => {
  return 552;
}
export const hasProductionCategories = (jobCategories) => {
  const productionCategories = ["Pre Production", "Post Production", "Filming", "Other", "CapEx Mag"];
  return jobCategories.some(category => productionCategories.includes(category.type));
}


