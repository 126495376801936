import React from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../context/auth/AuthContext";
import { useUserContext } from "../../context/user/UserContext";

const FirebaseGAuth = ({ endpoint, handleWelcome, goToRoute }) => {
  const {
    oAuthSignedIn: authorized,
    allUsers,
    idActiveUser,
    updateOAuthLogin,
    getAllUsers,
  } = useUserContext();
  const { user } = useAuth();

  const history = useHistory();
  useEffect(() => {
    if (!user?.uid) {
      history.push({ pathname: "/login", state: { endpoint: endpoint } });
    } else {
      handleSuccessfulLogin();
    }
  }, [user]);
  
  useEffect(() => {
    if(user?.uid){
      getAllUsers()
    }
  }, [user]);

  useEffect(() => {
    if (user?.uid && allUsers?.length > 0) {
      console.log("useEffect idActiveUser")
      idActiveUser(user, allUsers);
    }
  }, [user, authorized, allUsers]);

  useEffect(() => {
    if (user?.uid && authorized) {
      if (goToRoute) {
        history.push({
          pathname: endpoint,
          state: { fromGAuth: true },
        });
      } else {
        handleWelcome(user);
      }
    }
  }, [user, goToRoute, authorized]);

  const handleSuccessfulLogin = () => {
    if (user?.uid) {
      console.log("handleSuccessfulLogin")
      updateOAuthLogin(true, user.email)
    }
  };

  return <Fragment />;
};

export default FirebaseGAuth;
