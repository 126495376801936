import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { getProductCategoryByOrganisation } from "../../Utils/JobHelpers";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Draggable } from "react-beautiful-dnd";
import { archiveProduct } from "../../reduxActions/product";
import ConfirmationDialogActions from "../Shared/ConfirmationDialogActions";
const ProductsTableRow = ({
  product,
  productCategories,
  handleUpdateLocalProduct,
  index,
  handleArchiveProduct,
  setExpandedCategory,
}) => {
  const inputRef = useRef(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [openStausDialog, setOpenStausDialog] = useState(false);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : null
    );
  };

  const handleCloseContext = () => {
    setContextMenu(null);
  };

  const handleChangeStatusDialog = () => {
    setOpenStausDialog(true);
    handleCloseContext();
  };

  const handleConfirmChangeStatus = async () => {
    await handleArchiveProduct(product.id);
    setExpandedCategory(null);
    handleCloseStatusDialog();
  };

  const handleCloseStatusDialog = () => {
    setOpenStausDialog(false);
  };

  return (
    <>
      {openStausDialog && (
        <ConfirmationDialogActions
          title={"Are you sure?"}
          open={openStausDialog}
          handleClose={() => handleCloseStatusDialog()}
          contentText={`This action will make the product status ${
            product.isArchived !== 1 ? "INACTIVE" : "ACTIVE"
          }. Are you sure you want to proceed?`}
          button1Action={() => handleCloseStatusDialog()}
          button1Text={"Cancel"}
          button2Text={"Confirm"}
          button1Class="default-button"
          button2Class="default-button"
          button2Action={handleConfirmChangeStatus}
        />
      )}
      <Draggable key={index} draggableId={"" + index} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 10,
                backgroundColor: index % 2 === 0 ? "whitesmoke" : "white",
              }}
            >
              <div
                style={{ flex: "0.05" }}
                ref={inputRef}
                onContextMenu={handleContextMenu}
              >
                <DragIndicatorIcon className="drag-drop-icon" />
                <Menu
                  open={contextMenu !== null}
                  onClose={handleCloseContext}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    contextMenu !== null
                      ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                      : undefined
                  }
                >
                  {product?.isArchived !== 1 && (
                    <MenuItem onClick={handleChangeStatusDialog}>
                      Make it INACTIVE
                    </MenuItem>
                  )}
                </Menu>
              </div>
              <div
                className="fullwidth-flex"
                style={{ display: "grid", gap: 10 }}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: 10,
                  }}
                >
                  <Autocomplete
                    options={productCategories}
                    getOptionLabel={(category) => category.name}
                    value={productCategories.find(
                      (c) => c.id == product.productCategoryId
                    )}
                    onChange={(e, newValue) => {
                      handleUpdateLocalProduct(
                        "productCategoryId",
                        newValue,
                        product?.id
                      );
                    }}
                    disableClearable
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        label="Category"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    value={product.name}
                    fullWidth
                    onChange={(e) => {
                      handleUpdateLocalProduct(
                        "name",
                        e.target.value,
                        product?.id
                      );
                    }}
                    label="Name"
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gap: 10,
                  }}
                >
                  <Autocomplete
                    options={[
                      "Hour",
                      "Day",
                      "Half Day",
                      "Open Budget",
                      "Per Person",
                    ]}
                    value={product.rateType}
                    onChange={(e, newValue) => {
                      handleUpdateLocalProduct(
                        "rateType",
                        newValue,
                        product?.id
                      );
                    }}
                    disableClearable
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                        label="Rate Type"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  <Autocomplete
                    options={["Margin", "Markup"]}
                    value={product.calculationMethod}
                    onChange={(e, newValue) => {
                      handleUpdateLocalProduct(
                        "calculationMethod",
                        newValue,
                        product?.id
                      );
                    }}
                    disableClearable
                    disabled={product.rateType !== "Open Budget"}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        fullWidth
                        label="Margin or Markup %"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={product.costPrice}
                    label={`${
                      product.rateType === "Open Budget"
                        ? `Default ${product.calculationMethod} %`
                        : "Cost Price"
                    }`}
                    onChange={(e) => {
                      handleUpdateLocalProduct(
                        "costPrice",
                        e.target.value,
                        product?.id
                      );
                    }}
                    InputProps={
                      product.rateType !== "Open Budget" && {
                        startAdornment: (
                          <InputAdornment position="start">
                            <AttachMoneyIcon />
                          </InputAdornment>
                        ),
                      }
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={product.sellPrice}
                    onChange={(e) => {
                      handleUpdateLocalProduct(
                        "sellPrice",
                        e.target.value,
                        product?.id
                      );
                    }}
                    label="Sell Price"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachMoneyIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={product.minSellPrice}
                    label={`${
                      product.rateType === "Open Budget"
                        ? `Min ${product.calculationMethod} %`
                        : "Min Sell Price"
                    }`}
                    onChange={(e) => {
                      handleUpdateLocalProduct(
                        "minSellPrice",
                        e.target.value,
                        product?.id
                      );
                    }}
                    InputProps={
                      product.rateType !== "Open Budget" && {
                        startAdornment: (
                          <InputAdornment position="start">
                            <AttachMoneyIcon />
                          </InputAdornment>
                        ),
                      }
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                  <FormControlLabel
                    label="Allow Pass Through"
                    control={
                      <Checkbox
                        checked={product.allowPassThrough}
                        onChange={(e) =>
                          handleUpdateLocalProduct(
                            "allowPassThrough",
                            e.target.checked,
                            product.id
                          )
                        }
                      />
                    }
                  />

                  <Autocomplete
                    options={["Task", "Expense"]}
                    value={product.taskType?.includes("E") ? "Expense" : "Task"}
                    onChange={(e, newValue) => {
                      handleUpdateLocalProduct(
                        "taskType",
                        newValue === "Expense" ? "E" : "T",
                        product?.id
                      );
                    }}
                    disableClearable
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Task Type"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Draggable>
    </>
  );
};

const mapStateToProps = (state) => ({
  productCategories: state.product.productCategories,
});

export default connect(mapStateToProps, { archiveProduct })(ProductsTableRow);
