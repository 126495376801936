import React from "react";
import { getCurrencies } from "./ExchangeRateUtils";

/**
 * Is value numeric
 * 
 * Determine whether variable is a number
 * 
 * @param {*} str 
 *
  import { isNumeric } from '../helpers/general'

  isNumeric(value)
*/
function isNumeric(str) {
  if (["string", "number"].indexOf(typeof str) === -1) return false; // we only process strings and numbers!
  return (
    !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  ); // ...and ensure strings of whitespace fail
}

/**
 * Validate email format
 * 
 * Checks the provided email address and validates its format
 * 
 * @param   {String} email  The email address
 * 
    import { validateEmail } from '../helpers/general'

    validateEmail(email)
 */
function validateEmail(email) {
  const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email?.trim());
  return isValid;
}

/**
 * Validate strong password format
 * 
 * 
 * @param   {String} password  The password
 * 
    import { validateStrongPassword } from '../helpers/general'

    validateStrongPassword(email)
 */
function validateStrongPassword(password) {
  return /(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(password);
}

/**
 * Checks for empty string
 * 
 * @param   {String} email  input
 * 
    import { isEmpty } from '../helpers/general'

    isEmpty(email)
 */
function isEmpty(input) {
  if (input === "" || input === null || input === undefined || input === " ")
    return true;
}
function hasElements(array) {
  if (array && array.length > 0) return true;

  return false;
}

/**
 * Checks if user is authenticated
 * 
 * 
 * 
    import { isAuth } from '../helpers/general'

    isAuth()
 */
function isAuth() {
  const isBrowser = typeof window !== "undefined";
  if (isBrowser) {
    const token = window.localStorage.getItem("key");
    if (token) return true;
    else return false;
  } else {
    return true;
  }
}

function capitalizeWord(word) {
  if (word && word.length > 1) {
    return word[0].toUpperCase() + word.substring(1).toLowerCase();
  }
  return word;
}

function getTeamColours() {
  return [
    "Blue",
    "Purple",
    "Red",
    "People of Influence",
    "Flying Tiger",
    "Community Management",
    "Magnesium",
  ];
}

function currencyNumberFormat(num) {
  if (num == null || isNaN(num)) {
    return 0;
  }
  return num.toFixed(2);
}

function truncateDecimals(num, decimalPlaces) {
  const multiplier = Math.pow(10, decimalPlaces);
  return Math.floor(num * multiplier) / multiplier;
}

function currencySymbolFormat(num, currency = "NZD") {
  const symbol = getCurrencies().find((c) => c.value === currency).symbol;
  if (num == null || isNaN(num)) {
    return `${symbol}0`;
  }
  return `${symbol}${truncateDecimals(num, 2).toLocaleString()}`;
}

function convertToPositive(number) {
  if (number < 0) {
    return Math.abs(number);  // Multiply by -1 to make it positive
  }
  return number;  // If the number is already positive, return it as is
}

export {
  isNumeric,
  validateEmail,
  validateStrongPassword,
  isEmpty,
  isAuth,
  capitalizeWord,
  hasElements,
  getTeamColours,
  currencySymbolFormat,
  currencyNumberFormat,
  truncateDecimals,
  convertToPositive,
};
