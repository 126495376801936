import React, { useEffect, useState } from "react";
import { Header, SidePanelMenu } from "@camistrynz/thomspsonspencer-ui";
import { useHistory } from "react-router-dom";
import { useTheme } from "../../context/theme/ThemeContext";
import { useAuth } from "../../context/auth/AuthContext";

const Layout = ({ children }) => {
  const history = useHistory();
  const { mode, toggleMode } = useTheme();
  const { isLoadingUser, user, hasAdminAccess } = useAuth();
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [adminSection, setAdminSection] = useState(null);

  useEffect(() => {
    document.documentElement.className =
      mode === "dark" ? "dark-mode" : "light-mode";
  }, [mode]);

  useEffect(() => {
    if (hasAdminAccess()) {
      setAdminSection({
        name: "Admin",
        handleOnClick: () => {},
        icon: null,
        innerItems: [
          {
            name: "Products",
            handleOnClick: () => {
              history.push("/admin/products");
              setShowSidePanel(false);
            },
            icon: null,
            innerItems: null,
          },
          {
            name: "Emails",
            handleOnClick: () => {
              history.push("/admin/emails");
              setShowSidePanel(false);
            },
            icon: null,
            innerItems: null,
          },
          {
            name: "Contracts and Terms of Service",
            handleOnClick: () => {
              history.push("/admin/contractsAndTerms");
              setShowSidePanel(false);
            },
            icon: null,
            innerItems: null,
          },
          {
            name: "Exemptions",
            handleOnClick: () => {
              history.push("/admin/exemptions");
              setShowSidePanel(false);
            },
            icon: null,
            innerItems: null,
          },
        ],
      });
    }
  }, [hasAdminAccess]);

  return (
    <div>
      <Header
        systemName="QUOTE BUILDER"
        handleNavMenuClick={() =>
          !showSidePanel ? setShowSidePanel(true) : setShowSidePanel(false)
        }
        handleTitleClick={() => history.push("/home")}
        handleToggleMode={toggleMode}
        mode={mode}
      />
      {showSidePanel && user && (
        <SidePanelMenu
          isOpen={showSidePanel}
          handleClose={() => setShowSidePanel(false)}
          firstName={user?.firstName}
          adminSection={adminSection}
        />
      )}
      <div className={`body-container`}>{children}</div>
    </div>
  );
};

export default Layout;
