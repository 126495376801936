import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    CircularProgress
} from "@mui/material";
import { isEmpty } from "../../Utils/GeneralUtils";

const ConfirmationDialogWithInput = (props) => {
    const {
        loading = false
    } = props;
    const [inputValue, setInputValue] = useState("");

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={(e, reason) => {
                    if (reason !== "backdropClick") {
                        props.handleClose()
                    }
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ paper: "confirmation-with-input-dialog" }}
            >
                <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.description}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label={props.textFieldLabel}
                        type="text"
                        fullWidth
                        variant="filled"
                        multiline
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                </DialogContent>
                {loading ? (
                    <DialogActions style={{padding: 10}}>
                        <CircularProgress />
                    </DialogActions>
                ) : (
                    <DialogActions>
                        <Button disabled={loading} className="default-button" variant="contained" onClick={props.handleClose}>Cancel</Button>
                        <Button
                            disabled={isEmpty(inputValue) || loading}
                            className={isEmpty(inputValue) ? "" : "default-button"}
                            variant="contained"
                            onClick={() => props.handleSubmit(inputValue)}
                            autoFocus>
                            {props.submitLabel}
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        </div>
    );
};

export default ConfirmationDialogWithInput;
