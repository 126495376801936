import React, { useState, useEffect } from 'react';
import "./companyActivity.scss"
import { connect } from "react-redux";
import axios from "axios";
import {
  IconButton
} from "@mui/material";
import {
  Edit as EditIcon,
  OpenInNew as OpenInNewIcon
} from "@mui/icons-material";

const CompanyActivity = (props) => {
  const {
    company,
    selectedOrganisation
  } = props;

  const [history, setHistory] = useState([]);

  useEffect(() => {
    axios.get(`Company/GetCompanyHistory/${company.id}`)
      .then(res => {
        setHistory(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }, []);

  return (
    <div className='company-activity-root'>
      {company && company.id && (
        <div>
          <table className="table table-striped table-hover text-nowrap mb-0 table-sm">
            <thead className="thead-magnesium">
              <tr>
                <th className="text-center">Activity</th>
                <th className="text-center">Date</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {company.hasSignedMsa && (
                <tr className="text-center">
                  <td>Signed MSA Document</td>
                  <td></td>
                  <td>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <IconButton
                        className="default-icon"
                        onClick={e => {
                          window.open(`https://drive.google.com/file/d/${company?.msapdfPreview}/view`)
                        }}
                      >
                        <OpenInNewIcon style={{ color: "black" }} />
                      </IconButton>
                    </div>
                  </td>
                </tr>
              )}
              {history.map(item => {
                return (
                  <tr className="text-center">
                    <td>{item.activityName}</td>
                    <td>{item.activityTimeAsString}</td>
                    <td>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <IconButton
                          className="default-icon"
                          onClick={e => {
                            window.open(`${item.fileUrl}`)
                          }}
                        >
                          <OpenInNewIcon style={{ color: "black" }} />
                        </IconButton>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

//export default CompanyActivity
const mapStateToProps = (state) => ({
  selectedOrganisation: state.organisation.selectedOrganisation,
});

export default connect(mapStateToProps, {

})(CompanyActivity);