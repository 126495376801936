import React, { useState, useEffect } from 'react';

const TableauDashboard = () => {
    const [windowDimensions, setWindowDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        // Load Tableau JavaScript API
        const scriptElement = document.createElement('script');
        scriptElement.src = 'https://public.tableau.com/javascripts/api/viz_v1.js';
        document.head.appendChild(scriptElement);

        // Adjust visualization dimensions based on viewport size while maintaining aspect ratio
        const adjustVizDimensions = () => {
            setWindowDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        // Set initial dimensions
        adjustVizDimensions();

        // Add resize event listener to adjust dimensions on window resize
        window.addEventListener('resize', adjustVizDimensions);

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', adjustVizDimensions);
        };
    }, []); // Empty dependency array ensures that this effect runs once when the component mounts

    useEffect(() => {
        // Accessing the visualization element and adjusting dimensions
        const divElement = document.getElementById('viz1701036803478');
        const vizElement = divElement?.getElementsByTagName('object')[0];

        if (vizElement) {
            const aspectRatio = 1577 / 1400; // Aspect ratio of your original dimensions
            const width = Math.min(windowDimensions.width, 1400); // Set a maximum width based off tableau graph
            const height = width * aspectRatio;

            vizElement.style.width = `${width}px`;
            vizElement.style.height = `${height}px`;

            // Center the visualization within its container
            vizElement.style.marginLeft = 'auto !important';
            vizElement.style.marginRight = 'auto !important';
            vizElement.style.marginTop = 'auto !important';
            vizElement.style.marginBottom = 'auto !important';
            vizElement.style.display = 'block';
        }
    }, [windowDimensions]);

    return (
        <div id="tableau-graph-container"
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "center"
            }}
        >
            <div className='tableauPlaceholder' id='viz1701036803478' style={{ position: 'relative' }}>
                <noscript>
                    <a href='#'>
                        <img
                            alt='3. Revenue '
                            src='https://public.tableau.com/static/images/Jo/JobsDashboard1_9/3_Revenue/1_rss.png'
                            style={{ border: 'none' }}
                        />
                    </a>
                </noscript>
                <object className='tableauViz' style={{ display: 'none' }}>
                    <param name='host_url' value='https%3A%2F%2Fpublic.tableau.com%2F' />
                    <param name='embed_code_version' value='3' />
                    <param name='site_root' value='' />
                    <param name='name' value='JobsDashboard1_9/3_Revenue' />
                    <param name='tabs' value='no' />
                    <param name='toolbar' value='yes' />
                    <param name='static_image' value='https://public.tableau.com/static/images/Jo/JobsDashboard1_9/3_Revenue/1.png' />
                    <param name='animate_transition' value='yes' />
                    <param name='display_static_image' value='yes' />
                    <param name='display_spinner' value='yes' />
                    <param name='display_overlay' value='yes' />
                    <param name='display_count' value='yes' />
                    <param name='language' value='en-US' />
                    <param name='filter' value='publish=yes' />
                </object>
            </div>
        </div>

    );
};

export default TableauDashboard;