import axios from "axios";

function getCurrencies(shouldExcludeNZ = false) {
  let currenciesWithoutNZ = [
    {
      value: "AUD",
      code: "AU",
      symbol: "AU$",
    },
    {
      value: "EUR",
      code: "EU",
      symbol: "€",
    },
    {
      value: "GBP",
      code: "GB",
      symbol: "£",
    },
    {
      value: "USD",
      code: "US",
      symbol: "US$",
    },
    {
      value: "JPY",
      code: "JP",
      symbol: "¥",
    },
    {
      value: "CNY",
      code: "CN",
      symbol: "¥",
    },
  ];
  if (shouldExcludeNZ) {
    return currenciesWithoutNZ;
  } else {
    return [
      {
        value: "NZD",
        code: "NZ",
        symbol: "$",
      },
      ...currenciesWithoutNZ,
    ];
  }
}

function getCurrencyObject(currency) {
  const currencies = [
    {
      value: "NZD",
      code: "NZ",
      symbol: "$",
      locale: "en-NZ"
    },
    {
      value: "AUD",
      code: "AU",
      symbol: "AU$",
      locale: "en-AU"
    },
    {
      value: "EUR",
      code: "EU",
      symbol: "€",
      locale: "fr-LU"
    },
    {
      value: "GBP",
      code: "GB",
      symbol: "£",
      locale: "en-GB"
    },
    {
      value: "USD",
      code: "US",
      symbol: "US$",
      locale: "en-US"
    },
    {
      value: "JPY",
      code: "JP",
      symbol: "¥",
      locale: "ja-JP"
    },
    {
      value: "CNY",
      code: "CN",
      symbol: "¥",
      locale: "zh-CN"
    },
  ];

  var foundCurrency = currencies.find(c => c.value === currency);
  if (foundCurrency) {
    return foundCurrency;
  } else {
    return {
      value: "NZD",
      code: "NZ",
      symbol: "$",
      locale: "en-NZ"
    };
  }
}

function calculateCurrencyRate(baseCurrencyValue, targetCurrencyValue) {
  // Calculate the rate of target currency in terms of base currency
  const result = Number(baseCurrencyValue) / Number(targetCurrencyValue);
  return Math.floor(result * 100) / 100; // truncate to 2 decimal places without rounding.
}

async function getLatestCurrencyRate(currency) {
  const res = await axios.get(`/ExchangeRate/GetLatestRates`);
  if (res?.data?.rates && res?.data?.marginRate) {
    const { rates, marginRate } = res.data;
    return rates[currency] + rates[currency] * marginRate;
  }
}

export { getLatestCurrencyRate, getCurrencies, calculateCurrencyRate, getCurrencyObject };
