import React, { useEffect, useState, useRef, Fragment } from "react";
import { Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import "../admin.scss";

const UploadVideos = () => {
  const inputFile = useRef(null);
  const [videosToUpload, setVideosToUpload] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleUploadFile = (videos) => {
    let newVideos = [];
    // let newVideos = videos.map((i) => {
    //   return { image: i, url: URL.createObjectURL(i) };
    // });
    for (let i = 0; i < videos.length; i++) {
      newVideos.push({
        video: videos[i],
        name: videos[i].name,
      });
    }

    setVideosToUpload(...videosToUpload, newVideos);
  };

  const openFileExplorer = () => {
    inputFile.current.click();
  };

  const uploadToCloud = () => {
    let formData = new FormData();
    videosToUpload.forEach((file) => {
      formData.append("file", file.video);
      formData.append("name", file.name);
    });
    setUploading(true);
    axios
      .post("iPad/UploadToStorage", formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((res) => {
        // console.log(res);
        setUploading(false);
        //Snackbar success
      })
      .catch((error) => {
        // console.log(error);
        setUploading(false);
        //Snackbar failed
      });
  };

  return (
    <div>
      <Button
        id="upload-to-cloudstorage"
        variant="contained"
        onClick={openFileExplorer}
        size="small"
      >
        <input
          id="camera-input"
          type="file"
          accept="video/*"
          ref={inputFile}
          multiple
          style={{ display: "none" }}
          onChange={(e) => {
            handleUploadFile(e.target.files);
          }}
        />
        Select Video
      </Button>
      <div>
        {videosToUpload.map((i, index) => (
          <div key={i.name} style={{ display: "flex", width: "100%" }}>
            <div className="vertical-center">
              <Typography variant="subtitle1">{i.name}</Typography>
            </div>
          </div>
        ))}
        <div style={{ marginTop: "30px" }}>
          <Button variant="contained" color="primary" onClick={uploadToCloud}>
            Upload Videos
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UploadVideos;
