import React, { useState, useEffect } from "react";
import {
  Alert,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { currencySymbolFormat } from "../../../Utils/GeneralUtils";

const UsedInternalRevenueTable = ({
  internalBillingRevenueUsage,
  isSavingInternalRevenueUsage,
  handleRemoveInternalRevenue,
  totalAllocated
}) => {

  return (
    <TableContainer component={Paper} style={{ padding: 20 }}>
      {isSavingInternalRevenueUsage && (<Alert severity="info">Saving your changes.</Alert>)}
      <Typography variant="h6">Allocated Budget</Typography>
      <Table size="small">
        <TableHead>
          <TableCell>
            <Typography variant="caption"><b>Job Name</b></Typography>
          </TableCell>
          <TableCell>
            <Typography variant="caption"><b>Category</b></Typography>
          </TableCell>
          <TableCell>
            <Typography variant="caption"><b>Allocated Amount</b></Typography>
          </TableCell>
          <TableCell></TableCell>
        </TableHead>
        <TableBody>
          {internalBillingRevenueUsage?.map((record, index) => (
            <TableRow key={index}>
              <TableCell>
                <Typography variant="caption">
                  {`[${record.internalBillingRevenue?.jobId}] ${record.internalBillingRevenue?.job?.jobName}`}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">{record?.internalBillingRevenue?.category}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {record.amount?.toLocaleString("en-NZ", {
                    style: "currency",
                    currency: "NZD",
                    minimumFractionDigits: 2,
                  })}
                </Typography>
              </TableCell>
              <TableCell style={{ width: 220, textAlign: "right" }}>
                <Button
                  onClick={() => { handleRemoveInternalRevenue(record) }}
                  // record?.internalBillingRevenueId
                  variant="outlined"
                  size="small"
                  disabled={isSavingInternalRevenueUsage}
                >
                  Remove
                </Button>
              </TableCell>
            </TableRow>
          ))}
          {internalBillingRevenueUsage && (
            <TableRow key={internalBillingRevenueUsage?.length + 1}>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <Typography variant="caption">
                  Total:{" "}<b>{currencySymbolFormat(totalAllocated)}</b>
                </Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UsedInternalRevenueTable;
