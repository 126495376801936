import {
    Typography,
    Grid,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Snack from "../../Shared/Snack";
import { connect } from "react-redux";

const Settings = (props) => {
    const {
        selectedOrganisation
    } = props
    const [openSnack, setOpenSnack] = useState(false);
    const [snackSeverity, setSnackSeverity] = useState("info");
    const [snackText, setSnackText] = useState(false);



    return (
        <div style={{ margin: "0px 20%" }}>
            <Snack
                open={openSnack}
                setOpen={setOpenSnack}
                severity={snackSeverity}
                text={snackText}
            />
            <div className="text-center">
                <Typography variant="h4">Settings</Typography>
                <Grid container>
                    {/* <Grid item xs={}>

                    </Grid> */}
                </Grid>
            </div>
        </div>
    );
};

//export default Emails;
const mapStateToProps = (state) => ({
    selectedOrganisation: state.organisation.selectedOrganisation,
});

export default connect(mapStateToProps, {})(Settings);
