import { Skeleton, Typography } from "@mui/material";
import React from "react";

const LoadingTableSkeleton = (props) => {
  return (
    <tr style={{ width: "100%" }}>
      <td className="text-center" colSpan={props.colspan}>
        <Typography variant="subtitle1">{props.loadingMessage}</Typography>
        <Skeleton />
        <Skeleton />
      </td>
    </tr>
  );
};

export default LoadingTableSkeleton;
