import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  companies: [],
  selectedCompany: {},
  companyFilter: {
    companyId: 0
  }
};
const company = createReducer(initialState, {
  LOG_ERROR: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      error: payload,
    };
  },
  GET_COMPANIES_SUCCESS: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      companies: payload,
    };
  },
  ADD_COMPANY_STORE: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      companies: [...state.companies, payload],
    };
  },
  UPDATE_COMPANY_STORE: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      companies: state.companies.map(c => c.id === payload.id ? payload : c)
    };
  },
  UPDATE_COMPANY_FILTER_STORE: (state, action) => {
    const { field, value } = action.payload;
    return {
      ...state,
      companyFilter: { ...state.companyFilter, [field]: value },
    };
  },
});

export default company;
