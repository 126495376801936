import { Button, IconButton, Typography } from "@mui/material";
import React from "react";
import "./resultsTable.scss";

const ResultsTable = ({ contracts }) => {
  return (
    <div className="contracts-results-table-root">
      {contracts?.length > 0 ? (
        <div>
          <table className="table table-striped table-hover text-nowrap mb-0 table-sm">
            <thead className="thead-magnesium">
              <tr>
                <th className="text-center">Name</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {contracts.length > 0 ? (
                contracts.map((contract) => (
                  <tr key={contract.id}>
                    <td className="text-center">{contract.name}</td>
                    <td>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {contract.templateUrl && (
                          <Button
                            variant="contained"
                            className="default-button"
                            onClick={() =>
                              window.open(contract.templateUrl, "_blank")
                            }
                          >
                            Open Template
                          </Button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <div></div>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <Typography variant="h4">No Records Found</Typography>
      )}
    </div>
  );
};

export default ResultsTable;
