import {
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
  Autocomplete,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  searchInvoices,
  updateInvoiceStore,
  removeInvoiceFromStore,
} from "../../reduxActions/invoice";
import DocumentViewer from "../Shared/DocumentViewer";
import dayjs from "dayjs";
import axios from "axios";
import InvoiceFilters from "./InvoiceFilters";
import SendInvoiceDialog from "./SendInvoiceDialog";
import LoadingTableSkeleton from "../Shared/LoadingTableSkeleton";
import CustomTableHead from "../Shared/CustomTableHead";
import { getComparator } from "../../Utils/TableUtils";
import SplitInvoiceDialog from "./SplitInvoiceDialog";
import Snack from "../Shared/Snack";
import CustomButtonGroup from "../Shared/CustomButtonGroup";
import InvoiceDateDisplay from "../Shared/InvoiceDateDisplay";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import EditSingleFieldDialog from "../Shared/EditSingleFieldDialog";
import {
  Edit as EditIcon,
  Save as SaveIcon,
  PictureAsPdf as PictureAsPdfIcon,
} from "@mui/icons-material";
import "./invoice.scss";
import { get12MonthsAheadAnd1MonthBackArr } from "../../Utils/JobHelpers";
import ConfirmSetAsSentDialog from "./ConfirmSetAsSentDialog";
import { currencySymbolFormat } from "../../Utils/GeneralUtils";

const invoiceTableHeader = [
  {
    field: "companyName",
    name: "Company",
    fieldType: "string",
    alignment: "left",
  },
  {
    field: "jobName",
    name: "Job Name",
    fieldType: "string",
    alignment: "left",
  },
  {
    field: "date",
    name: "Invoice Date",
    fieldType: "date",
    alignment: "left",
  },
  {
    field: "total",
    name: "Total",
    fieldType: "numeric",
    alignment: "right",
  },
  {
    field: "poNumber",
    name: "PO Number",
    fieldType: "string",
  },
  {
    field: "status",
    name: "Invoice Status",
    fieldType: "string",
  },
  {
    field: "date",
    name: "Invoice Month",
    fieldType: "date",
    alignment: "right",
  },

  {
    field: "invoiceNumber",
    name: "",
    fieldType: "invoiceNumber",
  },
  {
    field: "",
    name: "",
    fieldType: "string",
  },
];

const Invoices = (props) => {
  let {
    updateInvoiceStore,
    removeInvoiceFromStore,
  } = props;
  const bottomElementRef = useRef(null);

  const [invoiceUrl, setInvoiceUrl] = useState("");
  const [showDocumentViewer, setShowDocumentViewer] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [openSendInvoiceDialog, setOpenSendInvoiceDialog] = useState(false);
  const [xeroInvoice, setXeroInvoice] = useState(null);
  const [loadingXeroInvoice, setLoadingXeroInvoice] = useState(false);
  const [order, setOrder] = useState("asc");
  const [openSplitInvoiceDialog, setOpenSplitInvoiceDialog] = useState(false);
  const [orderBy, setOrderBy] = useState({
    field: "date",
    name: "Invoice Date",
    fieldType: "date",
  });
  const [openSnack, setOpenSnack] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState("success");
  const [snackText, setSnackText] = useState(false);
  const [openPoNumberEdit, setOpenPoNumberEdit] = useState(false);
  const [poNumberEdit, setPoNumberEdit] = useState(null);
  const [openSetAsSentDialog, setOpenSetAsSentDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchJobInvoicesQueue, setSearchJobInvoicesQueue] = useState([]);
  const [lastSearchJobInvoicesFn, setLastSearchJobInvoicesFn] = useState(null);
  const [savingPO, setSavingPO] = useState(false);
  const [poFieldProperties, setPoFieldProperties] = useState({
    label: "PO Number",
    value: "",
    inputType: "text",
  });

  const [invoices, setInvoices] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [invoiceFilter, setInvoiceFilter] = useState({
    includeSent: false,
    awaitingPoNumber: false,
    poNumber: "",
    userEmail: "",
    companyId: 0,
  },);

  //Infinite Scroll

  useEffect(() => {
    if (!isLoading && hasMoreData && !isLoadingMore) {
      const observer = new IntersectionObserver(
        async (entries) => {
          if (entries[0].isIntersecting) {
            setIsLoadingMore(true); // prevent making multiple calls
            await searchJobInvoices();
            setIsLoadingMore(false);
          }
        },
        { threshold: [1] }
      ); // By specifying [1] as the threshold value, you're instructing the Intersection Observer to trigger when the target element becomes fully visible.

      if (bottomElementRef.current) {
        observer.observe(bottomElementRef.current);
      }

      return () => {
        if (bottomElementRef.current) {
          observer.unobserve(bottomElementRef.current);
        }
      };
    }
  }, [isLoading, hasMoreData, isLoadingMore]);

  useEffect(() => {
    handleSearchInvoices();
  }, [invoiceFilter]);

  const addToSearchInvoicesQueue = (asyncFn) => {
    setSearchJobInvoicesQueue((prevQueue) => [...prevQueue, asyncFn]);
  };

  useEffect(() => {}, [selectedInvoice]);

  const handleOpenPdf = (invoiceId, organisationId) => {
    setInvoiceUrl(`/Invoice/GetInvoiceAsPdf/${invoiceId}/${organisationId}`);
    setShowDocumentViewer(true);
  };

  const handleSearchInvoices = () => {
    setHasMoreData(true);
    searchJobInvoices(true);
  };

  const searchJobInvoices = async (shouldBypass = false) => {
    if (!shouldBypass) {
      if (!hasMoreData || isLoading || isLoadingMore) {
        return; // No more data to fetch
      }
    }
    setIsLoading(true);
    axios
      .post("Invoice/SearchInvoices", invoiceFilter, {
        params: {
          page: currentPage,
          pageSize: 40,
        },
      })
      .then(({ data }) => {
        if (data.length === 0) {
          setHasMoreData(false);
          if (currentPage == 0) {
            setInvoices([]);
          }
        } else {
          if (currentPage === 0) {
            setInvoices(data);
          } else {
            setInvoices((prevInvoices) => [...prevInvoices, ...data]);
          }
          setCurrentPage(currentPage + 1);
        }
      })
      .finally(() => setIsLoading(false));

    return;
  };

  const handleChangeInvoiceMonth = (selectedDate) => {
    var month = dayjs(selectedDate).month();
    var year = dayjs(selectedDate).year();
    axios
      .put(
        `Invoice/UpdateInvoiceBillingMonth/${selectedInvoice.id}/${
          month + 1
        }/${year}`
      )
      .then(({ data }) => {
        if (data) {
          let invoiceClone = { ...selectedInvoice };
          invoiceClone.dueDate = data.dueDate;
          invoiceClone.date = data.date;
          updateInvoiceStore(invoiceClone);
        }
        setSelectedInvoice(null);
      });
  };

  const handleSendNowButton = (invoice) => {
    setSelectedInvoice(invoice);
    setLoadingXeroInvoice(true);
    axios
      .post(`Invoice/ManuallyGenerateXeroInvoice/${invoice.id}`)
      .then(({ data }) => {
        if (data) {
          setOpenSendInvoiceDialog(true);
          setXeroInvoice(data);
          setLoadingXeroInvoice(false);
        } else {
          setLoadingXeroInvoice(false);
          alert("Something went wrong!");
        }
      });
  };

  const handleCloseSendNowDialog = () => {
    setOpenSendInvoiceDialog(false);
    setSelectedInvoice(null);
  };

  const handleConfirmSendInvoice = () => {
    if (xeroInvoice) {
      axios
        .post(
          `Invoice/ManuallySendXeroInvoice/${selectedInvoice.id}`,
          xeroInvoice
        )
        .then(({ data }) => {
          if (data) {
            //updateInvoiceStore(data);
            removeInvoiceFromStore(data);
            handleCloseSendNowDialog();
          } else {
            alert("Something went wrong. The Dev team has been notified!");
            handleCloseSendNowDialog();
          }
        });
    }
  };

  const handleOnRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleInvoiceChanges = (xeroInvoice, invoice) => {
    setXeroInvoice(xeroInvoice);
    if (invoice) {
      axios
        .post(`Invoice/UpdateInvoiceFromSendNow/${invoice.id}`, invoice)
        .then(({ data }) => {
          if (data) {
            setSelectedInvoice(data);
            updateInvoiceStore(data);
          } else {
            alert("Something went wrong.");
          }
        });
    }
  };

  const handleCloseSplitInvoiceDialog = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    } else {
      setOpenSplitInvoiceDialog(false);
      setSelectedInvoice(null);
    }
  };

  const handleSplitInvoices = (
    firstInvoice,
    secondInvoice,
    chosenType,
    chosenValue
  ) => {
    if (firstInvoice && secondInvoice && selectedInvoice) {
      setLoading(true);
      axios
        .post(
          `Invoice/SplitInvoices/${selectedInvoice.id}/?chosenType=${chosenType}&chosenValue=${chosenValue}`,
          [firstInvoice, secondInvoice]
        )
        .then(({ data }) => {
          if (data) {
            addToSearchInvoicesQueue(searchJobInvoices);
            setSnackText("Invoices saved with success!");
            setSnackSeverity("success");
            setOpenSnack(true);
            handleCloseSplitInvoiceDialog();
          } else {
            setSnackText("Failed to save the invoices!");
            setSnackSeverity("error");
            setOpenSnack(true);
          }
        })
        .catch((err) => {
          setSnackText("Failed to save the invoices!");
          setSnackSeverity("error");
          setOpenSnack(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleButtonGroup = (option) => {
    if (option.id === "SEND_NOW") {
      handleSendNowButton(option.invoice);
    } else if (option.id === "SPLIT_INVOICE") {
      setOpenSplitInvoiceDialog(true);
    } else if (option.id === "FLAG_AS_SENT") {
      setOpenSetAsSentDialog(true);
    }
  };

  const handleOpenPoNumberEdit = (e, invoice) => {
    e.preventDefault();
    setSelectedInvoice(invoice);
    setPoNumberEdit(invoice.poNumber);
    setPoFieldProperties({
      label: "PO Number",
      value: invoice.poNumber,
      inputType: "text",
    });
    setOpenPoNumberEdit(true);
  };

  const handleClosePoNumberEdit = () => {
    setOpenPoNumberEdit(false);
    setSelectedInvoice(null);
    setPoNumberEdit(null);
  };

  const handleSavePoNumber = (poNumber) => {
    setSavingPO(true);
    if (!poNumber) {
      poNumber = "";
    }
    axios
      .patch(
        `Invoice/PatchInvoicePoNumber?invoiceId=${selectedInvoice.id}&poNumber=${poNumber}`
      )
      .then(({ data }) => {
        if (data) {
          let invoiceClone = { ...selectedInvoice };
          invoiceClone.poNumber = data.poNumber;
          updateInvoiceStore(invoiceClone);
          handleClosePoNumberEdit();
        }
        setSelectedInvoice(null);
      })
      .finally(() => {
        setSavingPO(false);
        setPoFieldProperties({
          label: "PO Number",
          value: "",
          inputType: "text",
        });
      });
  };

  const handleSaveSetAsSent = (status) => {
    axios
      .put(`Invoice/UpdateStatusAndFlagAsSent/${selectedInvoice.id}/${status}`)
      .then(({ data }) => {
        if (data) {
          let invoiceClone = { ...selectedInvoice };
          invoiceClone.isSent = data.isSent;
          invoiceClone.status = data.status;
          updateInvoiceStore(invoiceClone);
          setSnackText("Invoices saved !");
          setSnackSeverity("success");
          setOpenSnack(true);
          handleCloseSetAsSentDialog();
        }
        setSelectedInvoice(null);
      });
  };

  const handleCloseSetAsSentDialog = () => {
    setOpenSetAsSentDialog(false);
    setSelectedInvoice(null);
  };

  // useEffect(() => {
  //   if (searchJobInvoicesQueue.length > 0) {
  //     // Clear any existing timer
  //     clearTimeout(lastSearchJobInvoicesFn);

  //     // Create a new timer to execute the last function in the searchJobsQueue after a delay
  //     const timeoutId = setTimeout(() => {
  //       setLastSearchJobInvoicesFn(null); // Clear the last function after it is executed
  //       const lastFunc = searchJobInvoicesQueue[searchJobInvoicesQueue.length - 1];
  //       lastFunc().then(() => {
  //         setSearchJobInvoicesQueue([]);
  //       });
  //     }, 1000);

  //     // Set the new timer to the state
  //     setLastSearchJobInvoicesFn(timeoutId);
  //   }
  // }, [searchJobInvoicesQueue]);

  const handleUpdateInvoiceFilter = (field, value) => {
    setCurrentPage(0);
    setInvoiceFilter({...invoiceFilter, [field]: value });
  }

  return (
    <div>
      <Snack
        open={openSnack}
        setOpen={setOpenSnack}
        severity={snackSeverity}
        text={snackText}
      />
      {showDocumentViewer && (
        <DocumentViewer
          modalState={showDocumentViewer}
          handleModal={() => {
            setShowDocumentViewer(false);
          }}
          headerColor="#000"
          docType={"PDF"}
          urlFile={invoiceUrl}
        />
      )}
      {openSendInvoiceDialog && (
        <SendInvoiceDialog
          open={openSendInvoiceDialog}
          handleClose={handleCloseSendNowDialog}
          xeroInvoice={xeroInvoice}
          invoice={selectedInvoice}
          handleConfirmSendInvoice={handleConfirmSendInvoice}
          handleInvoiceChanges={handleInvoiceChanges}
        />
      )}
      {openSplitInvoiceDialog && (
        <SplitInvoiceDialog
          open={openSplitInvoiceDialog}
          handleClose={handleCloseSplitInvoiceDialog}
          invoice={selectedInvoice}
          handleSave={handleSplitInvoices}
          setSnackSeverity={setSnackSeverity}
          setSnackText={setSnackText}
          setOpenSnack={setOpenSnack}
          loading={loading}
        />
      )}
      {openPoNumberEdit && selectedInvoice && (
        <EditSingleFieldDialog
          open={openPoNumberEdit}
          handleClose={handleClosePoNumberEdit}
          handleSaveEdit={handleSavePoNumber}
          fieldProperties={poFieldProperties}
          loading={savingPO}
        />
      )}
      {openSetAsSentDialog && selectedInvoice && (
        <ConfirmSetAsSentDialog
          open={openSetAsSentDialog}
          handleClose={handleCloseSetAsSentDialog}
          handleSubmit={handleSaveSetAsSent}
          invoice={selectedInvoice}
        />
      )}

      <InvoiceFilters handleUpdateInvoiceFilter={handleUpdateInvoiceFilter} invoiceFilter={invoiceFilter}/>
      {!isLoading && invoices?.length === 0 ? (
        <div className="text-center">
          <Typography variant="h4">No invoices found</Typography>
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table table-striped table-hover mb-0 invoice-table-root">
            <CustomTableHead
              headers={invoiceTableHeader}
              orderBy={orderBy}
              order={order}
              onRequestSort={handleOnRequestSort}
            />
            <tbody>
              {[...invoices].sort(getComparator(order, orderBy)).map((i) => (
                <tr
                  key={i.id}
                  onClick={(e) => {
                    setSelectedInvoice(i);
                  }}
                  className={
                    i.date &&
                    `month-color-${dayjs(i.date).format("MMMM").toLowerCase()}`
                  }
                  style={{ cursor: "context-menu" }}
                >
                  <td className="invoice-table-company-name">
                    {i.company.name}
                  </td>
                  <td className="no-padding invoice-table-job-name">
                    {i.jobName}
                  </td>
                  <td className="no-padding">
                    <InvoiceDateDisplay invoice={i} />
                  </td>
                  <td className="no-padding invoice-table-align-right">
                    {currencySymbolFormat(i?.exchangedTotal, i.currency)}
                  </td>

                  <td className="no-padding">
                    <div
                      className="invoice-table-po-number"
                      onClick={(e) => handleOpenPoNumberEdit(e, i)}
                    >
                      {i.poNumber}
                      {!openPoNumberEdit && i.requiredPO && !i.poNumber && (
                        <Tooltip title="Missing PO Number">
                          <IconButton
                            onClick={(e) => handleOpenPoNumberEdit(e, i)}
                          >
                            <WarningAmberIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {!openPoNumberEdit && !i.requiredPO && !i.poNumber && (
                        <Tooltip title="Add new PO Number">
                          <Button
                            onClick={(e) => handleOpenPoNumberEdit(e, i)}
                            className="invoices-table-add-po-number-btn"
                          >
                            Add
                          </Button>
                        </Tooltip>
                      )}
                    </div>
                  </td>
                  <td className="no-padding invoice-table-align-center">
                    {i.status.toUpperCase()}
                  </td>
                  <td
                    id="invoice-month-select-cell"
                    className="no-padding invoice-table-align-right invoice-month-autocomplete"
                    onClick={(e) => {
                      setSelectedInvoice(i);
                    }}
                  >
                    <Autocomplete
                      value={
                        i.date
                          ? {
                              display: dayjs(i.date).format("MMMM YYYY"),
                              value: i.date,
                            }
                          : null
                      }
                      options={get12MonthsAheadAnd1MonthBackArr()}
                      onChange={(event, option) => {
                        handleChangeInvoiceMonth(option.value);
                      }}
                      getOptionLabel={(option) => option.display}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          disabled={i.isSent}
                          label={"Billing Month"}
                          size="small"
                          fullWidth={true}
                          variant="filled"
                        />
                      )}
                      disableClearable
                      disabled={
                        i.status === "draft" || i.status === "placeholder"
                          ? true
                          : false
                      }
                    />
                  </td>
                  <td
                    onClick={(e) => {
                      handleOpenPdf(
                        i.newXeroInvoiceId || i.xeroInvoiceId,
                        i.newXeroInvoiceId ? -1 : i.organisationId
                      );
                    }}
                    className="no-padding"
                  >
                    <div className="no-padding">
                      {i.xeroInvoiceId && (
                        <PictureAsPdfIcon
                          fontSize="small"
                          style={{ cursor: "pointer", marginLeft: 5 }}
                        />
                      )}
                    </div>
                  </td>
                  <td
                    onClick={(e) => {
                      setSelectedInvoice(i);
                    }}
                    className="no-padding"
                  >
                    {!i.isSent && (
                      <CustomButtonGroup
                        handleClick={handleButtonGroup}
                        loading={
                          loadingXeroInvoice && i.id === selectedInvoice?.id
                        }
                        options={[
                          { id: "SEND_NOW", title: "Send Now", invoice: i },
                          {
                            id: "SPLIT_INVOICE",
                            title: "New Progress Invoice",
                            invoice: i,
                          },
                          {
                            id: "FLAG_AS_SENT",
                            title: "Set as Sent",
                            invoice: i,
                          },
                        ]}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {isLoading && (
            <div style={{ display: "grid" }}>
              <Skeleton height={50} />
              <Skeleton height={50} />
              <Skeleton height={50} />
            </div>
          )}

          <div ref={bottomElementRef}></div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  invoices: state.invoice.invoices,
  invoiceFilter: state.invoice.invoiceFilter,
  selectedOrganisation: state.organisation.selectedOrganisation,
});

export default connect(mapStateToProps, {
  searchInvoices,
  updateInvoiceStore,
  removeInvoiceFromStore,
})(Invoices);
