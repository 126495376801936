import Close from "@mui/icons-material/Close";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import SplitInvoicePreview from "./SplitInvoicePreview";
import SplitInvoiceWizard from "./SplitInvoiceWizard";
import { calculateCurrencyRate } from "../../Utils/ExchangeRateUtils";

const SplitInvoiceDialog = ({
  open,
  handleClose,
  invoice,
  handleSave,
  setSnackText,
  setSnackSeverity,
  setOpenSnack,
  loading
}) => {
  const [firstInvoice, setFirstInvoice] = useState(null);
  const [secondInvoice, setSecondInvoice] = useState(null);
  const [maxTotal, setMaxTotal] = useState(0);
  const [isWizardComplete, setIsWizardComplete] = useState(false);
  const [wizardTotal, setWizardTotal] = useState(0);
  const [finalInvoiceDate, setFinalInvoiceDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [chosenType, setChosenType] = useState(null);
  const [chosenValue, setChosenValue] = useState(null);
  const [currencyRate, setCurrencyRate] = useState(calculateCurrencyRate(1,invoice?.exchangeRate));

  useEffect(() => {
    if (invoice) {
      let newInvoice = { ...invoice };
      newInvoice.date = null;
      newInvoice.dueDate = null;
      newInvoice.total = 0;
      newInvoice.exchangedTotal = 0;
      setFirstInvoice({ ...invoice });
      setSecondInvoice(newInvoice);
      setCurrencyRate(calculateCurrencyRate(1,invoice?.exchangeRate))
      setMaxTotal(invoice.exchangedTotal);
    }
  }, [invoice]);

  const calculateRemainingAmount = (value) => {
    return maxTotal - value;
  };

  const handleFirstInvoiceChange = (field, value) => {
    if(loading){
      return;
    }
    const remainingTotal = calculateRemainingAmount(value)
    if (field === "exchangedTotal") {
      if (value > maxTotal) {
        value = maxTotal;
      }
      setSecondInvoice({
        ...secondInvoice,
        total: remainingTotal * currencyRate,
        exchangedTotal: remainingTotal,
      });
      setFirstInvoice({ ...firstInvoice, [field]: value, total: value * currencyRate, });
    }else{
      setFirstInvoice({ ...firstInvoice, [field]: value });
    }
  };

  const handleSecondInvoiceChange = (field, value) => {
    if(loading){
      return;
    }
    const remainingTotal = calculateRemainingAmount(value)
    if (field === "exchangedTotal") {
      if (value > maxTotal) {
        value = maxTotal;
      }
      setFirstInvoice({
        ...firstInvoice,
        total: remainingTotal * currencyRate,
        exchangedTotal: calculateRemainingAmount(value),
      });
      setSecondInvoice({ ...secondInvoice, [field]: value, total: value * currencyRate, });
    }else{
      setSecondInvoice({ ...secondInvoice, [field]: value });
    }
    
  };

  const validateInvoices = () => {
    if (
      !firstInvoice.date ||
      !secondInvoice.date ||
      !firstInvoice.dueDate ||
      !secondInvoice.dueDate
    ) {
      setSnackText("Dates cannot be empty!");
      setSnackSeverity("error");
      setOpenSnack(true);
      return false;
    } else if (
      dayjs(firstInvoice.dueDate).isBefore(firstInvoice.date) ||
      dayjs(secondInvoice.dueDate).isBefore(secondInvoice.date)
    ) {
      setSnackText(
        "Please fix the Due Date. It cannot be before than the Invoice Date"
      );
      setSnackSeverity("error");
      setOpenSnack(true);
      return false;
    }
    return true;
  };

  const handleSaveInvoices = () => {
    if (validateInvoices()) {
      handleSave(firstInvoice, secondInvoice, chosenType, chosenValue);
    }
  };

  const handleCompleteWizard = () => {
    let _dueDate = dayjs(finalInvoiceDate)
      .add(1, "month")
      .set("date", 20)
      .format("YYYY-MM-DD");

    if (invoice?.companyPaymentTerms?.split("_")?.length > 0) {
      _dueDate = dayjs(finalInvoiceDate)
        .add(invoice?.companyPaymentTerms?.split("_")[0], "days")
        .format("YYYY-MM-DD")
    } else {
      _dueDate = dayjs(finalInvoiceDate)
        .add(1, "month")
        .set("date", parseInt(invoice.companyPaymentTerms))
        .format("YYYY-MM-DD")
    }
    setFirstInvoice({ ...firstInvoice, exchangedTotal: wizardTotal, total: wizardTotal * currencyRate });
    setSecondInvoice({
      ...secondInvoice,
      total: (maxTotal - wizardTotal) * currencyRate,
      exchangedTotal: maxTotal - wizardTotal,
      date: finalInvoiceDate,
      dueDate: _dueDate,
    });
    setIsWizardComplete(true);
  };

  return (
    <Fragment>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        {isWizardComplete && (
          <DialogTitle id="quote-preview-dialog-title">
            {`Split Invoices for ${invoice?.jobName}`}
            <IconButton
              disabled={loading}
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
        )}
        <DialogContent dividers>
          {!isWizardComplete ? (
            <SplitInvoiceWizard
              maxTotal={maxTotal}
              handleCompleteWizard={handleCompleteWizard}
              setWizardTotal={setWizardTotal}
              wizardTotal={wizardTotal}
              setFinalInvoiceDate={setFinalInvoiceDate}
              setChosenType={setChosenType}
              setChosenValue={setChosenValue}
              currency={invoice.currency}
            />
          ) : (
            <div className="flex">
              <div className="split-invoices-grid">
                <SplitInvoicePreview
                  title="Invoice 1"
                  invoice={firstInvoice}
                  handleOnChange={handleFirstInvoiceChange}
                  currency={invoice.currency}
                />
              </div>
              <div className="split-invoices-grid">
                <SplitInvoicePreview
                  title="Invoice 2"
                  invoice={secondInvoice}
                  handleOnChange={handleSecondInvoiceChange}
                  currency={invoice.currency}
                />
              </div>
            </div>
          )}
        </DialogContent>
        {isWizardComplete && (
          <DialogActions>
            {loading ? (
              <div style={{ padding: 15 }}>
                <CircularProgress />
              </div>
            ) : (
              <div style={{ display: "flex" }}>
                <div style={{ paddingRight: 15 }}>
                  <Button
                    variant="contained"
                    className="default-button"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </div>
                <div style={{ paddingRight: 15 }}>
                  <Button
                    variant="contained"
                    className="default-button"
                    onClick={handleSaveInvoices}
                  >
                    Save Invoices
                  </Button>
                </div>
              </div>
            )}
          </DialogActions>
        )}
      </Dialog>
    </Fragment>
  );
};

export default SplitInvoiceDialog;
