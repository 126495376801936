import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import TotalCard from "../../TotalCard";
import RevenueBarChart from "./RevenueBarChart";
import RevenueClientsPieChart from "./RevenueClientsPieChart";
import "./revenueDashboard.scss";
import RevenueDataTable from "./RevenueDataTable";
import axios from "axios";
import { Skeleton } from "@mui/material";

const RevenueDashboard = ({
  handleChangeFilter,
  filters,
  setFilters,
}) => {

  const [loading, setLoading] = useState(true);
  const [selectedRevenueChart, setSelectedRevenueChart] = useState(false);
  const [revenueData, setRevenueData] = useState({
    chart: [],
    data: [],
  });

  const calculateTotals = (field, isSent = true) => {
    if (revenueData.chart && revenueData.chart.length > 0) {
      return [...revenueData.chart].filter(r => r.monthYear.includes(" Total"))
        .reduce((a, b) => a + (b[field] || 0), 0);
    } else {
      return 0;
    }
  };

  useEffect(() => {
    setLoading(true);
    var status = filters.status && filters.status.length > 0 ? filters.status.join(",") : ""
    axios
      .post(
        `Dashboard/GetRevenueChartData`, 
        {
          dateFrom: filters.dateFrom,
          dateTo: filters.dateTo,
          organisationId: filters.organisationId || 0,
          companyId: filters.companyId || 0,
          userEmail: filters.userEmail || "",
          companySize: filters.companySize || "",
          xeroAccount: filters.xeroAccount || 0,
          pullApprovedOnly: filters.pullApprovedOnly, 
          productType: filters.productType || null,
          teamColour: filters.teamColour || null,
          status: status
        }
      )
      .then(({ data }) => {
        setRevenueData(data);
      })
      .finally(() => setLoading(false));
  }, [filters]);

  return loading ? (
    <>
      <Skeleton height={500} />
    </>
  ) : (
    <div className="revenue-dashboard-root">
      {/* <div className="revenue-dashboard-totals">
        <TotalCard
          label={"Draft"}
          value={calculateTotals("draft").toLocaleString("en-NZ", {
            style: "currency",
            currency: "NZD",
            minimumFractionDigits: 2,
          })}
        />
        <TotalCard
          label={"Sent"}
          value={calculateTotals("sent", false).toLocaleString("en-NZ", {
            style: "currency",
            currency: "NZD",
            minimumFractionDigits: 2,
          })}
        />
        <TotalCard
          label={"Approved"}
          value={calculateTotals("approved", false).toLocaleString("en-NZ", {
            style: "currency",
            currency: "NZD",
            minimumFractionDigits: 2,
          })}
        />
      </div> */}

      <div>
        <RevenueBarChart
          filters={filters}
          setFilters={setFilters}
          revenueData={revenueData}
          selectedRevenueChart={selectedRevenueChart}
          handleChangeFilter={handleChangeFilter}
          setSelectedRevenueChart={setSelectedRevenueChart}
        />

        <RevenueDataTable
          revenueData={revenueData}
          selectedRevenueChart={selectedRevenueChart}
        />
      </div>
      {/* <div>
      <RevenueClientsPieChart
          revenueData={revenueData}
          handleChangeFilter={handleChangeFilter}
          filters={filters}
        />
      </div> */}
    </div>
  );
};

export default RevenueDashboard;
