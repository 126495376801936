import React from "react";
import { Redirect, Route, Link } from "react-router-dom";
import { connect } from "react-redux";
import { useAuth } from "../../context/auth/AuthContext";
import PageLoadingSkeleton from "../Shared/PageLoadingSkeleton";

const AuthenticatedRoute = (props) => {
  const { isAuthenticated, isLoadingUser } = useAuth();
  const { component: Component, path } = props;
  return (
    <Route
      exact
      path={path}
      render={(props) =>
        isAuthenticated && !isLoadingUser ? (
          <Component {...props} />
        ) : isLoadingUser ? (
          <PageLoadingSkeleton />
        ) : (
          window.open(
            `${process.env.REACT_APP_IDENTITY_HUB_URL}/login?redirect=${window.location.origin}${window.location.pathname}`,
            "_self"
          )
        )
      }
    />
  );
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(AuthenticatedRoute);
