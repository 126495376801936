import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";
import dayjs from "dayjs";

const EditSingleFieldDialog = ({
  open,
  handleClose,
  handleSaveEdit,
  fieldProperties,
  loading = false
}) => {
  const [inputValue, setInputValue] = useState();
  const [minValue, setMinValue] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  useEffect(() => {
    if (fieldProperties && fieldProperties.value) {
      if (fieldProperties.inputType === "date") {
        setMinValue(dayjs().format("YYYY-MM-DD"));
        setInputValue(dayjs(fieldProperties?.value).format("YYYY-MM-DD"));
      } else {
        setInputValue(fieldProperties?.value);
      }
    }
  }, [fieldProperties]);

  const checkIfValidValue = () => {
    if (fieldProperties.inputType === "date" && !inputValue) {
      setErrorMessage("Date cannot be empty.")
      return false;
    } else if (fieldProperties.inputType === "date" && dayjs(inputValue).isBefore(minValue)) {
      setErrorMessage("Date cannot be in the past.")
      return false;
    }
    setErrorMessage(null)
    return true;
  };

  const handleSave = () => {
    if (checkIfValidValue()) {
      handleSaveEdit(inputValue);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason !== "backdropClick" && !loading) {
          handleClose();
        }
      }}
      size="xs"
      PaperProps={{
        style: {
          backgroundColor: "#ededed",
        },
      }}>
      <DialogTitle id="quote-preview-dialog-title">
        Edit {fieldProperties?.label}
        <IconButton
          aria-label="close"
          onClick={() => {
            if(!loading){
              handleClose();
            }
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <TextField
          disabled={loading}
          label={fieldProperties?.label}
          type={fieldProperties?.inputType}
          id="change-input"
          min={minValue}
          value={inputValue}
          variant="filled"
          size="small"
          fullWidth={true}
          onChange={(e) => setInputValue(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {errorMessage && (
          <Typography variant="caption" style={{ color: "red" }}>
            {errorMessage}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        {loading ? (
          <div style={{ padding: 10 }}><CircularProgress /></div>
        ) : (
          <div style={{ textAlign: "right" }}>
            <Button
              className="job-button"
              variant="contained"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default EditSingleFieldDialog;
