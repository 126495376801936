import React from "react";
import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";

const CustomTableHead = ({ headers, orderBy, order, onRequestSort }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="thead-magnesium">
      <TableRow>
        {headers.map((header, index) => (
          <TableCell
            key={index}
            align={`${header?.alignment ? header.alignment : "center"}`}
            sortDirection={orderBy === header.field ? order : false}
            style={{paddingRight: 0}}
            className={header?.cellClassName ? header.cellClassName : ""}
          >
            {header.name}
            <TableSortLabel
              active={orderBy === header.field}
              direction={orderBy === header.field ? order : "asc"}
              onClick={createSortHandler(header)}
              className="sorting-header"
            >
              {orderBy === header.field ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
              
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHead;
