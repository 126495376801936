import axios from "axios";
import { createAction } from "@reduxjs/toolkit";

const logError = createAction("LOG_ERROR");
const getCompaniesSuccess = createAction("GET_COMPANIES_SUCCESS");

export const getCompanies = (organisationId) => async (dispatch) => {
  try {
    const res = await axios.get("Company/GetCompanies/"+organisationId);
    if (res.status !== "500") {
      dispatch(getCompaniesSuccess(res.data));
    } else {
      dispatch(logError(res.data));
    }
  } catch (err) {
    dispatch(logError(err));
  }
};

export const checkNzbnToken = () => async (dispatch) => {
  try {
    const res = await axios.get("Company/GrantNzbnToken");
    if (res.status === "500") {
      dispatch(logError(res.data));
    }
  } catch (err) {
    dispatch(logError(err));
  }
};

const addCompanyToStore = createAction("ADD_COMPANY_STORE");

export const addCompany = (company) => (dispatch) => {
  dispatch(addCompanyToStore(company));
}

export const updateCompanyStore = (company) => (dispatch) => {
  dispatch(updateCompanyStoreSuccess(company));
}
const updateCompanyStoreSuccess = createAction("UPDATE_COMPANY_STORE");

export const updateCompanyFilter = (field, value) => async (dispatch) => {
  dispatch(updateCompanyFilterStoreSuccess({ field, value }));
};
const updateCompanyFilterStoreSuccess = createAction("UPDATE_COMPANY_FILTER_STORE");

export const searchCompanies = (companyFilter) => async (dispatch) => {
  try {
    const res = await axios.post("Company/SearchCompanies", companyFilter);
    if (res.status !== "500") {
      dispatch(getCompaniesSuccess(res.data));
    } else {
      dispatch(logError(res.data));
    }
  } catch (err) {
    dispatch(logError(err));
  }
};
