import dayjs from "dayjs";

export const checkJobPercentage = (company, contact, job) => {
  let percentage = 0;
  if (company.name) {
    percentage = 0.1;
  }
  if (contact.firstName) {
    percentage = 0.2;
  }
  if ( job.jobName !== "" && ((Number(job.shootDays) > 0 && job.expectedShootDate && job.expectedTransmissionDate && dayjs(job.expectedTransmissionDate).isAfter(dayjs("01-01-2000"))) ||
      ((job.shootDays == null || job.shootDays == "0" || job.shootDays == "") && job.completionDate && dayjs(job.completionDate).isAfter(dayjs("01-01-2000"))))
  ) {
    percentage = 0.4;
  }

  if (job.jobDescription !== "" && job.jobDescription !== "<p></p>\n") {
    percentage = 0.5;
  }
  return percentage;
};

export const checkMarginPercentage = (percentage) => {
  let checkerProps = {
    message: "",//"Amazing!",
    color: ""//"#008b4b",
  };
  // if (percentage <= 0.2) {
  //   checkerProps = {
  //     message: "Bad Margin",
  //     color: "#cb1212",
  //   };
  // } else if (percentage < 0.65) {
  //   checkerProps = {
  //     message: "Not Good",
  //     color: "#ef8a00",
  //   };
  // } else if (percentage < 0.72) {
  //   checkerProps = {
  //     message: "Ok",
  //     color: "#407cc7",
  //   };
  // }
  return checkerProps;
};
