import {
  Button,
  Tooltip,
  TextField,
  Typography,
  Autocomplete,
  createFilterOptions,
  Divider,
  IconButton,
  Container,
} from "@mui/material";
import React, { useState, useEffect, Fragment, useMemo } from "react";
import { connect } from "react-redux";
import Steps from "../Shared/Steps";
import "./jobs.scss";
import AddNewContactDialog from "../Contacts/AddNewContactDialog";
import {
  getAllProducts,
  getAllProductCategories,
} from "../../reduxActions/product";
import { getCompanies, checkNzbnToken } from "../../reduxActions/company";
import { getExemptions, removeJobLineStore } from "../../reduxActions/job";
import {
  updateJobStore,
  resetJobStore,
  getJobById,
  saveJobUpdates,
  createDraft,
  updateAllJobLinesStore,
} from "../../reduxActions/job";
import axios from "axios";
import JobLines from "./JobLines";
import { checkJobPercentage } from "../../Utils/Checkers";
import {
  checkIfContainsMonthYear,
  updateJobNameToIncludeMonthYear,
  get12MonthsAheadAnd1MonthBackArr,
  getMonthsArray,
  checkIfValidMiscJobLine,
  checkIfJobIsReadOnly,
  getReadOnlyText,
  getInternalCompanyId,
} from "../../Utils/JobHelpers";
import { isEmpty, truncateDecimals } from "../../Utils/GeneralUtils";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import {
  EditorState,
  convertToRaw,
  compositeDecorator,
  convertFromHTML,
  ContentState,
} from "draft-js";
import ConfirmationDialog from "../Shared/ConfirmationDialog";
import _ from "lodash";
import { Prompt, useParams } from "react-router-dom";
import dayjs from "dayjs";
import Snack from "../Shared/Snack";
import SpellCheckTextField from "../Shared/SpellCheckTextField";
import CheckJobDialog from "./CheckJobDialog";
import CompanyDialog from "../Companies/CompanyDialog";
import { v1 as uuidv1 } from "uuid";
import RetainerBillingDialog from "./RetainerBillingDialog";
import InfoDialog from "../Shared/InfoDialog";
import { useHistory } from "react-router-dom";
import ConfirmationDialogWithInput from "../Shared/ConfirmationDialogWithInput";
import EditRetainerJobConfirmation from "./socialites/EditRetainerJobConfirmation";
import ForeignCurrencyNote from "./ForeignCurrencyNote";
import JobActions from "./JobActions";
import JobInternalActions from "./JobInternalActions";
import InternalQuoteNote from "./InternalQuoteNote";
import { useAuth } from "../../context/auth/AuthContext";
import { getCurrencies } from "../../Utils/ExchangeRateUtils";

const filter = createFilterOptions();

const emptyCompany = {
  name: "",
  overrideLimit: 0,
  address: "",
  registeredName: "",
  isNzbusiness: true,
  companyLocation: "NEW_ZEALAND",
  currency: "NZD",
  gstregistered: true,
  paymentTerms: "20",
  mediaAdminFee: 15,
  person: [],
  onboardingCentreFkNavigation: {
    googleDrive: true,
    airtable: true,
    onePasswordVault: true,
    asana: true,
    sendWelcomeGift: true,
  },
};

const emptyContact = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  phoneNumber: "",
  companyFk: 0,
  isOnboardingRequired: true,
  personCategory: "Client",
};

const Job = (props) => {
  const {
    location,
    job,
    getAllProducts,
    getAllProductCategories,
    products,
    getCompanies,
    companies,
    checkNzbnToken,
    updateJobStore,
    resetJobStore,
    getJobById,
    saveJobUpdates,
    createDraft,
    selectedOrganisation,
    jobCategories,
    getExemptions,
    exemptions,
    removeJobLineStore,
    jobLines,
    updateAllJobLinesStore,
  } = props;
  const history = useHistory();
  const { id, isAdditional } = useParams();
  const { user } = useAuth();

  // Memoize the currencies to avoid recalculating on each render
  const currencies = useMemo(() => getCurrencies(), []);

  const [isRetainerParam, setIsRetainerParam] = useState(
    location?.state?.isRetainer || false
  );
  const [selectedCompany, setselectedCompany] = useState(emptyCompany);
  const [selectedContact, setselectedContact] = useState(emptyContact);

  const [openCreateContactDialog, setopenCreateContactDialog] = useState(false);
  const [openCreateCompanytDialog, setopenCreateCompanytDialog] =
    useState(false);
  const [percent, setpercent] = useState(0);
  const [refreshAutocomplete, setrefreshAutocomplete] = useState(false);
  const [editorState, seteditorState] = useState(EditorState.createEmpty());
  const [openConfirmShootDaysDialog, setOpenConfirmShootDaysDialog] =
    useState(false);
  const [tempShootDays, setTempShootDays] = useState(0);
  const [hasChanges, setHasChanges] = useState(false);
  const [saving, setsaving] = useState(false);
  const [openSnack, setopenSnack] = useState(false);
  const [snackSeverity, setsnackSeverity] = useState("info");
  const [snackText, setsnackText] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [isReadyForCheck, setIsReadyForCheck] = useState(false);

  const [showCheckDialog, setShowCheckDialog] = useState(false);
  const [emailHtml, setEmailHtml] = useState();
  const [refreshJobName, setRefreshJobName] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [hasJobNameError, setHasJobNameError] = useState(false);
  const [jobNameErrorMessage, setJobNameErrorMessage] = useState(null);
  const [openRetainerBillingDialog, setOpenRetainerBillingDialog] =
    useState(false);
  const [retainerDialogType, setRetainerDialogType] = useState(null);
  const [openConfirmClearJobDialog, setOpenConfirmClearJobDialog] =
    useState(false);
  const [isInvalidDate, setIsInvalidDate] = useState(false);
  const [invalidDateMessage, setInvalidDateMessage] = useState(null);
  const [showContactError, setShowContactError] = useState(false);
  const [fromManage, setFromManage] = useState(false);
  const [openConfirmEditJobDialog, setOpenConfirmEditJobDialog] =
    useState(false);
  const [
    openConfirmEditRetainerJobDialog,
    setOpenConfirmEditRetainerJobDialog,
  ] = useState(false);
  const [loadingEditJob, setLoadingEditJob] = useState(false);
  const [openConfirmCancelJobDialog, setOpenConfirmCancelJobDialog] =
    useState(false);
  const [loadingCancelJob, setLoadingCancelJob] = useState(false);
  const [loadExemptions, setLoadExemptions] = useState(true);
  const [showSavePoButton, setShowSavePoButton] = useState(false);
  const [approvedQuotePoNumber, setApprovedQuotePoNumber] = useState(null);
  const [showMiscPopup, setShowMiscPopup] = useState(false);

  const [selectedYear, setSelectedYear] = useState(
    `${new Date().getFullYear()}`
  );

  const [infoDialogProps, setInfoDialogProps] = useState({
    title: "",
    context: "",
  });
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [jobOrganisation, setJobOrganisation] = useState(
    selectedOrganisation?.id
  );
  const [jobOrgIsDifferent, setJobOrgIsDifferent] = useState(false);

  useEffect(() => {
    if (jobOrgIsDifferent) {
      setJobOrgIsDifferent(false);
    }
  }, [selectedOrganisation]);

  useEffect(() => {
    if (
      job &&
      job.organisationId !== selectedOrganisation?.id &&
      !jobOrgIsDifferent
    ) {
      setJobOrgIsDifferent(true);
      setIsReadOnly(
        checkIfJobIsReadOnly(
          job.status,
          job.organisationId,
          selectedOrganisation?.id
        )
      );
      setJobOrganisation(job.organisationId);
    } else if (
      job &&
      job.organisationId === selectedOrganisation?.id &&
      !jobOrgIsDifferent
    ) {
      setIsReadOnly(
        checkIfJobIsReadOnly(
          job.status,
          job.organisationId,
          selectedOrganisation?.id
        )
      );
      setJobOrganisation(job.organisationId);
    }
  }, [job]);

  useEffect(() => {
    if (job) {
      var showButton = false;
      if (
        job?.invoice?.length > 0 &&
        job.status !== "draft" &&
        job.status !== "checked"
      ) {
        showButton = true;
        for (var i = 0; i < job.invoice.length; i++) {
          if (
            job.invoice[i].status !== "DRAFT" &&
            job.invoice[i].status !== "PLACEHOLDER"
          ) {
            showButton = false;
            break;
          }
        }
      } else {
        showButton = false;
      }
      setShowSavePoButton(showButton);
      if (
        approvedQuotePoNumber === null ||
        approvedQuotePoNumber === undefined
      ) {
        setApprovedQuotePoNumber(job["poNumber"]);
      }
    }
  }, [job]);

  useEffect(() => {
    if (job) {
      setIsReadOnly(
        checkIfJobIsReadOnly(
          job?.status,
          jobOrganisation,
          selectedOrganisation?.id
        )
      );
    }
  }, [job, jobOrganisation, selectedOrganisation]);

  useEffect(() => {
    if (history.location.state) {
      if (history.location.state.from === "fromManage") {
        setFromManage(true);
      } else {
        setFromManage(false);
      }
    } else {
      setFromManage(false);
    }
  }, [history]);

  useEffect(() => {
    if (
      (products.length < 1 && user?.email && jobOrganisation) ||
      jobOrganisation
    ) {
      getAllProducts(0);
      getAllProductCategories();
      getCompanies(0);
      checkNzbnToken();
      updateJobStore("user", user?.email);
    }
    setHasChanges(false);
    return () => {
      clearFields();
    };
  }, [user?.email, jobOrganisation]);

  useEffect(() => {
    if (loadExemptions) {
      setLoadExemptions(false);
      getExemptions();
    }
  }, [loadExemptions, getExemptions]);

  useEffect(() => {
    updateSpecificFieldsOrganisation();
  }, [id, jobOrganisation]);

  useEffect(() => {
    //prevent when companies is not fully loaded
    if (id && companies?.length > 0 && !selectedCompany?.id) {
      populateFieldsForEditing();
    }
  }, [id, companies, selectedCompany]);

  // useEffect(() => {
  //   if (id) {
  //     getJobById(id);
  //   }
  // }, [id, jobOrganisation, user]);

  useEffect(() => {
    if (id) {
      getJobById(id);
    }
    if (!id && isRetainerParam) {
      updateJobStore("isRetainer", true);
    }
  }, [id, jobOrganisation, isRetainerParam, user]);

  useEffect(() => {
    if (job.id || isAdditional) {
      populateFieldsForEditing();
      if (!job.id && isAdditional) {
        updateJobStore("jobLines", []);
      }
    }
  }, [job.id]);

  useEffect(() => {
    if (isReadyForCheck && job.id) {
      checkJob();
      setIsReadyForCheck(false);
    }
  }, [isReadyForCheck]);

  useEffect(() => {
    // pull through default job description
    if (percent === 0.4 && !job.id && !job.jobDescription) {
      axios.get(`SystemDefaults/GetSystemDefaults`).then(({ data }) => {
        handleJobChange("jobDescription", data.jobDescription);
        const blocksFromHTML = convertFromHTML(data.jobDescription);
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        seteditorState(
          EditorState.createWithContent(state, compositeDecorator)
        );
      });
    }
  }, [percent]);

  useEffect(() => {
    console.log("Company Currency: " + selectedCompany.currency);
    console.log("Job Currency: " + job.currency);
  }, [selectedCompany]);

  const getPoTextField = () => {
    return (
      <TextField
        key={refreshAutocomplete}
        label={"PO Number (Optional)"}
        type={"text"}
        id={"poNumber"}
        name={"poNumber"}
        value={approvedQuotePoNumber}
        variant="filled"
        disabled={!!job.crossOrganisationProject}
        size="small"
        fullWidth={true}
        onChange={(e) => {
          setApprovedQuotePoNumber(e.target.value);
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  };
  const handleCheckAndSend = () => {
    if (!isReadOnly) {
      var copyOfExemptions = [...exemptions];
      var exemptionsForCompany = [];
      for (var i = 0; i < copyOfExemptions.length; i++) {
        if (copyOfExemptions[i].clientId === job.companyId) {
          exemptionsForCompany.push(copyOfExemptions[i]);
        }
      }

      var copyOfProducts = [...products];
      var copyOfJobLines = [...job.jobLines];
      for (var i = 0; i < copyOfJobLines.length; i++) {
        var jobLine = copyOfJobLines[i];
        var productDefault = copyOfProducts.find(
          (p) => p.id == jobLine.productId
        );
        var productApproved = exemptionsForCompany[i]
          ? exemptionsForCompany.find(
              (e) =>
                e.productId == jobLine.productId &&
                exemptionsForCompany[i].status == "APPROVED"
            )
          : true;
        var productPending = exemptionsForCompany[i]
          ? exemptionsForCompany.find(
              (e) =>
                e.productId == jobLine.productId &&
                exemptionsForCompany[i].status == "PENDING"
            )
          : false;
        if (productPending && productPending.productId === jobLine.productId) {
          if (!productApproved) {
            //check if costPrice is < default
            if (jobLine.costPrice < productDefault.minSellPrice) {
              setInfoDialogProps({
                title: "Exemption still in review",
                context: `The min markup percentage request is still being reviewed. Please raise the line markup to at least ${productDefault.minSellPrice}% 
                for ${productDefault.type} - ${productDefault.name} to send the quote now or wait until the request has been approved`,
              });
              setShowInfoDialog(true);
              return;
            }
          }
        }

        if (checkIfValidMiscJobLine(jobLine)) {
          setShowMiscPopup(true);
          return;
        }
      }
    }

    if (job.isRetainer && !job.retainerType && job.status !== "approved") {
      setOpenRetainerBillingDialog(true);
      setRetainerDialogType("create");
      return;
    }
    checkAndSend();
  };

  const handleSaveAcceptedQuotePo = () => {
    setsaving(true);
    axios
      .post(
        `Job/UpdateAcceptedQuotePoNumber/${job.id}/${approvedQuotePoNumber}`
      )
      .then(() => {
        setsnackText("PO Updated");
        setsnackSeverity("info");
        setopenSnack(true);
      })
      .catch((e) => {
        console.log(e);
        setsnackText("Failed to update PO");
        setsnackSeverity("warning");
        setopenSnack(true);
      })
      .finally(() => {
        setsaving(false);
      });
  };

  const updateSpecificFieldsOrganisation = async () => {
    if (!id && jobOrganisation && jobOrganisation === 1) {
    } else if (!id && jobOrganisation > 1) {
      await updateJobStore("paymentType", "END_OF_MONTH_BILLING");
    }
  };

  const setJobCategories = (categories) => {
    updateJobStore("jobCategories", categories);
  };

  const populateFieldsForEditing = () => {
    loadJobDescriptionEditor();
    //loadJobCategories();
    const company = companies.find((c) => c.id === job.companyId);
    setselectedCompany(company ? company : emptyCompany);
    setselectedContact(
      company && company.person.length > 0
        ? company.person.find((p) => p.id === job.contactId)
        : emptyContact
    );
    setIsReadOnly(
      checkIfJobIsReadOnly(
        job.status,
        job.organisationId,
        selectedOrganisation?.id
      )
    );
    setTempShootDays(job.shootDays);
  };

  const loadJobCategories = async () => {
    if (job.id || job.parentJobId) {
      const res = await axios.get(
        `Job/GetUniqueFilmingDaysByJobId/${job.id ? job.id : job.parentJobId}`
      );
      const filmingCategories = res.data;
      if (filmingCategories && filmingCategories.length > 0) {
        let jobCategoriesClone = [];
        filmingCategories.forEach((c, index) => {
          const filmingCategory = {
            id: index + 2,
            name: c,
          };
          jobCategoriesClone.push(filmingCategory);
        });
        setJobCategories(jobCategoriesClone);
      }
    }
  };

  const loadJobDescriptionEditor = () => {
    if (job && job.jobDescription) {
      const blocksFromHTML = convertFromHTML(job.jobDescription);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      seteditorState(EditorState.createWithContent(state, compositeDecorator));
    }
  };

  const refreshJobDescriptionEditor = (jobDescription) => {
    if (jobDescription) {
      const blocksFromHTML = convertFromHTML(jobDescription);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      seteditorState(EditorState.createWithContent(state, compositeDecorator));
    }
  };

  const clearFields = async () => {
    setTempShootDays(0);
    await resetJobStore(jobOrganisation);
    setselectedCompany(emptyCompany);
    setselectedContact(emptyContact);
    seteditorState(EditorState.createEmpty());
    setHasChanges(false);
    setOpenConfirmClearJobDialog(false);
  };

  const handleOnChangeCompany = async (event, newValue) => {
    if (newValue && newValue.inputValue) {
      setselectedCompany({
        ...emptyCompany,
        name: newValue.inputValue,
      });
      setopenCreateCompanytDialog(true);
      // setpercent(0);
    } else if (newValue === null) {
      setselectedCompany(emptyCompany);
      setselectedContact(emptyContact);
      updateJobStore("companyId", 0);
      updateJobStore("company", null);
      updateJobStore("contactId", 0);
      // setpercent(0);
    } else {
      setselectedCompany(newValue);
      var contactClone = { ...selectedContact };
      const selectedCurrency = newValue.currency || "NZD";
      contactClone.companyFk = newValue.id;
      setselectedContact(contactClone);
      updateJobStore("companyId", newValue.id);
      updateJobStore("company", newValue);
      updateJobStore("currency", selectedCurrency);
      // updateJobStore(
      //   "exchangeRate",
      //   selectedCurrency === "NZD"
      //     ? 1
      //     : await getLatestCurrencyRate(selectedCurrency)
      // );
      // setpercent(0.1);
    }
    setHasChanges(true);
  };

  const updateMultiPropertiesCompany = (updatedCompany) => {
    setselectedCompany(updatedCompany);
  };

  const handleOnChangeContact = (event, newValue) => {
    if (newValue && newValue.inputValue) {
      const nameArray = newValue.inputValue.split(" ");
      const firstName = nameArray[0];
      const lastName = nameArray.length > 1 ? nameArray[1] : "";
      setselectedContact({
        ...emptyContact,
        firstName: firstName,
        lastName: lastName,
        companyFk: selectedContact.companyFk,
      });
      setopenCreateContactDialog(true);
    } else if (newValue === null) {
      setselectedContact(emptyContact);
      updateJobStore("contactId", 0);
    } else {
      setselectedContact(newValue);
      updateJobStore("contactId", newValue.id);
    }
    setShowContactError(false);
    setHasChanges(true);
  };

  const handleCloseNewCompanyDialog = () => {
    setopenCreateCompanytDialog(false);
    if (!selectedCompany.id) {
      setselectedCompany(emptyCompany);
      updateJobStore("companyId", 0);
      updateJobStore("company", null);
    }
  };

  const handleCloseNewContactDialog = () => {
    // setselectedContact(emptyContact);
    setopenCreateContactDialog(false);
    setrefreshAutocomplete(!refreshAutocomplete);
    updateJobStore("contactId", 0);
  };

  const handleCloseNewCompanyDialogAfterCreated = () => {
    setopenCreateCompanytDialog(false);
  };

  const handleCloseNewCompanyDialogAfterUpdated = (company) => {
    setopenCreateCompanytDialog(false);
    updateCurrency(company);
  };

  const handleCloseNewContactDialogAfterCreated = () => {
    if (jobOrganisation) {
      getCompanies(0);
    }
    setopenCreateContactDialog(false);
  };

  const handleNewCompanyCreated = async (company) => {
    setselectedCompany(company);
    setselectedContact({
      ...selectedContact,
      companyFk: company.id,
    });
    updateJobStore("companyId", company.id);
    updateJobStore("company", company);
    updateCurrency(company);
  };

  const updateCurrency = async (company) => {
    const selectedCurrency = company.currency || "NZD";
    updateJobStore("currency", selectedCurrency);
    // updateJobStore(
    //   "exchangeRate",
    //   selectedCurrency === "NZD"
    //     ? 1
    //     : await getLatestCurrencyRate(selectedCurrency)
    // );
  };

  const handleNewContactCreated = (contact) => {
    setselectedContact(contact);
    updateJobStore("contactId", contact.id);
    updateJobStore("contact", contact);
    setHasChanges(true);
    setsnackText("Contact Saved");
    setsnackSeverity("info");
    setopenSnack(true);
  };

  useEffect(() => {
    setRefreshJobName(false);
    if (refreshJobName && jobOrganisation === 1) {
      let _jobName = "";
      if (job.jobName) {
        _jobName = updateJobNameToIncludeMonthYear(
          job.jobName,
          job.completionDate,
          job.expectedTransmissionDate,
          job.shootDays
        );
      }
      if (_jobName !== job.jobName) {
        updateJobStore("jobName", _jobName);
      }
      if (checkIfContainsMonthYear(_jobName)) {
        setHasJobNameError(true);
        setJobNameErrorMessage(
          "Remove Month and Year. This wil be added automatically."
        );
      } else {
        setHasJobNameError(false);
        setJobNameErrorMessage(null);
      }
    }
  }, [refreshJobName]);

  useEffect(() => {
    setIsInvalidDate(false);
    setInvalidDateMessage(null);
    if (job.expectedShootDate && job.expectedTransmissionDate) {
      if (
        dayjs(job.expectedTransmissionDate).isBefore(
          dayjs(job.expectedShootDate)
        )
      ) {
        setIsInvalidDate(true);
        setInvalidDateMessage(
          "Ops, transmission date should be after shoot date."
        );
      }
    }
  }, [job.expectedShootDate, job.expectedTransmissionDate]);

  const handleJobChange = (field, value) => {
    setHasChanges(true);
    switch (field) {
      case "shootDays":
        setTempShootDays(value);
        if (value < job[field] && !openConfirmShootDaysDialog) {
          setOpenConfirmShootDaysDialog(true);
        } else if (value === 0) {
          updateJobStore(field, value);
          updateJobStore("expectedShootDate", null);
          updateJobStore("expectedTransmissionDate", null);
        } else {
          updateJobStore(field, value);
          updateJobStore("completionDate", null);
        }
        break;
      case "completionDate":
        if (jobOrganisation === 1) {
          updateJobStore("completionDate", value);
        } else {
          updateJobStore("completionDate", dayjs(value).format("YYYY-MM-DD"));
          if (job["isRetainer"] && job["retainerDuration"]) {
            updateJobStore(
              "retainerEndDate",
              dayjs(value)
                .endOf("month")
                .add(job["retainerDuration"], "months")
                .format("YYYY-MM-DD")
            );
          }
        }
        break;
      case "retainerDuration":
        if (job["completionDate"]) {
          updateJobStore("retainerDuration", value);
          updateJobStore(
            "retainerEndDate",
            dayjs(job["completionDate"])
              .endOf("month")
              .add(value, "months")
              .format("YYYY-MM-DD")
          );
        }
        break;
      default:
        updateJobStore(field, value);
        break;
    }
  };

  useEffect(() => {
    if (selectedCompany && selectedContact && job) {
      const checker = checkJobPercentage(selectedCompany, selectedContact, job);
      if (checker !== null) {
        if (percent !== checker) {
          setpercent(checker);
          setrefreshAutocomplete(!refreshAutocomplete);
        }
      }
    }
  }, [job, selectedCompany, selectedContact]);

  const onEditorStateChange = (editorState) => {
    let jobDescription = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    handleJobChange("jobDescription", jobDescription);
    seteditorState(editorState);
  };

  const saveDraftJob = async () => {
    var copyOfJobLines = [...job.jobLines];
    for (var i = 0; i < copyOfJobLines.length; i++) {
      var jobLine = copyOfJobLines[i];
      if (checkIfValidMiscJobLine(jobLine)) {
        setShowMiscPopup(true);
        return;
      }
    }
    if (
      job.contactId === undefined ||
      job.contactId === null ||
      job.contactId === 0
    ) {
      setShowContactError(true);
      scrollToElement("job-container");
    } else {
      setsaving(true);
      let _job = _.cloneDeep(job);

      const draftJobLines = _job.jobLines.filter((jl) => jl.name !== "");
      _job.jobLines = draftJobLines;
      if (!_job.organisationId) {
        _job.organisationId = jobOrganisation;
      }
      if (job.id) {
        await saveJobUpdates(_job, user?.email);
        setsnackText("Draft Updated");
        setopenSnack(true);
      } else {
        if (user?.email) {
          await createDraft(_job, user?.email);
          setsnackText("Draft Created");
          setopenSnack(true);
        } else {
          console.log("Something went wrong - User field is empty.");
          setsnackText("Something went wrong.");
          setopenSnack(true);
        }
      }
      setHasChanges(false);
      setsaving(false);
    }
  };

  const getTextField = (
    label,
    type,
    name,
    onBlur,
    isDisabled = false,
    hasError = false,
    errorMessage = null,
    min = null,
    max = null
  ) => {
    return (
      <TextField
        key={refreshAutocomplete}
        label={label}
        type={type}
        id={name}
        name={name}
        disabled={isDisabled}
        value={
          type === "date" &&
          job[name] &&
          dayjs(job[name]).isAfter(dayjs("01-01-2000")) // workaround dayjs and native date input issue: https://github.com/iamkun/dayjs/issues/1237
            ? dayjs(job[name]).format("YYYY-MM-DD")
            : job[name]
        }
        variant="filled"
        size="small"
        fullWidth={true}
        onChange={(e) => {
          if (name !== "user") {
            handleJobChange(name, e.target.value);
            if (onBlur) {
              handleJobNameUpdate();
            }
          }
        }}
        InputLabelProps={{
          shrink: true,
        }}
        onBlur={onBlur && handleJobNameUpdate}
        error={hasError}
        helperText={errorMessage}
        InputProps={{ inputProps: { min: min, max: max } }}
      />
    );
  };

  const handleJobNameUpdate = () => {
    setRefreshJobName(true);
  };

  const getSpellCheckTextField = (
    label,
    type,
    name,
    onBlur,
    isDisabled = false
  ) => {
    return (
      <Fragment>
        <SpellCheckTextField
          keyProp={refreshAutocomplete}
          label={label}
          type={type}
          id={name}
          disabled={isDisabled}
          name={name}
          value={job[name]}
          size="small"
          fullWidth={true}
          handleOnChange={(newValue) => handleJobChange(name, newValue)}
          onBlur={onBlur}
          handleJobNameUpdate={handleJobNameUpdate}
          error={hasJobNameError}
          errorMessage={jobNameErrorMessage}
        />
      </Fragment>
    );
  };

  const handleCloseConfirmShootDaysDialog = () => {
    setOpenConfirmShootDaysDialog(false);
  };
  const handleCloseConfirmClearJobDialog = () => {
    setOpenConfirmClearJobDialog(false);
  };

  const handleRemoveFilmingDays = () => {
    handleJobChange("shootDays", tempShootDays);
    handleCloseConfirmShootDaysDialog();
  };

  const forceSaveDraftJob = async () => {
    await saveDraftJob();
    setIsReadyForCheck(true);
  };

  const forceSaveDraftJobNoCheck = async () => {
    await saveDraftJob();
  };

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const checkAndSend = async () => {
    if (
      job.contactId === undefined ||
      job.contactId === null ||
      job.contactId === 0
    ) {
      setShowContactError(true);
      scrollToElement("job-container");
    } else if ((job.id && !hasChanges) || isReadOnly) {
      checkJob();
    } else {
      await saveDraftJob();
      setIsReadyForCheck(true);
    }
  };

  const checkJob = () => {
    const emailCategory = "SignOffEmail";
    const getEmail = axios.get(`Job/GetEmail/${job.id}/${emailCategory}`);
    const updateToCheck = axios.get(`Job/UpdateToCheck/${job.id}`);
    axios
      .all([getEmail, updateToCheck])
      .then(
        axios.spread((email, job) => {
          setShowCheckDialog(true);
          setEmailHtml(email.data);
          updateJobStore("status", job.data.status);
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  const getCompanyContacts = () => {
    let contacts = [];
    if (selectedCompany?.person?.length > 0) {
      contacts = selectedCompany.person.filter(
        (p) => p.personCategory === "Client" && !p.isArchived
      );
    }
    return contacts;
  };

  const downloadPdf = async (retainerType = null) => {
    setLoadingPdf(true);
    if (hasChanges) {
      await saveDraftJob();
    }

    let url = `Job/DownloadPdf/${job.id}`;
    if (retainerType !== null) {
      url = `Job/DownloadRetainerPdf/${job.id}/${retainerType}`;
    }

    const res = await axios.get(url, {
      params: {
        cacheBustTimestamp: Date.now(), // prevents IE cache problems on re-download
      },
      responseType: "blob",
      headers: {
        Accept: "application/pdf",
      },
    });
    const blob = new Blob([res.data], { type: "application/pdf" });

    const blobUrl = window.URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");

    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = job.jobName ? `${job.jobName}.pdf` : "file.pdf";

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    // Remove link from body
    document.body.removeChild(link);

    //var newDownloadWindow = window.open();
    // //setTimeout to prevent browser from blocking the popup
    // setTimeout(() => {
    //   if (newDownloadWindow) {
    //     newDownloadWindow.location = window.URL.createObjectURL(blob);
    //   }
    // }, 500);

    setLoadingPdf(false);
  };

  const handleCloseCheckJobDialog = () => {
    setShowCheckDialog(false);
  };

  const handleEditAcceptedJob = (reason) => {
    setLoadingEditJob(true);
    axios
      .post(`Job/CopyJob`, {
        jobId: job.id,
        reason: reason,
        user: user.email,
      })
      .then((res) => {
        history.push(`/job/${res.data}`);
      })
      .catch((e) => {
        setsnackText("Something went wrong. Please try again");
        setopenSnack(true);
      })
      .finally(() => {
        setLoadingEditJob(false);
      });
  };

  const handleEditAcceptedRetainerJob = (
    reason,
    retainerStartDate,
    retainerDuration
  ) => {
    setLoadingEditJob(true);
    axios
      .post(`Job/CopyRetainerJob`, {
        jobId: job.id,
        reason: reason,
        user: user.email,
        RetainerStartDate: new Date(retainerStartDate),
        retainerDuration: retainerDuration,
      })
      .then((res) => {
        history.push(`/job/${res.data}`);
      })
      .catch((e) => {
        setsnackText("Something went wrong. Please try again");
        setopenSnack(true);
      })
      .finally(() => {
        setLoadingEditJob(false);
      });
  };

  const handleCancelAcceptedJob = (reason) => {
    setLoadingCancelJob(true);
    axios
      .post(`Job/ArchiveJob`, {
        jobId: job.id,
        reason: reason,
        user: user.email,
      })
      .then((res) => {
        history.push(`/home`);
      })
      .catch((e) => {
        setsnackText("Something went wrong. Please try again");
        setopenSnack(true);
      })
      .finally(() => {
        setLoadingCancelJob(false);
      });
  };

  const handleInvalidMiscItem = () => {
    var copyOfJobCategories = [...jobCategories];
    var copyOfJobLines = [...jobLines];
    var removedJobLines = [];
    var newJobLines = [];
    var productionAdded = false,
      mediaAdded = false,
      influencerAdded = false;

    for (var i = 0; i < copyOfJobLines.length; i++) {
      var jobLine = copyOfJobLines[i];
      var foundJobCategory = copyOfJobCategories.find(
        (jc) => jc.guid === jobLine.jobCategoryGuid
      );
      if (
        jobLine.description &&
        jobLine.description.toLowerCase().includes("production")
      ) {
        copyOfJobCategories.push({
          order: copyOfJobCategories.length + 1,
          name: foundJobCategory?.name,
          guid: uuidv1(),
          type: "Magnesium Production",
          description: foundJobCategory?.description,
        });
        productionAdded = true;
        removedJobLines.push(jobLine);
        continue;
      }
      if (
        jobLine.description &&
        jobLine.description.toLowerCase().includes("media")
      ) {
        copyOfJobCategories.push({
          order: copyOfJobCategories.length + 1,
          name: foundJobCategory?.name,
          guid: uuidv1(),
          type: "Media Implementation",
          description: foundJobCategory?.description,
        });
        mediaAdded = true;
        removedJobLines.push(jobLine);
        continue;
      }
      if (
        jobLine.description &&
        jobLine.description.toLowerCase().includes("influencer")
      ) {
        copyOfJobCategories.push({
          order: copyOfJobCategories.length + 1,
          name: foundJobCategory?.name,
          guid: uuidv1(),
          type: "Influencer Marketing",
          description: foundJobCategory?.description,
        });
        influencerAdded = true;
        removedJobLines.push(jobLine);
        continue;
      }
      newJobLines.push(jobLine);
    }

    if (productionAdded || mediaAdded || influencerAdded) {
      setJobCategories(copyOfJobCategories);
      updateJobStore("jobLines", newJobLines);

      removedJobLines.forEach((j) => {
        removeJobLineStore(j.guid);
      });
    }

    setShowMiscPopup(false);
  };

  const handleSetUpdateCompanyData = (company) => {
    setselectedCompany(company);
    setopenCreateCompanytDialog(true);
  };

  return (
    <Container maxWidth="xl" id="job-container">
      {showCheckDialog && (
        <CheckJobDialog
          job={job}
          open={showCheckDialog}
          handleClose={handleCloseCheckJobDialog}
          emailHtml={emailHtml}
          isReadOnly={isReadOnly}
          setsnackText={setsnackText}
          setsnackSeverity={setsnackSeverity}
          setopenSnack={setopenSnack}
          setIsReadOnly={setIsReadOnly}
        />
      )}

      {openRetainerBillingDialog && (
        <RetainerBillingDialog
          open={openRetainerBillingDialog}
          handleClose={() => {
            setOpenRetainerBillingDialog(false);
            setRetainerDialogType(null);
          }}
          handleSubmit={forceSaveDraftJob}
          setHasChanges={setHasChanges}
          job={job}
          type={retainerDialogType}
        />
      )}

      <Snack
        open={openSnack}
        setOpen={setopenSnack}
        severity={snackSeverity}
        text={snackText}
      />
      <Prompt
        when={hasChanges}
        message="You have unsaved changes, are you sure you want to leave?"
      />

      {!job.crossOrganisationProject && <Steps status={job.status} />}

      {showInfoDialog && (
        <InfoDialog
          open={showInfoDialog}
          handleClose={() => {
            setShowInfoDialog(false);
            setInfoDialogProps({
              title: "",
              context: "",
            });
          }}
          title={infoDialogProps.title}
          context={infoDialogProps.context}
        />
      )}
      {openCreateCompanytDialog && (
        <CompanyDialog
          open={openCreateCompanytDialog}
          handleCloseNewCompanyDialog={handleCloseNewCompanyDialog}
          selectedCompany={selectedCompany}
          setselectedCompany={setselectedCompany}
          handleCloseNewCompanyDialogAfterCreated={
            handleCloseNewCompanyDialogAfterCreated
          }
          handleNewCompanyCreated={handleNewCompanyCreated}
          handleNewContactCreated={handleNewContactCreated}
          updateMultiPropertiesCompany={updateMultiPropertiesCompany}
          handleCloseNewCompanyDialogAfterUpdated={
            handleCloseNewCompanyDialogAfterUpdated
          }
        />
      )}
      {openCreateContactDialog && (
        <AddNewContactDialog
          open={openCreateContactDialog}
          handleCloseNewContactDialog={handleCloseNewContactDialog}
          firstName={selectedContact?.firstName}
          lastName={selectedContact?.lastName}
          companyId={selectedCompany?.id}
          existingContact={selectedContact}
          setselectedContact={setselectedContact}
          handleCloseNewContactDialogAfterCreated={
            handleCloseNewContactDialogAfterCreated
          }
          handleNewContactCreated={handleNewContactCreated}
        />
      )}
      {openConfirmShootDaysDialog && (
        <ConfirmationDialog
          open={openConfirmShootDaysDialog}
          handleClose={handleCloseConfirmShootDaysDialog}
          submitLabel={"Confirm"}
          title={"Please confirm to proceed!"}
          description={
            "This action will remove lines allocated to the specific day. (if applicable)"
          }
          handleSubmit={handleRemoveFilmingDays}
        />
      )}
      {openConfirmClearJobDialog && (
        <ConfirmationDialog
          open={openConfirmClearJobDialog}
          handleClose={handleCloseConfirmClearJobDialog}
          submitLabel={"Confirm"}
          title={"Please confirm to proceed!"}
          description={"This action will clear the entire quote. Are you sure?"}
          handleSubmit={clearFields}
        />
      )}
      {showMiscPopup && (
        <ConfirmationDialog
          open={showMiscPopup}
          handleClose={() => setShowMiscPopup(false)}
          submitLabel={"Do this for me"}
          title={`Found error with a job line`}
          description={`Miscellaneous and Influencer - Expenses & Reimbursements should not be used for Production, Media or Influencer. Please create a separate block to bill this item.`}
          handleSubmit={() => {
            handleInvalidMiscItem();
          }}
        />
      )}
      {openConfirmEditJobDialog && (
        <ConfirmationDialogWithInput
          open={openConfirmEditJobDialog}
          handleClose={() => setOpenConfirmEditJobDialog(false)}
          submitLabel={"Proceed"}
          textFieldLabel="Reason for editing"
          title={"Warning!"}
          description={
            "You are about to put this job back into edit mode. This cannot be undone, client will need to resign off any changes. " +
            "This will not change anything that has already been invoiced"
          }
          handleSubmit={(reason) => {
            handleEditAcceptedJob(reason);
          }}
          loading={loadingEditJob}
        />
      )}
      {openConfirmEditRetainerJobDialog && (
        <EditRetainerJobConfirmation
          open={openConfirmEditRetainerJobDialog}
          handleClose={() => setOpenConfirmEditRetainerJobDialog(false)}
          submitLabel={"Proceed"}
          textFieldLabel="Reason for editing"
          title={"Warning!"}
          description={
            "You are about to put this job back into edit mode. This cannot be undone, client will need to resign off any changes. " +
            "This will not change anything that has already been invoiced"
          }
          handleSubmit={(reason, retainerStartDate, retainerDuration) => {
            handleEditAcceptedRetainerJob(
              reason,
              retainerStartDate,
              retainerDuration
            );
          }}
          loading={loadingEditJob}
        />
      )}
      {openConfirmCancelJobDialog && (
        <ConfirmationDialogWithInput
          open={openConfirmCancelJobDialog}
          handleClose={() => setOpenConfirmCancelJobDialog(false)}
          submitLabel={"Proceed"}
          textFieldLabel="Reason for canceling"
          title={"Warning!"}
          description={
            "You are about to delete this job. This cannot be undone and clients will need to resign off this project again"
          }
          handleSubmit={(reason) => {
            handleCancelAcceptedJob(reason);
          }}
          loading={loadingCancelJob}
        />
      )}
      <div className="flex">
        {isReadOnly && job.status !== "approved" ? (
          <Typography style={{ fontSize: 16 }}>
            <strong>
              {getReadOnlyText(
                job.status,
                jobOrganisation,
                selectedOrganisation?.id
              )}
            </strong>
          </Typography>
        ) : (
          <Typography variant="h6" component="div">
            {job.id
              ? `Edit ${job.isRetainer ? "Retainer" : ""} Job`
              : isAdditional
              ? "Additional Quote"
              : `Create New ${job.isRetainer ? "Retainer" : ""} Job`}
          </Typography>
        )}

        {job.additional && (
          <div className="additional-badge-wrapper">
            <div className="badge additional-badge h-auto py-0 px-3">
              Additional
            </div>
          </div>
        )}
      </div>
      <div className="flex-div">
        <div className="newjob-textfield fullwidth-flex flex-row-components">
          <Tooltip
            className="tooltip-css"
            title="Company"
            placement="top"
            arrow
          >
            <Autocomplete
              key={refreshAutocomplete}
              options={companies}
              disabled={
                isReadOnly || isAdditional || !!job.crossOrganisationProject
              }
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.title;
                }
                // Regular option
                return option.name;
              }}
              value={selectedCompany}
              onChange={handleOnChangeCompany}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                //if (params.inputValue !== "") {
                // filtered.push({
                //   inputValue: params.inputValue,
                //   title: `Add New Company`,
                // });
                //}
                var filteredArr = [
                  {
                    inputValue: params.inputValue,
                    title: `Add New Company`,
                  },
                ];
                return filteredArr.concat(filtered);
              }}
              selectOnFocus
              clearOnBlur
              renderOption={(props, option) => {
                if (option.title === "Add New Company") {
                  return (
                    // <li {...props} key={option.id} onClick={() => { }} style={{position: "fixed", bottom: -65, right: 0, width: "100%", backgroundColor: "white"}}>
                    <Button
                      size="medium"
                      variant="contained"
                      fullWidth
                      //className="default-button-outlined"
                      className="job-button"
                      onClick={(e) => {
                        option = emptyCompany;
                        option.inputValue = " ";
                        option.title = " ";
                        handleSetUpdateCompanyData(option);
                      }}
                      style={{ position: "fixed", bottom: -50, right: -5 }}
                      //, width: "100%", backgroundColor: "white"
                    >
                      Add Company
                    </Button>
                    // </li>
                  );
                } else {
                  return (
                    <li {...props} key={option.id}>
                      <div className="flex space-between width-100">
                        <span>{option.name ? option.name : option.title}</span>
                        {option.name ? (
                          <Button
                            size="small"
                            className="default-button-outlined"
                            onClick={(e) => {
                              handleSetUpdateCompanyData(option);
                            }}
                          >
                            Edit
                          </Button>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </li>
                  );
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Company"
                  size="small"
                  fullWidth={true}
                  variant="filled"
                />
              )}
            />
          </Tooltip>
        </div>

        <div className="newjob-textfield-name fullwidth-flex flex-row-components">
          <Tooltip
            className="tooltip-css"
            title="Contact"
            placement="top"
            arrow
          >
            <Autocomplete
              key={refreshAutocomplete}
              options={getCompanyContacts()}
              disabled={
                percent < 0.1 || isReadOnly || !!job.crossOrganisationProject
              }
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.title;
                }
                // Regular option
                return `${option.firstName} ${option.lastName}`;
              }}
              value={
                selectedContact?.firstName !== ""
                  ? `${selectedContact?.firstName} ${selectedContact?.lastName}`
                  : ""
              }
              onChange={handleOnChangeContact}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== "") {
                  filtered.push({
                    inputValue: params.inputValue,
                    title: `Add "${params.inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    <div className="flex space-between width-100">
                      <span>
                        {option.firstName
                          ? `${option.firstName} ${option.lastName}`
                          : option.title}
                      </span>
                      {option.firstName ? (
                        <Button
                          size="small"
                          className="default-button-outlined"
                          onClick={(e) => {
                            setselectedContact(option);
                            setopenCreateContactDialog(true);
                          }}
                        >
                          Edit
                        </Button>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Contact"
                  size="small"
                  fullWidth={true}
                  variant="filled"
                  error={showContactError}
                  helperText={showContactError && "Please select a contact"}
                />
              )}
            />
          </Tooltip>
        </div>

        <div
          className={`po-textfield-container ${
            showSavePoButton ? `highlight-border-light-blue` : ``
          }`}
        >
          {showSavePoButton
            ? getPoTextField()
            : getTextField(
                "PO Number (Optional)",
                "text",
                "poNumber",
                true,
                isReadOnly || !!job.crossOrganisationProject
              )}
        </div>

        {job?.user && (
          <div className="newjob-textfield-name fullwidth-flex flex-row-components">
            {getTextField("Created By", "text", "user", true, true)}
          </div>
        )}
      </div>
      {percent >= 0.2 && (
        <div className="flex-div">
          <div
            className="newjob-textfield fullwidth-flex flex-row-components"
            style={{ minWidth: 400 }}
          >
            <Tooltip
              className="tooltip-css"
              title="Job Name"
              placement="top"
              arrow
            >
              {getSpellCheckTextField(
                "Job Name",
                "text",
                "jobName",
                true,
                isReadOnly || isAdditional
              )}
            </Tooltip>
          </div>
          {/* {jobOrganisation === 1 && (
              <div className="newjob-textfield-shootdays flex-row-components">
                {getTextField(
                  "Number of shoot days",
                  "number",
                  "shootDays",
                  true,
                  isReadOnly
                )}
              </div>
            )} */}
          {/* {job.shootDays > 0 ? (
              <div style={{ display: "flex" }}>
                <div className="newjob-textfield-date flex-row-components">
                  {getTextField(
                    "Expected Shoot Date",
                    "date",
                    "expectedShootDate",
                    false,
                    isReadOnly
                  )}
                </div>
                <div className="newjob-textfield-date flex-row-components">
                  {getTextField(
                    "Expected Transmission Date",
                    "date",
                    "expectedTransmissionDate",
                    true,
                    isReadOnly,
                    isInvalidDate,
                    invalidDateMessage,
                    job.expectedShootDate
                      ? dayjs(job.expectedShootDate).format("YYYY-MM-DD")
                      : null,
                    null
                  )}
                </div>
              </div>
            ) : (
              <></>
            )} */}
          <div style={{ display: "flex" }}>
            <React.Fragment>
              <div className="fullwidth-flex job-retainer-container">
                {getInternalCompanyId() === job.companyId && (
                  <Autocomplete
                    value={currencies.find((c) => c.value === job.currency)}
                    options={currencies}
                    onChange={(event, option) => {
                      handleJobChange("currency", option.value);
                    }}
                    getOptionLabel={(option) => option.value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`Currency`}
                        size="small"
                        fullWidth={true}
                        variant="filled"
                      />
                    )}
                    disableClearable
                    disabled={isReadOnly || !!job.crossOrganisationProject}
                  />
                )}
                <Autocomplete
                  value={
                    job["completionDate"]
                      ? {
                          display: dayjs(job["completionDate"]).format(
                            "MMMM YYYY"
                          ),
                          value: job["completionDate"],
                        }
                      : null
                  }
                  options={get12MonthsAheadAnd1MonthBackArr()}
                  onChange={(event, option) => {
                    handleJobChange("completionDate", option.value);
                  }}
                  onBlur={() => {
                    handleJobNameUpdate();
                  }}
                  getOptionLabel={(option) => option.display}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${
                        job.isRetainer ? "Start Retainer From" : "Billing Month"
                      }`}
                      size="small"
                      fullWidth={true}
                      variant="filled"
                    />
                  )}
                  disableClearable
                  disabled={isReadOnly || !!job.crossOrganisationProject}
                />
                {job.isRetainer && job.completionDate && (
                  <Autocomplete
                    value={
                      job["retainerDuration"]
                        ? {
                            id: job["retainerDuration"],
                            value: `${job["retainerDuration"]} Month${
                              job["retainerDuration"] > 1 ? "s" : ""
                            }`,
                          }
                        : {
                            id: 1,
                            value: `1 Month`,
                          }
                    }
                    options={getMonthsArray(12)}
                    onChange={(event, option) => {
                      handleJobChange("retainerDuration", option.id);
                    }}
                    getOptionLabel={(option) => option.value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${job.isRetainer && "Duration"}`}
                        size="small"
                        fullWidth={true}
                        variant="filled"
                      />
                    )}
                    disableClearable
                    disabled={isReadOnly}
                  />
                )}
                {job.isRetainer && (
                  <Autocomplete
                    value={job.retainerType ? job.retainerType : null}
                    options={["MONTHLY", "QUARTERLY", "YEARLY", "TOTAL"]}
                    getOptionDisabled={(option) => {
                      if (job["retainerDuration"]) {
                        if (option === "QUARTERLY") {
                          return job["retainerDuration"] % 3 !== 0;
                        } else if (option === "YEARLY") {
                          return job["retainerDuration"] % 12 !== 0;
                        } else {
                          return false;
                        }
                      } else {
                        return false;
                      }
                    }}
                    onChange={(event, option) => {
                      handleJobChange("retainerType", option);
                      setInfoDialogProps({
                        title: "Important!",
                        context:
                          "Please remember to set up this quote as a monthly retainer. This option only changes what the client sees. It will still be invoiced monthly.",
                      });
                      setShowInfoDialog(true);
                    }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Retainer View Type"
                        size="small"
                        fullWidth={true}
                        variant="filled"
                      />
                    )}
                    disableClearable
                    disabled={isReadOnly}
                  />
                )}
              </div>
            </React.Fragment>
          </div>
        </div>
      )}
      {fromManage && (
        <div className="newjob-textfield-name fullwidth-flex flex-row-components">
          <div>
            <Button
              size="small"
              color="primary"
              variant="contained"
              className="default-button margin-10"
              onClick={() => {
                if (job.isRetainer) {
                  setOpenConfirmEditRetainerJobDialog(true);
                } else {
                  setOpenConfirmEditJobDialog(true);
                }
              }}
            >
              Edit Job
            </Button>
            <Button
              size="small"
              color="primary"
              variant="contained"
              className="default-button margin-10"
              onClick={() => {
                setOpenConfirmCancelJobDialog(true);
              }}
            >
              Cancel Job
            </Button>
          </div>
        </div>
      )}
      {!!job.currency && !!job.crossOrganisationProject && (
        <InternalQuoteNote
          currency={job.currency}
          crossOrganisationProject={job.crossOrganisationProject}
        />
      )}
      {job.currency && job.currency !== "NZD" && job.exchangeRate && (
        <ForeignCurrencyNote
          currency={job.currency}
          exchangeRate={job.exchangeRate}
        />
      )}
      {percent >= 0.4 && (
        <Fragment>
          <div className="newjob-textfield-jobdescription">
            <div className="after-divider">
              <Typography variant="subtitle2" className="subtitle-bold">
                Job Description
              </Typography>
            </div>
            <Editor
              editorState={editorState}
              wrapperClassName="jobdescription-text-editor-wrapper"
              editorClassName="jobdescription-text-editor"
              onEditorStateChange={onEditorStateChange}
              handlePastedText={() => false}
              readOnly={isReadOnly}
              toolbar={{
                options: ["inline", "fontSize", "list"],
                inline: {
                  options: ["bold", "italic", "underline"],
                },
                list: {
                  options: ["unordered", "ordered"],
                },
              }}
            />
          </div>
        </Fragment>
      )}
      {percent >= 0.5 && (
        <div className="row-textbox-table">
          <JobLines
            products={products}
            shootDays={tempShootDays}
            setHasChanges={setHasChanges}
            jobCategories={jobCategories}
            setJobCategories={setJobCategories}
            isReadOnly={isReadOnly}
            percent={percent}
            refreshJobDescriptionEditor={refreshJobDescriptionEditor}
            forceSaveDraftJob={forceSaveDraftJobNoCheck}
            handleSetUpdateCompanyData={handleSetUpdateCompanyData}
          />
        </div>
      )}
      <div className="jobs-footer">
        {!!job?.crossOrganisationProject ? (
          <JobInternalActions
            saveDraftJob={saveDraftJob}
            hasJobNameError={hasJobNameError}
            isInvalidDate={
              isInvalidDate ||
              (job.organisationId !== 1 && isEmpty(job.completionDate))
            }
            isReadOnly={isReadOnly}
            percent={percent}
            saving={saving}
            setsnackText={setsnackText}
            setsnackSeverity={setsnackSeverity}
            setopenSnack={setopenSnack}
            setIsReadOnly={setIsReadOnly}
          />
        ) : (
          <JobActions
            setOpenConfirmClearJobDialog={setOpenConfirmClearJobDialog}
            handleCheckAndSend={handleCheckAndSend}
            downloadPdf={downloadPdf}
            hasJobNameError={hasJobNameError}
            isInvalidDate={isInvalidDate}
            saveDraftJob={saveDraftJob}
            saving={saving}
            loadingPdf={loadingPdf}
            isReadOnly={isReadOnly}
            percent={percent}
            showSavePoButton={showSavePoButton}
            handleSaveAcceptedQuotePo={handleSaveAcceptedQuotePo}
            selectedCompany={selectedCompany}
          />
        )}
      </div>
      <div className="job-bottom-spacing"></div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  products: state.product.products,
  companies: state.company.companies,
  job: state.job.job,
  jobCategories: state.job.job.jobCategories,
  jobLines: state.job.job.jobLines,
  selectedOrganisation: state.organisation.selectedOrganisation,
  exemptions: state.job.exemptions,
});

export default connect(mapStateToProps, {
  getAllProducts,
  getCompanies,
  checkNzbnToken,
  updateJobStore,
  resetJobStore,
  getJobById,
  saveJobUpdates,
  createDraft,
  getExemptions,
  removeJobLineStore,
  updateAllJobLinesStore,
  getAllProductCategories,
})(Job);
