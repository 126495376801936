import { ResponsiveBar } from "@nivo/bar";
import React from "react";

const BarComponent = ({ bar, borderColor }) => {
  return (
    <g transform={`translate(${bar.x},${bar.y})`}>
      <rect
        x={-3}
        y={7}
        width={bar.width}
        height={bar.height}
        fill="rgba(0, 0, 0, .07)"
      />
      <rect width={bar.width} height={bar.height} fill={bar.color} />
      <rect
        x={bar.width - 5}
        width={5}
        height={bar.height}
        fill={borderColor}
        fillOpacity={0.2}
      />
      <text
        x={bar.width - 16}
        y={bar.height / 2 - 8}
        textAnchor="end"
        dominantBaseline="central"
        fill="black"
        style={{
          fontWeight: 900,
          fontSize: 10,
          textAnchor: "middle",
        }}
      >
        {bar.data.indexValue}
      </text>
      <text
        x={bar.width - 16}
        y={bar.height / 2 + 10}
        textAnchor="end"
        dominantBaseline="central"
        fill={borderColor}
        style={{
          fontWeight: 900,
          fontSize: 11,
          
        }}
      >
        {`$${Number(bar.data.value).toLocaleString("en-NZ", {
          minimumFractionDigits: 2,
        })}`}
      </text>
    </g>
  );
};

const ProfitabilityBar = ({ profitabilityData }) => {
  return profitabilityData && (
    <div style={{ height: 500 }}>
      <ResponsiveBar
        data={[...profitabilityData].sort((a, b) => a.total - b.total)}
        layout="horizontal"
        margin={{ top: 26, right: 120, bottom: 26, left: 60 }}
        keys={["total"]}
        indexBy="companyName"
        colorBy="indexValue"
        colors={{ scheme: 'pastel1' }}
        borderColor={{ from: "color", modifiers: [["darker", 2.6]] }}
        enableGridX
        enableGridY={false}
        axisTop={{ format: "~s" }}
        axisBottom={{ format: "~s" }}
        axisLeft={null}
        padding={0.3}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.4]] }}
        isInteractive={false}
        barComponent={BarComponent}
      />
    </div>
  );
};

export default ProfitabilityBar;
