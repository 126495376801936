import { Checkbox, FormControlLabel, List, Popover } from "@mui/material";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

const DashboardFilterPopover = ({ 
    filters, 
    handleChangeFilter,
    open,
    handleClose,
    anchorEl
}) => {

    const handleStatusCheckboxClick = (status, checked) => {
        var copyOfStatusFilter = [...filters.status];
        if(checked){
            if(!copyOfStatusFilter.includes(status)){
                copyOfStatusFilter.push(status);
            }
        } else {
            if(copyOfStatusFilter.includes(status)){
                copyOfStatusFilter = copyOfStatusFilter.filter(s => s !== status);
            }
        }
        handleChangeFilter("status", copyOfStatusFilter);
    }

    return (
        <div>
            <Popover
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                PaperProps={{ style: { padding: 10 } }}
            >
                <div>
                    <FormControlLabel
                        label="Include Draft"
                        control={
                            <Checkbox
                                checked={filters.status.includes("draft")}
                                onChange={(e) => {
                                    handleStatusCheckboxClick("draft", e.target.checked);
                                }}
                            />
                        }
                    />
                </div>
                <div>
                    <FormControlLabel
                        label="Include Checked"
                        control={
                            <Checkbox
                                checked={filters.status.includes("checked")}
                                onChange={(e) => {
                                    handleStatusCheckboxClick("checked", e.target.checked);
                                }}
                            />
                        }
                    />
                </div>
                <div>
                    <FormControlLabel
                        label="Include Sent"
                        control={
                            <Checkbox
                                checked={filters.status.includes("sent")}
                                onChange={(e) => {
                                    handleStatusCheckboxClick("sent", e.target.checked);
                                }}
                            />
                        }
                    />
                </div>
                <div>
                    <FormControlLabel
                        label="Include Approved"
                        control={
                            <Checkbox
                                checked={filters.status.includes("approved")}
                                onChange={(e) => {
                                    handleStatusCheckboxClick("approved", e.target.checked);
                                }}
                            />
                        }
                    />
                </div>
            </Popover>
        </div>
    );
};

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, {

})(DashboardFilterPopover);
