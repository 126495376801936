import { Fade, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import FontFaceObserver from "fontfaceobserver";
import "./thompsonSpencerLoader.scss";
import FirebaseGAuth from "../OAuth/FirebaseGAuth";
const ThompsonSpencerLoader = ({ location }) => {
  const sfProFont = new FontFaceObserver("SFPro");
  sfProFont.load().then(() => {
    setIsCustomFontLoaded(true);
  });
  const [welcome, setwelcome] = useState(null);
  const [fadeTransition, setfadeTransition] = useState(true);
  const [goToRoute, setgoToRoute] = useState(false);
  const [isCustomFontLoaded, setIsCustomFontLoaded] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  const handleWelcome = (user) => {
    setwelcome(
      `Hi ${user?.displayName.substring(0, user?.displayName?.indexOf(" "))}`
    );
  };

  useEffect(() => {
    if (!hasLoaded && isCustomFontLoaded) {
      setTimeout(() => setHasLoaded(true), 1500);
    }
  }, [hasLoaded, isCustomFontLoaded]);

  useEffect(() => {
    if (welcome) {
      setTimeout(() => setfadeTransition(false), 2000);
    }
  }, [welcome, isCustomFontLoaded]);

  useEffect(() => {
    if (!fadeTransition && isCustomFontLoaded) {
      setTimeout(() => setgoToRoute(true), 500);
    }
  }, [fadeTransition, isCustomFontLoaded]);

  return (
    <div className="thompson-spencer-loader-wrapper">
      <div>
        {isCustomFontLoaded && (
          <>
            {hasLoaded && (
              <FirebaseGAuth
                endpoint={
                  location.state !== undefined
                    ? location.state.endpoint === "/index.html"
                      ? "/home"
                      : location.state.endpoint
                    : "/home"
                }
                goToRoute={goToRoute}
                handleWelcome={handleWelcome}
              />
            )}
            <div className="thompson-spencer-loader-text-container">
              {welcome ? (
                <Fade in={fadeTransition} timeout={{ enter: 1000, exit: 500 }}>
                  <span>{welcome}</span>
                </Fade>
              ) : (
                <Fade in={fadeTransition} timeout={{ enter: 1500, exit: 500 }}>
                  <span>Authenticating</span>
                </Fade>
              )}
            </div>
          </>
        )}
      </div>
      <div>
        <div className="thompson-spencer-loader-root">
          <div className="thompson-spencer-loader-logo">
            <img
              src="https://storage.googleapis.com/quotes-assets/logo-vector/TS-vector.svg"
              width={150}
              height={150}
            />
          </div>
          <div className="thompson-spencer-loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(ThompsonSpencerLoader);
