import React from "react";
import { currencySymbolFormat } from "../../../Utils/GeneralUtils";
import { calculateJoblinesTotal } from "../../../Utils/JobHelpers";
import { connect } from "react-redux";
import { Typography } from "@mui/material";

const TotalRemaining = ({ jobLines, currency, crossOrganisationProject }) => {
  return (
    <div
      style={{
        display: "grid",
        justifyItems: "flex-end",
        gridTemplateColumns: "1fr",
        marginTop: 15,
      }}
    >
      <div className="cross-organisation-total-remaining-container">
        <div>{`Budget: `}</div>
        <div style={{ marginLeft: "25px" }}>
          {currencySymbolFormat(
            crossOrganisationProject?.totalToSpend,
            currency
          )}
        </div>
      </div>
      <div className="cross-organisation-total-remaining-container">
        <div>{`Remaining: `}</div>
        <div style={{ marginLeft: "25px" }}>
          {currencySymbolFormat(
            (crossOrganisationProject?.totalToSpend || 0) -
              calculateJoblinesTotal(jobLines, "exchangedTotal"),
            currency
          )}
        </div>
      </div>
      <div style={{display: "flex", justifyContent: "flex-start", padding: 5}} className="exchanged-grand-total">
        <Typography variant="caption">You must allocate all budget</Typography>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  crossOrganisationProject: state.job.job.crossOrganisationProject,
  currency: state.job.job.currency,
  jobLines: state.job.job.jobLines,
});

export default connect(mapStateToProps, {})(TotalRemaining);
