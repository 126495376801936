import { Checkbox, FormControlLabel, List, Popover } from "@mui/material";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updateJobsFilter } from "../../reduxActions/job";

const JobFilterPopover = (props) => {
    let { jobsFilter, updateJobsFilter } = props;

    const handleStatusCheckboxClick = (status, checked) => {
        var copyOfStatusFilter = [...jobsFilter.status];
        if(checked){
            if(!copyOfStatusFilter.includes(status)){
                copyOfStatusFilter.push(status);
            }
        } else {
            if(copyOfStatusFilter.includes(status)){
                copyOfStatusFilter = copyOfStatusFilter.filter(s => s !== status);
            }
        }
        updateJobsFilter("status", copyOfStatusFilter);
    }

    return (
        <div>
            <Popover
                open={props.open}
                onClose={props.handleClose}
                anchorEl={props.anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                PaperProps={{ style: { padding: 10 } }}
            >
                <div>
                    <FormControlLabel
                        label="Include Draft"
                        control={
                            <Checkbox
                                checked={jobsFilter.status.includes("draft")}
                                onChange={(e) => {
                                    handleStatusCheckboxClick("draft", e.target.checked);
                                }}
                            />
                        }
                    />
                </div>
                <div>
                    <FormControlLabel
                        label="Include Checked"
                        control={
                            <Checkbox
                                checked={jobsFilter.status.includes("checked")}
                                onChange={(e) => {
                                    handleStatusCheckboxClick("checked", e.target.checked);
                                }}
                            />
                        }
                    />
                </div>
                <div>
                    <FormControlLabel
                        label="Include Sent"
                        control={
                            <Checkbox
                                checked={jobsFilter.status.includes("sent")}
                                onChange={(e) => {
                                    handleStatusCheckboxClick("sent", e.target.checked);
                                }}
                            />
                        }
                    />
                </div>
                <div>
                    <FormControlLabel
                        label="Include Approved"
                        control={
                            <Checkbox
                                checked={jobsFilter.status.includes("approved")}
                                onChange={(e) => {
                                    handleStatusCheckboxClick("approved", e.target.checked);
                                }}
                            />
                        }
                    />
                </div>
            </Popover>
        </div>
    );
};

const mapStateToProps = (state) => ({
    jobsFilter: state.job.jobsFilter,
});

export default connect(mapStateToProps, {
    updateJobsFilter,
})(JobFilterPopover);
