import React from "react";
import {
  Dialog,
  Slide,
  IconButton,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Close from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogWithHtmlContent = (props) => {
  let { isOpen, handleClose, htmlContent, title } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      maxWidth={"md"}
      onBackdropClick={(e) => e.preventDefault()}
    >
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={props.handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </DialogContent>
    </Dialog>
  );
};

export default DialogWithHtmlContent;
