import { Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { checkMarginPercentage } from "../../Utils/Checkers";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const MarginDisplay = ({ total = 0, margin = 0, currency = "NZD" }) => {
  const [percentage, setPercentage] = useState(0);
  const [checkerProps, setCheckerProps] = useState();

  useEffect(() => {
    calculateTotalMarginPercentage();
  }, [total, margin]);

  const calculateTotalMarginPercentage = () => {
    //const cost = total - margin;
    const grossProfitPercentage = total > 0 ? margin / total : 0;
    setPercentage(grossProfitPercentage);
    setCheckerProps(checkMarginPercentage(grossProfitPercentage));
  };

  return (
    <Fragment>
      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit">{checkerProps?.message}</Typography>
            <b>{"Margin: "}</b>{" "}
            {percentage?.toLocaleString("en-NZ", {
              style: "percent",
            })}
          </React.Fragment>
        }
      >
        <span>
          {margin?.toLocaleString("en-UK", {
            style: "currency",
            currency: currency,
            minimumFractionDigits: 2,
          })}
        </span>
      </HtmlTooltip>
    </Fragment>
  );
};

export default MarginDisplay;
