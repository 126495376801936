import { Skeleton } from '@mui/material'
import React from 'react'

const PageLoadingSkeleton = () => {
  return (
    <div style={{display: "flex", flexDirection: "column", gap: 15, height: "85vh"}}>
        <Skeleton variant='rounded' animation="wave" height={50}/>
        <Skeleton variant='rounded' animation="wave" height={50}/>
        <Skeleton variant='rounded' animation="wave" style={{flex: "1"}}/>
    </div>
  )
}

export default PageLoadingSkeleton