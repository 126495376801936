import React, { useEffect, useState } from 'react';
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Autocomplete,
    Checkbox,
    CircularProgress,
    IconButton
} from '@mui/material';
import {
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
    CheckBox as CheckBoxIcon,
    Close as CloseIcon
} from "@mui/icons-material";
import { useUserContext } from '../../context/user/UserContext';
import axios from 'axios';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const AddTeamMemebersDialog = ({
    open,
    handleClose,
    jobId,
    handleSave
}) => {
    const { allUsers } = useUserContext();

    const [teamMembers, setTeamMembers] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        //Get default Team
        setLoading(true);
        axios.get(`Job/GetDefaultTeamMembers/${jobId}`)
            .then(res => {
                setTeamMembers(res.data);
            }).catch(e => {
                console.log(e);
            }).finally(() => {
                setLoading(false);
            })
    }, [])

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
            <DialogTitle id="form-dialog-title">
                Select Team Members
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            {loading ? (
                <CircularProgress />
            ) : (
                <DialogContent>
                    <DialogContentText>
                        We have recommended a team for this job below.
                    </DialogContentText>
                    <div style={{ paddingTop: "10px" }}>
                        <Autocomplete
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            size='small'
                            multiple
                            options={[
                                { id: -1, firstName: "People of Influence", lastName: "" },
                                { id: -2, firstName: "Magnesium", lastName: "" },
                                { id: -3, firstName: "Flying Tiger", lastName: "" },
                                { id: -4, firstName: "Community Management", lastName: "" },
                                ...allUsers
                            ]}
                            getOptionLabel={(option) =>
                                option.firstName + " " + option.lastName
                            }
                            value={teamMembers}
                            onChange={(e, items, type, selected) => {
                                if (selected && selected.option.id < 0) {
                                    var newItems = items.filter(i => i.id >= 0);
                                    var usersToAdd = [];
                                    switch (selected.option.firstName) {
                                        case "People of Influence":
                                            usersToAdd = allUsers.filter(u => u.teams && u.teams.includes("People of Influence"));
                                            break;
                                        case "Magnesium":
                                            usersToAdd = allUsers.filter(u => u.teams && u.teams.includes("Magnesium"));
                                            break;
                                        case "Flying Tiger":
                                            usersToAdd = allUsers.filter(u => u.teams && u.teams.includes("Flying Tiger"));
                                            break;
                                        case "Community Management":
                                            usersToAdd = allUsers.filter(u => u.teams && u.teams.includes("Community Management"));
                                            break;
                                        default:
                                            break;
                                    }
                                    usersToAdd.forEach(user => {
                                        if(newItems.findIndex(i => i.id === user.id) < 0){
                                            newItems.push(user);
                                        }
                                    })
                                    setTeamMembers(newItems);
                                } else {
                                    setTeamMembers(items.filter(i => i.id >= 0))
                                }
                            }}
                            disableCloseOnSelect
                            renderOption={(props, option, { selected }) => {
                                return (
                                    <li {...props} key={`${option.id}-user-list-item`}>
                                        {option.id >= 0 && (
                                            <Checkbox
                                                key={`${option.id}-checkbox`}
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected || teamMembers.includes(u => u.id === option.id)}
                                            />
                                        )}
                                        {option.firstName + " " + option.lastName}
                                    </li>
                                )
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    label=""
                                />
                            )}
                        />
                    </div>
                </DialogContent>
            )}
            <DialogActions>
                <Button onClick={() => handleSave(teamMembers)} color="primary">
                    Continue
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddTeamMemebersDialog;