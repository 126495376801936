import React from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import { Container } from "@mui/material";
import { connect } from "react-redux";

const NewJobButton = ({ handleAdditionalJob, selectedOrganisation }) => {
  const history = useHistory();
  return (
    <Container maxWidth="xl">
      <div style={{ display: "flex", justifyContent: "center", gap: 10 }}>
        <Button
          variant="contained"
          className="default-button"
          onClick={() => history.push("job")}
        >
          Create New Job
        </Button>
        {selectedOrganisation?.id > 1 ? (
          <Button
            variant="contained"
            className="default-button"
            onClick={() =>
              history.push({ pathname: "/job", state: { isRetainer: true } })
            }
          >
            Create Retainer
          </Button>
        ) : (
          <></>
        )}

        <Button
          variant="contained"
          className="default-button"
          onClick={(e) => {
            handleAdditionalJob();
          }}
        >
          Add to existing job
        </Button>
        
      </div>
    </Container>
  );
};
const mapStateToProps = (state) => ({
  selectedOrganisation: state.organisation.selectedOrganisation,
});

export default connect(mapStateToProps, {})(NewJobButton);
