import React, { Fragment, useState, useEffect } from "react";
import Close from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
import axios from "axios";
import { updateJobStore } from "../../reduxActions/job";
import { getCompanies } from "../../reduxActions/company";

const AdditionalJobDialog = ({
  open,
  handleClose,
  companies,
  updateJobStore,
  redirectAddionalJob,
  selectedOrganisation,
  getCompanies
}) => {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobs, setJobs] = useState(null);

  useEffect(() => {
    if (companies?.length === 0) {
      getCompanies(0);
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedCompany) {
      fetchCompaniesJob();
    }
  }, [selectedCompany]);

  const fetchCompaniesJob = () => {
    axios
      .get(`Job/GetJobsByCompanyId/${selectedCompany.id}?jobStatus=approved&additional=false`)
      .then(({ data }) => {
        if (data) {
          setJobs(data);
        }
      });
  };

  const changeSelectedCompany = (company) => {
    setSelectedCompany(companies.find((c) => c.id === company.id));
  };

  const loadAdditionalJob = async () => {
    await updateJobStore("companyId", selectedJob.companyId);
    await updateJobStore("company", selectedJob.company);
    await updateJobStore("contactId", selectedJob.contactId);
    await updateJobStore("jobName", selectedJob.jobName);
    await updateJobStore("additional", true);
    await updateJobStore("completionDate", selectedJob.completionDate || selectedJob.expectedTransmissionDate);
    await updateJobStore("jobDescription", selectedJob.jobDescription);
    await updateJobStore("paymentType", "END_OF_MONTH_BILLING");
    await updateJobStore("parentJobId", selectedJob.id);
    await updateJobStore("projectId", selectedJob.projectId);
    await updateJobStore("projectCode", selectedJob.projectCode);
    await updateJobStore("organisationId", 2);
    await redirectAddionalJob();
  }
  return (
    <Fragment>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" PaperProps={{
      style: {
        backgroundColor: "#ededed",
      },
    }}>
        <DialogTitle id="quote-preview-dialog-title">
          {`Select the job to proceed`}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div style={{ padding: 15 }}>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  options={companies}
                  getOptionLabel={(option) => option.name}
                  value={selectedCompany}
                  onChange={(e, newValue) => {
                    changeSelectedCompany(newValue);
                  }}
                  selectOnFocus
                  clearOnBlur
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Company"
                      size="small"
                      fullWidth={true}
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={jobs ? jobs : []}
                  getOptionLabel={(option) => option.jobName}
                  value={selectedJob}
                  onChange={(e, selectedValue) => {
                    setSelectedJob(selectedValue);
                  }}
                  selectOnFocus
                  clearOnBlur
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.jobName}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Jobs"
                      size="small"
                      fullWidth={true}
                      variant="filled"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{ display: "flex" }}>
            <div style={{ paddingRight: 15 }}>
              <Button
                variant="contained"
                className="default-button"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
            <div style={{ paddingRight: 15 }}>
              <Button
                variant="contained"
                className={
                  !selectedJob || !selectedCompany ? "" : "default-button"
                }
                onClick={loadAdditionalJob}
                disabled={!selectedJob || !selectedCompany}
              >
                Next
              </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  companies: state.company.companies,
  selectedOrganisation: state.organisation.selectedOrganisation,
});

export default connect(mapStateToProps, {updateJobStore, getCompanies})(AdditionalJobDialog);
