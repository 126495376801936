import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const InfoDialog = ({
    open,
    handleClose,
    title,
    context
}) => {

    const closeDialog = (e, reason) => {
        if (reason !== "backdropClick") {
            handleClose();
        }
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={(e, reason) => closeDialog(e, reason)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {context}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e, reason) => closeDialog(e, reason)} autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default InfoDialog;