import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from "./registerServiceWorker";
// import '@fontsource/roboto/300.css';
// import '@fontsource/roboto/400.css';
// import '@fontsource/roboto/500.css';
// import '@fontsource/roboto/700.css';
import store from "./store/store";
import { Provider } from "react-redux";
import "./fonts/SF-Pro-Text-Heavy.otf";
import "./fonts/SF-Pro.ttf";
import { AuthContextProvider } from "./context/auth/AuthContext";
import { UserContextProvider } from "./context/user/UserContext";
import { ThemeContextProvider } from "./context/theme/ThemeContext";
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename={baseUrl}>
      <ThemeContextProvider>
        <AuthContextProvider>
          <UserContextProvider>
            <App />
          </UserContextProvider>
        </AuthContextProvider>
      </ThemeContextProvider>
    </BrowserRouter>
  </Provider>,
  rootElement
);

// registerServiceWorker();
unregister();
