import { createAction } from "@reduxjs/toolkit";
import axios from "axios";
import _ from "lodash";

export const resetJobStore = (organisationId) => async (dispatch) => {
  try {
    dispatch(resetJobStoreSuccess(organisationId));
  } catch (error) {
    console.log("reset-error", error);
    dispatch(logError(error));
  }
};
export const resetJobStoreSuccess = createAction("RESET_JOB_STORE");

export const updateJobStore = (field, value) => async (dispatch) => {
  try {
    dispatch(updateJobStoreSuccess({ field, value }));
  } catch (error) {
    dispatch(logError(error));
  }
};
export const updateJobStoreSuccess = createAction("UPDATE_JOB_STORE");

export const updateAllJobLinesStore = (jobLines) => async (dispatch) => {
  try {
    dispatch(updateAllJobLinesStoreSuccess(jobLines));
  } catch (error) {
    dispatch(logError(error));
  }
};
export const updateAllJobLinesStoreSuccess = createAction(
  "UPDATE_ALL_JOB_LINES_STORE"
);

export const updateAllJobCategoriesStore = (jobCategories) => async (dispatch) => {
  try {
    dispatch(updateAllJobCategoriesStoreSuccess(jobCategories));
  } catch (error) {
    dispatch(logError(error));
  }
};
export const updateAllJobCategoriesStoreSuccess = createAction(
  "UPDATE_ALL_JOB_CATEGORIES_STORE"
);

export const updateSingleJobLineStore =
  (jobLineGuid, field, value) => async (dispatch) => {
    try {
      dispatch(updateSingleJobLineStoreSuccess({ jobLineGuid, field, value }));
    } catch (error) {
      dispatch(logError(error));
    }
  };
export const updateSingleJobLineStoreSuccess = createAction(
  "UPDATE_SINGLE_JOB_LINE_STORE"
);

export const updateCategoryStore = (category) => async (dispatch) => {
  try {
    dispatch(updateCategoryNameStoreSuccess(category));
  } catch (error) {
    dispatch(logError(error));
  }
};
export const updateCategoryNameStoreSuccess = createAction(
  "UPDATE_CATEGORY_NAME_STORE"
);

export const addProductToJobLineStore =
  (jobLineGuid, selectedProduct) => async (dispatch) => {
    try {
      dispatch(
        addProductToJobLineStoreSuccess({ jobLineGuid, selectedProduct })
      );
    } catch (error) {
      dispatch(logError(error));
    }
  };
export const addProductToJobLineStoreSuccess = createAction(
  "ADD_PRODUCT_TO_JOB_LINE_STORE"
);

export const addNewJobLineStore =
  (newJobLines, category) => async (dispatch) => {
    try {
      dispatch(addNewJobLineStoreSuccess({ newJobLines, category }));
    } catch (error) {
      dispatch(logError(error));
    }
  };
export const addNewJobLineStoreSuccess = createAction("ADD_JOB_LINE_STORE");

export const removeJobLineStore = (jobLineGuid) => async (dispatch) => {
  try {
    dispatch(removeJobLineStoreSuccess(jobLineGuid));
    axios.delete(`Job/DeleteJobLine/${jobLineGuid}`);
  } catch (error) {
    dispatch(logError(error));
  }
};
export const removeJobLineStoreSuccess = createAction("REMOVE_JOB_LINE_STORE");

export const reorderJobLinesSameCategory =
  (category, sourceIndex, destinationIndex) => async (dispatch) => {
    try {
      dispatch(
        reorderJobLinesSameCategorySuccess({
          category,
          sourceIndex,
          destinationIndex,
        })
      );
    } catch (error) {
      dispatch(logError(error));
    }
  };
export const reorderJobLinesSameCategorySuccess = createAction(
  "REORDER_JOB_LINES_SAME_CATEGORY"
);

export const moveJobLinesBetweenCategories =
  (fromCategory, toCategory, source, destination) => async (dispatch) => {
    try {
      dispatch(
        moveJobLinesBetweenCategoriesSuccess({
          fromCategory,
          toCategory,
          source,
          destination,
        })
      );
    } catch (error) {
      dispatch(logError(error));
    }
  };
export const moveJobLinesBetweenCategoriesSuccess = createAction(
  "MOVE_JOB_LINES_BETWEEN_CATEGORIES"
);

export const getJobsSuccess = createAction("GET_JOBS");
export const getJobsSuccessAddToExisting = createAction(
  "GET_JOBS_ADD_TO_EXISTING"
);

export const getJobs =
  (jobsFilter, page = 0, rowsPerPage = 25) =>
  async (dispatch) => {
    try {
      const {
        organisationId = 0,
        users = [],
        companyId = null,
        keyword = null,
        team = null,
        includeApproved = null,
        status = [],
      } = jobsFilter;

      const res = await axios.get("Job/SearchJobsFromViewTable", {
        params: {
          organisationId,
          users: users.length > 0 ? [users].flat().join(",") : null,
          companyId,
          keyword,
          team: team && team.length > 0 ? team : null,
          includeApproved,
          status: status.length > 0 ? status.join(",") : null,
          page,
          pageSize: rowsPerPage,
        },
      });

      if (page === 0) {
        dispatch(getJobsSuccess(res.data));
      } else {
        dispatch(getJobsSuccessAddToExisting(res.data));
      }
    } catch (error) {
      dispatch(logError(error));
    }
  };

export const getJobById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`Job/GetJobById/${id}`);
    dispatch(getJobSuccess(res.data));
  } catch (error) {
    dispatch(logError(error));
  }
};
export const getJobSuccess = createAction("GET_JOB");

export const saveJobUpdates = (job, activeUserEmail) => async (dispatch) => {
  try {
    const res = await axios.put(`Job/UpdateJob?user=${activeUserEmail || ""}`, job);
    dispatch(saveJobUpdatesSuccess(res.data));
  } catch (error) {
    dispatch(logError(error));
  }
};
export const saveJobUpdatesSuccess = createAction("UPDATE_JOB");

export const createDraft = (job, user) => async (dispatch) => {
  try {
    job.user = user;
    const res = await axios.post("Job/CreateDraft", job);
    dispatch(createDraftSuccess(res.data));
  } catch (error) {
    dispatch(logError(error));
  }
};
export const createDraftSuccess = createAction("CREATE_DRAFT_JOB");

export const setTeamMembersStore = (teamMembers) => async (dispatch) => {
  try {
    dispatch(setTeamMembersStoreSuccess(teamMembers));
  } catch (error) {
    dispatch(logError(error));
  }
};
export const setTeamMembersStoreSuccess = createAction(
  "SET_TEAM_MEMBERS_STORE"
);

export const updateJobsFilter = (field, value) => async (dispatch) => {
  dispatch(updateJobsFilterSuccess({ field, value }));
};
export const updateJobsFilterSuccess = createAction("UPDATE_JOBS_FILTER");

export const logError = createAction("LOG_JOB_ERROR");

export const deleteJobCategory = (category) => async (dispatch) => {
  try {
    if (category?.id) {
      axios
        .delete("job/DeleteJobCategory/" + category?.id)
        .then(({ data }) => {
          dispatch(deleteJobCategorySuccess(category));
        })
        .catch((err) => {
          dispatch(logError(err));
        });
    } else {
      dispatch(deleteJobCategorySuccess(category));
    }
  } catch (error) {
    dispatch(logError(error));
  }
};
export const deleteJobCategorySuccess = createAction("DELETE_JOB_CATEGORY");

export const getExemptions = () => async (dispatch) => {
  try {
    const res = await axios.get("MarginExemption/GetActiveExemptions");
    dispatch(getExemptionsSuccess(res.data));
  } catch (error) {
    dispatch(logError(error));
  }
};
export const getExemptionsSuccess = createAction("GET_EXEMPTIONS");