import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  products: [],
  productCategories: [],
};

const product = createReducer(initialState, {
  GET_ALL_PRODUCTS: (state, action) => {
    return {
      ...state,
      products: action.payload,
    };
  },
  GET_ALL_PRODUCT_CATEGORIES: (state, action) => {
    return {
      ...state,
      productCategories: action.payload,
    };
  },
  SAVE_PRODUCT_CATEGORIES: (state, action) => {
    return {
      ...state,
      productCategories: action.payload,
    };
  },
  CREATE_PRODUCT: (state, action) => {
    return {
      ...state,
      products: [action.payload, ...state.products],
    };
  },
  UPDATE_PRODUCT_DETAILS: (state, action) => {
    return {
      ...state,
      products: [...state.products].map(p => p.id === action.payload.id ? action.payload : p),
    };
  },
  BATCH_SAVE_PRODUCTS: (state, action) => {
    let productsClone = [...state.products];
    action.payload.forEach(product => {
      productsClone = productsClone.map(p => p.id === product.id ? product : p)
    });
    return {
      ...state,
      products: productsClone,
    };
  },
  ARCHIVE_PRODUCTS: (state, action) => {
    const productsClone = state.products.reduce((acc, product) => {
      if (!action.payload.includes(product.id)) {
        acc.push(product);
      }
      return acc;
    }, []);
    debugger
    return {
      ...state,
      products: productsClone,
    };
  },
});

export default product;
