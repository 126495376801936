import React, { useEffect, useState, Fragment } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import AuthenticatedRoute from "./components/OAuth/AuthenticatedRoute";
import { connect } from "react-redux";
import Layout from "./components/Shared/Layout";
import Home from "./components/Home";
import Job from "./components/Jobs/Job";
import { getCompanies } from "./reduxActions/company";
import "./custom.scss";
import "./components/assets/base.scss";
import Products from "./components/Products/Products";
import { getExpenseCodes } from "./reduxActions/data";
import { getAllProducts, getAllProductCategories } from "./reduxActions/product";
import Login from "./components/OAuth/Login";
import Admin from "./components/Admin/Admin";
import Ipad from "./components/Admin/Ipad/Ipad";
import UploadVideos from "./components/Admin/Ipad/UploadVideos";
import Defaults from "./components/Admin/Defaults/Defaults";
import Emails from "./components/Admin/Emails/Emails";
import Company from "./components/Admin/Company/Company";
import ContractsAndTerms from "./components/ContractsAndTerms/ContractsAndTerms";
import ThompsonSpencerLoader from "./components/Shared/ThompsonSpencerLoader";
import axios from "axios";
import { useAuth } from "./context/auth/AuthContext";
import { auth } from "./config/firebase";
import Dashboard from "./components/Admin/Dashboard/Dashboard";
import { Helmet } from "react-helmet";
import InfluencerSearch from "./components/Influencers/InfluencerSearch";
import Settings from "./components/Admin/SystemSettings/Settings";
import MarginExemptionHomePage from "./components/Jobs/socialites/MarginExemption/MarginExemptionHomePage";
import MarginExemptionLandingPage from "./components/Jobs/socialites/MarginExemption/MarginExemptionLandingPage";
import RetainerJobsEndingSoon from "./components/Jobs/retainers/RetainerJobsEndingSoon";
import OnboardingCenter from "./components/Admin/OnboardingCenter/OnboardingCenter";
import "./components/Shared/loadingDots.scss";
import TableauDashboard from "./components/Admin/Dashboard/TableauDashboard";

const App = (props) => {
  const location = useLocation();
  const { user } = useAuth();
  const {
    getExpenseCodes,
    getCompanies,
    selectedOrganisation,
    getAllProducts,
    getAllProductCategories
  } = props;

  const [firstPath, setFirstPath] = useState(null);

  useEffect(() => {
    if (firstPath == null) {
      setFirstPath(location.pathname);
    }
  }, [location.pathname]);

  // useEffect(() => {
  //   if (auth?.currentUser) {
  //     axios.interceptors.request.use(
  //       async (config) => {
  //         config.headers.Authorization =
  //           "Bearer " + (await auth?.currentUser?.getIdToken());
  //         config.headers.IpAddress = ip;
  //         return config;
  //       },
  //       async (error) => {
  //         debugger;
  //         return Promise.reject(error);
  //       }
  //     );
  //   }
  // }, [auth?.currentUser, ip]);

  useEffect(() => {
    if (user) {
      getCompanies(0);
      getExpenseCodes(0);
      getAllProducts(0);
      getAllProductCategories();
    }
  }, [user]);

  // useEffect(() => {
  //   console.log("auth?.currentUser")
  //   console.log(auth?.currentUser)
  // }, [auth])

  // useEffect(() => {
  //   console.log("user")
  //   console.log(user)
  // }, [user])

  // axios.interceptors.response.use(
  //   (response) => {
  //     // Return a successful response
  //     return response;
  //   },
  //   async (error) => {
  //     // Check for a 401 status code
  //     if (error.response.status === 401) {
  //       await logout();
  //       window.location.href = "/";
  //     }
  //     // Return an error response
  //     return Promise.reject(error);
  //   }
  // );

  return (
    <Fragment>
      <Helmet>
        <script>
          {(function (h, o, t, j, a, r) {
            h.hj =
              h.hj ||
              function () {
                (h.hj.q = h.hj.q || []).push(arguments);
              };
            h._hjSettings = { hjid: 3414171, hjsv: 6 };
            a = o.getElementsByTagName("head")[0];
            r = o.createElement("script");
            r.async = 1;
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
          })(
            window,
            document,
            "https://static.hotjar.com/c/hotjar-",
            ".js?sv="
          )}
        </script>
      </Helmet>
      <Layout location={location}>
        <Switch location={location} key={location.pathname}>
          <AuthenticatedRoute exact path="/" component={Home} />
          <AuthenticatedRoute exact path="/home" component={Home} />
          <AuthenticatedRoute
            exact
            path="/influencer-search"
            component={InfluencerSearch}
          />
          <AuthenticatedRoute
            exact
            path="/admin/products"
            component={Products}
          />
          <AuthenticatedRoute
            exact
            path="/job/:id?/:isAdditional?"
            component={Job}
          />
          <AuthenticatedRoute exact path="/admin" component={Admin} />
          <AuthenticatedRoute exact path="/admin/ipad" component={Ipad} />
          <AuthenticatedRoute
            exact
            path="/admin/upload"
            component={UploadVideos}
          />
          <AuthenticatedRoute
            exact
            path="/admin/contractsAndTerms"
            component={ContractsAndTerms}
          />
          <AuthenticatedRoute exact path="/company" component={Company} />
          <AuthenticatedRoute
            exact
            path="/admin/defaults"
            component={Defaults}
          />
          <AuthenticatedRoute exact path="/admin/emails" component={Emails} />
          <AuthenticatedRoute
            exact
            path="/admin/dashboard"
            //component={Dashboard}
            component={TableauDashboard}
          />
          <AuthenticatedRoute
            exact
            path="/admin/settings"
            component={Settings}
          />
          <AuthenticatedRoute
            exact
            path="/admin/exemptions"
            component={MarginExemptionHomePage}
          />
          <AuthenticatedRoute
            exact
            path="/admin/exemptions/:id/:type"
            component={MarginExemptionHomePage}
          />
          <AuthenticatedRoute
            exact
            path="/ending-retainers"
            component={RetainerJobsEndingSoon}
          />
          <AuthenticatedRoute
            exact
            path="/onboarding-center"
            component={OnboardingCenter}
          />
          <AuthenticatedRoute
            exact
            path="/onboarding-center/:id"
            component={OnboardingCenter}
          />
        </Switch>
      </Layout>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  selectedOrganisation: state.organisation.selectedOrganisation,
});

export default connect(mapStateToProps, {
  getExpenseCodes,
  getCompanies,
  getAllProducts,
  getAllProductCategories
})(App);
