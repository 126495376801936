import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { calculateJoblinesTotal } from "../../Utils/JobHelpers";
import { Button } from "@mui/material";
import ConfirmationDialog from "../Shared/ConfirmationDialog";
import axios from "axios";
import { useAuth } from "../../context/auth/AuthContext";

const JobInternalActions = ({
  jobLines,
  crossOrganisationProject,
  saveDraftJob,
  hasJobNameError,
  isInvalidDate,
  saving,
  isReadOnly,
  percent,
  job,
  setopenSnack,
  setsnackSeverity,
  setsnackText,
  setIsReadOnly,
}) => {
  const { user } = useAuth();
  const [
    showManualApproveConfirmationDialog,
    setShowManualApproveConfirmationDialog,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    calculateRemaining();
  }, [jobLines]);

  const calculateRemaining = () => {
    return (
      (crossOrganisationProject?.totalToSpend || 0) -
      calculateJoblinesTotal(jobLines, "exchangedTotal")
    );
  };

  const displayApprovalDialog = async () => {
    await saveDraftJob();
    setShowManualApproveConfirmationDialog(true);
  };

  const handleManualApproval = () => {
    setIsLoading(true);
    axios
      .post(`Job/ManuallyApproveJob`, {
        jobId: job.id,
        reason: "Internal Quote Approval",
        user: user?.email,
        crossOrganisationId: crossOrganisationProject?.id
      })
      .then(() => {
        setopenSnack(true);
        setsnackSeverity("success");
        setsnackText(`Job has been manually approved`);
        setShowManualApproveConfirmationDialog(false)
        setIsReadOnly(true)
      })
      .catch((e) => {
        console.log(e);
        setopenSnack(true);
        setsnackSeverity("error");
        setsnackText(`Unable to approve job`);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div id="job-footer-buttons">
      {showManualApproveConfirmationDialog && (
        <ConfirmationDialog
          open={showManualApproveConfirmationDialog}
          handleClose={() => setShowManualApproveConfirmationDialog(false)}
          submitLabel={"Confirm"}
          title={"Are you sure?"}
          description={`Are you sure you want to manually approve ${job.jobName}`}
          handleSubmit={handleManualApproval}
          loading={isLoading}
        />
      )}
      {!isReadOnly && (
        <>
          <Button
            className={
              percent == null ||
              percent < 0.5 ||
              hasJobNameError ||
              isInvalidDate
                ? ""
                : "job-button"
            }
            variant="contained"
            disabled={
              percent == null ||
              percent < 0.5 ||
              saving ||
              hasJobNameError ||
              isInvalidDate
            }
            onClick={saveDraftJob}
          >
            Save
          </Button>
          <Button
            className={
              (calculateRemaining() > 1 || calculateRemaining() < 0)  ||
              percent == null ||
              percent < 0.5 ||
              hasJobNameError ||
              isInvalidDate
                ? ""
                : "job-button"
            }
            disabled={
              (calculateRemaining() > 1 || calculateRemaining() < 0) ||
              percent == null ||
              percent < 0.5 ||
              hasJobNameError ||
              isInvalidDate
            }
            onClick={displayApprovalDialog}
            variant="contained"
          >
            Manually Approve
          </Button>
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  job: state.job.job,
  jobLines: state.job.job.jobLines,
  crossOrganisationProject: state.job.job.crossOrganisationProject,
});

export default connect(mapStateToProps, {})(JobInternalActions);
