import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Typography,
  IconButton,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Grid,
  TableRow,
  TableCell,
  Collapse,
  Box,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Check as CheckIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import axios from "axios";
import ContactTable from "./ContactTable";
import AddNewCompanyDialog from "../../Companies/AddNewCompanyDialog";
import { getCompanies, searchCompanies } from "../../../reduxActions/company";
import CompanyFilters from "./CompanyFilters";
import CompanyDialog from "../../Companies/CompanyDialog";

function CompanyRow(props) {
  const { company, openCompanyDialog } = props;

  return (
    <React.Fragment>
      <tr>
        <td className="text-center">{company.name}</td>
        <td className="text-center">{company.registeredName}</td>
        <td>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              className="default-icon"
              onClick={() => openCompanyDialog(company)}
            >
              <EditIcon style={{ color: "black" }} />
            </IconButton>
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
}

const Company = (props) => {
  const {
    getCompanies,
    selectedOrganisation,
    companies,
    companyFilter,
    searchCompanies
  } = props;

  const [filteredCompanies, setFilteredCompanies] = useState([]);

  const [openCreateCompanytDialog, setOpenCreateCompanytDialog] = useState(
    false
  );
  const [selectedCompany, setSelectedCompany] = useState({
    name: "",
    overrideLimit: 0,
    address: "",
    registeredName: "",
    isNzbusiness: true,
    companyLocation: "NEW_ZEALAND",
    currency: "NZD",
    gstregistered: true,
    paymentTerms: "20",
    person: [],
  });

  const emptyCompany = {
    name: "",
    overrideLimit: 0,
    address: "",
    registeredName: "",
    companySize: "",
    isNzbusiness: true,
    companyLocation: "NEW_ZEALAND",
    currency: "NZD",
    gstregistered: true,
    paymentTerms: "20",
    person: [],
    mediaAdminFee: 15,
    onboardingCentreFkNavigation: {
      googleDrive: true,
      airtable: true,
      onePasswordVault: true,
      asana: true,
      sendWelcomeGift: true,
    },
  };

  useEffect(() => {
    if (companies?.length === 0) {
      getCompanies(0);
    }
  }, []);

  const openCompanyDialog = (company) => {
    setSelectedCompany(company);
    setOpenCreateCompanytDialog(true);
  };

  const handleCloseNewCompanyDialog = () => {
    setOpenCreateCompanytDialog(false);
    if (!selectedCompany.id) {
      setSelectedCompany(emptyCompany);
    }
  };

  const handleCloseNewCompanyDialogAfterCreated = () => {
    setOpenCreateCompanytDialog(false);
  };

  const handleNewCompanyCreated = (company) => {
    setSelectedCompany(company);
  };

  const updateMultiPropertiesCompany = (updatedCompany) => {
    setSelectedCompany(updatedCompany);
  }

  const handleNewContactCreated = (contact) => {
    // setSelectedContact(contact);
    // updateJobStore("contactId", contact.id);
    // updateJobStore("contact", contact);
    // setHasChanges(true);
    // setsnackText("Contact Saved");
    // setsnackSeverity("info");
    // setopenSnack(true);
  };

  

  useEffect(() => {
    if (companyFilter.companyId != 0) {
      setFilteredCompanies(
        companies.filter((c) => c.id === companyFilter.companyId)
      );
    } else {
      setFilteredCompanies(companies);
    }
  }, [selectedOrganisation, companyFilter, companies]);

  return (
    <div id={`companies-table-container`} style={{ margin: "0px 20%" }}>
      {selectedCompany && (
        <CompanyDialog
          open={openCreateCompanytDialog}
          handleCloseNewCompanyDialog={handleCloseNewCompanyDialog}
          selectedCompany={selectedCompany}
          setselectedCompany={setSelectedCompany}
          handleCloseNewCompanyDialogAfterCreated={
            handleCloseNewCompanyDialogAfterCreated
          }
          handleNewCompanyCreated={handleNewCompanyCreated}
          handleNewContactCreated={handleNewContactCreated}
          updateMultiPropertiesCompany={updateMultiPropertiesCompany}
        />
      )}
      <Grid container style={{ padding: "15px 0px" }}>
        <Grid item xs={4} md={4} lg={4}>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            alignSelf={"center"}
          >
            Companies
          </Typography>
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <CompanyFilters />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <div style={{ float: "right" }}>
            <Button
              size="small"
              color="primary"
              variant="contained"
              className="default-button"
              onClick={() => {
                setSelectedCompany(emptyCompany);
                setOpenCreateCompanytDialog(true);
              }}
            >
              Create Company
            </Button>
          </div>
        </Grid>
      </Grid>
      <table className="table table-striped table-hover text-nowrap mb-0 table-sm">
        <thead className="thead-magnesium">
          <tr>
            <th className="text-center">Name</th>
            <th className="text-center">Registered Name</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredCompanies.length > 0 ? (
            filteredCompanies.map((company) => (
              <CompanyRow
                company={company}
                id={`${company.id}-row`}
                openCompanyDialog={openCompanyDialog}
              />
            ))
          ) : (
            <div></div>
          )}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedOrganisation: state.organisation.selectedOrganisation,
  companies: state.company.companies,
  companyFilter: state.company.companyFilter,
});

export default connect(mapStateToProps, {
  getCompanies,
  searchCompanies
})(Company);
