import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import {
  EditorState,
  convertToRaw,
  compositeDecorator,
  convertFromHTML,
  ContentState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import React, { useState, useEffect } from "react";
import axios from "axios";

const EditEmailDialog = ({
  open,
  handleClose,
  selectedEmail,
  setSelectedEmail,
  handleEditEmail,
}) => {
  const [editorState, seteditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (selectedEmail) {
      const blocksFromHTML = convertFromHTML(selectedEmail.template);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      seteditorState(EditorState.createWithContent(state, compositeDecorator));
    }
  }, []);

  const onEditorStateChange = (editorState) => {
    let template = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    setSelectedEmail({
      ...selectedEmail,
      template: template,
    });
    seteditorState(editorState);
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle id="quote-preview-dialog-title">
        {selectedEmail?.name}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2} style={{ height: "100%" }}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className="paragraph-default">
                  <Editor
                    editorState={editorState}
                    wrapperClassName="jobdescription-text-editor-wrapper"
                    editorClassName="jobdescription-text-editor"
                    onEditorStateChange={onEditorStateChange}
                    handlePastedText={() => false}
                    toolbar={{
                      options: ["inline", "fontSize", "list"],
                      inline: {
                        options: ["bold", "italic", "underline"],
                      },
                      list: {
                        options: ["unordered", "ordered"],
                      },
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={{ textAlign: "right" }}>
                  <Button
                    className="job-button"
                    onClick={handleEditEmail}
                    variant="contained"
                  >
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EditEmailDialog;
