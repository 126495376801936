import {
  Table,
  TableBody,
  IconButton,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import JobLinesTableRow from "../JobLinesTableRow";
import AddIcon from "@mui/icons-material/Add";
import "./socialitesJobLinesTable.scss";
const SocialitesJobLinesTable = (props) => {
  const [contextMenu, setContextMenu] = React.useState(null);
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleCloseAddRow = () => {
    setContextMenu(null);
  };

  const handleAddMultipleRows = async (count) => {
    await props.handleAddNewJobLineButton(props.category, count);
    handleCloseAddRow();
  };
  const handleAddTask = async (product) => {
    await props.handleAddNewJobLineButton(props.category, 1, product);
    handleCloseAddRow();
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Table
        className="jobLineItem job-line-table"
        aria-label="simple table"
        size="small"
      >
        <TableBody style={{ height: 10 }}>
          {props.jobLines
            .filter((j) => j.jobCategoryGuid === props.category?.guid)
            ?.sort((a, b) => a.order - b.order)
            .map((j, index) => (
              <JobLinesTableRow
                key={j.guid}
                jobLine={{ ...j }}
                index={index}
                category={props.category}
                products={props.products}
                jobLines={props.jobLines}
                handleChangeProduct={props.handleChangeProduct}
                handleOnChangeJobLine={props.handleOnChangeJobLine}
                handleAddNewJobLineButton={props.handleAddNewJobLineButton}
                handleDeleteJobLine={props.handleDeleteJobLine}
                getMargin={props.getMargin}
                handleChangeProductRateType={props.handleChangeProductRateType}
                isReadOnly={props.isReadOnly}
                selectedOrganisation={props.selectedOrganisation}
                forceSaveDraftJob={props.forceSaveDraftJob}
                setShowMiscPopup={props.setShowMiscPopup}
                currency={props.currency}
                company={props.company}
                handleOpenBookingFeeDialog={props.handleOpenBookingFeeDialog}
              />
            ))}
          {props.provided?.placeholder}
        </TableBody>

        {/* <MediaContractItemsTotal
            list={props.mediaContract.mediaContractItem}
          /> */}
      </Table>
      {!props.isReadOnly && (
        <div className="socialites-job-lines-table-add-row">
          <Button
            className="default-button-outlined socialites-job-lines-table-add-row-btn"
            onClick={handleContextMenu}
            startIcon={<AddIcon />}
          >
            Add a task
          </Button>
          <Menu
            open={contextMenu !== null}
            onClose={handleCloseAddRow}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
          >
            {props.products
              .filter((p) => p.type === props.category.type)
              .sort((a, b) => a.displayOrder - b.displayOrder)
              .map((p) => (
                <MenuItem key={p.id} onClick={() => handleAddTask(p)}>
                  {p.name}
                </MenuItem>
              ))}
          </Menu>
          {!props.showDescription && (
            <Button
              className="default-button-outlined socialites-job-lines-table-add-row-btn"
              onClick={() => props.setShowDescription(true)}
              startIcon={<AddIcon />}
            >
              Add a description
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  currency: state.job.job.currency,
  selectedOrganisation: state.organisation.selectedOrganisation,
});

export default connect(mapStateToProps, {})(SocialitesJobLinesTable);
