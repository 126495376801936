import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from "@mui/material";
import React from "react";
import ProcessingSpinnerV2 from "./ProcessingSpinnerV2";

const ProcessingSpinnerDialogV2 = ({
    loading,
    duration,
    message
}) => {
    return (
        <Dialog
            open={loading}
            aria-labelledby="processing-spinner-dialogV2-title"
            aria-describedby="processing-spinner-dialogV2-description"
        >
            <DialogTitle id="processing-spinner-dialogV2-title" style={{textAlign: "center"}}>
                <Typography variant="h5">{message}</Typography>
            </DialogTitle>
            <DialogContent>
                <ProcessingSpinnerV2 duration={duration} unitTime="seconds" />
            </DialogContent>
        </Dialog>
    );
};

export default ProcessingSpinnerDialogV2;