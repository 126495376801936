import React from "react";
import {
  Alert,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { currencySymbolFormat } from "../../../Utils/GeneralUtils";
import { getCurrencies, getCurrencyObject } from "../../../Utils/ExchangeRateUtils";

const InternalRevenueTable = ({
  internalRevenue,
  handleUseInternalRevenue,
  handleRemoveInternalRevenue,
  isSavingInternalRevenue,
  subtotal,
  totalAllocated
}) => {
  return (
    <TableContainer component={Paper} style={{ padding: 20 }}>
      {isSavingInternalRevenue && (<Alert severity="info">Saving your changes.</Alert>)}
      <Typography variant="h6">Usable Budget</Typography>
      <Table size="small">
        <TableHead>
          <TableCell>
            <Typography variant="caption"><b>Job Name</b></Typography>
          </TableCell>
          <TableCell>
            <Typography variant="caption"><b>Date</b></Typography>
          </TableCell>
          <TableCell>
            <Typography variant="caption"><b>Category</b></Typography>
          </TableCell>
          <TableCell>
            <Typography variant="caption"><b>Total</b></Typography>
          </TableCell>
          <TableCell></TableCell>
        </TableHead>
        <TableBody>
          {internalRevenue?.map((record, index) => (
            <TableRow key={index}>
              <TableCell>
                <Typography variant="caption">
                  {`[${record.jobId}] ${record.job?.jobName}`}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {dayjs.utc(record.billingDate || record.job?.approved).local().format("MMM YYYY")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">{record.category}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {record.remaining?.toLocaleString("en-UK", {
                    style: "currency",
                    currency: getCurrencyObject(record.currency|| "NZD").value ,
                    minimumFractionDigits: 2,
                  })}
                </Typography>
              </TableCell>
              <TableCell style={{ width: 220, textAlign: "right" }}>
                {record.status === "OPEN" && record.remaining > 0 && (
                  <Button
                    onClick={() => handleUseInternalRevenue(record)}
                    variant="outlined"
                    size="small"
                    disabled={isSavingInternalRevenue || subtotal - totalAllocated === 0}
                  //style={subtotal-totalAllocated === 0 ? {display: "none"} : {}}
                  >
                    Use
                  </Button>
                )}
                {/* {record.status === "CLOSED" && (
                  <Button
                    onClick={() => handleRemoveInternalRevenue(record.id)}
                    variant="outlined"
                    size="small"
                    disabled={isSavingInternalRevenue || subtotal - totalAllocated === 0}
                  //style={subtotal-totalAllocated === 0 ? {display: "none"} : {}}
                  >
                    Remove from this Job
                  </Button>
                )} */}
              </TableCell>
            </TableRow>
          ))}
          {internalRevenue && (
            <TableRow key={internalRevenue?.length + 1}>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <Typography variant="caption">
                  <b>{`${currencySymbolFormat(subtotal - totalAllocated)}`}</b> left to allocate
                </Typography>
              </TableCell>
              <TableCell style={{ width: 220, textAlign: "right" }}></TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InternalRevenueTable;
