import React from "react";
import { ResponsivePie } from "@nivo/pie";
import CustomChartTooltip from "../CustomChartTooltip";
const RevenueClientsPieChart = ({ revenueData, handleChangeFilter,filters }) => {
  return (
    <div className="dashboard-charts-400-height">
      <ResponsivePie
        data={revenueData.clientsPieChart}
        id={"label"}
        value="valuePercentage"
        valueFormat={(value) => `${value}%`}
        tooltip={({ datum: { id, value, color, data } }) => (
          <CustomChartTooltip
            id={id}
            percentage={data.valuePercentage}
            value={data.value}
          />
        )}
        animate={false}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["darker", 2]],
        }}
        onClick={(item, e) => {
          e.preventDefault();
          if(filters.organisationId){
            handleChangeFilter("companyId", item.data.id);
          }else{
            handleChangeFilter("organisationId", item.data.id);
          }
        }}
        defs={[
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "rgba(255, 255, 255, 0.3)",
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "rgba(255, 255, 255, 0.3)",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
      />
    </div>
  );
};

export default RevenueClientsPieChart;
