import { createAction } from "@reduxjs/toolkit";
import axios from "axios";
import { v1 as uuidv1 } from "uuid";

export const getAllProducts = (organisationId) => async (dispatch) => {
  try {
    const res = await axios.get(`Product/GetAllProducts/` + organisationId);
    dispatch(getAllProductsSuccess(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const getAllProductsSuccess = createAction("GET_ALL_PRODUCTS");

export const getAllProductCategories = () => async (dispatch) => {
  try {
    const res = await axios.get(`Product/GetAllProductCategories`);
    dispatch(getAllProductCategoriesSuccess(res.data));
  } catch (error) {
    console.log(error);
  }
};
export const getAllProductCategoriesSuccess = createAction("GET_ALL_PRODUCT_CATEGORIES");

export const saveProductCategories = (productCategories) => async (dispatch) => {
  try {
    // const res = await axios.post(`Product/SaveProductCategories`,productCategories );
    dispatch(saveProductCategoriesSuccess(productCategories));
  } catch (error) {
    console.log(error);
  }
};
export const saveProductCategoriesSuccess = createAction("SAVE_PRODUCT_CATEGORIES");

export const updateProductDetails = (product) => async (dispatch) => {
  await dispatch(updateProductDetailsSuccess(product));
  try {
    const res = await axios.post(`Product/SaveProduct`, product);
  } catch (error) {
    console.log("updateProductDetails");
    console.log(error);
  }
};

export const updateProductDetailsSuccess = createAction(
  "UPDATE_PRODUCT_DETAILS"
);

export const batchSaveProducts = (productList) => async (dispatch) => {
  try {
    await dispatch(batchSaveProductsSuccess(productList));
    const res = await axios.post(`Product/BatchSaveProducts`, productList);
  } catch (error) {
    console.log("batchSaveProducts");
    console.log(error);
  }
};

export const batchUpdateProductStore = (productList) => async (dispatch) => {
  await dispatch(batchSaveProductsSuccess(productList));
};

export const batchSaveProductsSuccess = createAction("BATCH_SAVE_PRODUCTS");

export const createBlankProduct = (organisationId) => async (dispatch) => {
  try {
    const res = await axios.post(`Product/SaveProduct`, {
      id: 0,
      isArchived: 0,
      allowPassThrough: 0,
      organisationId: organisationId,
    });
    await dispatch(createProductSuccess(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const addBlankProductToStore = (organisationId) => async (dispatch) => {
  try {
    await dispatch(createProductSuccess({
      id: uuidv1(),
      type: "",
      name: "",
      rateType: "",
      description: "",
      costPrice: null,
      sellPrice: null,
      minSellPrice: null,
      enterpriseAccountCode: null,
      smbAccountCode: null,
      retainerEnterpriseAccountCode: null,
      retainerSmbAccountCode: null,
      xeroAccountCode: null,
      passThrough: 0,
      taskType: null,
      displayOrder: null,
      requiresJournaling: 0,
      organisationId: organisationId
    }));
  } catch (error) {
    console.log(error);
  }
};

export const createNewProduct = (product) => async (dispatch) => {
    try {
      await dispatch(createProductSuccess(product));
    } catch (error) {
      console.log(error);
    }
  };

export const createProductSuccess = createAction("CREATE_PRODUCT");

export const archiveProduct = (productIdList) => async (dispatch) => {
  try {
    dispatch(archiveProductSuccess(productIdList));
  } catch (error) {
    console.log(error);
  }
};

export const archiveProductSuccess = createAction("ARCHIVE_PRODUCTS");
