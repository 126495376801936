import React, { Fragment } from "react";
import dayjs from "dayjs";
import PaymentCardItem from "./PaymentCardItem";
import "./paymentCards.scss";
import RetainerPaymentCardItem from "./RetainerPaymentCardItem";
import PaymentCardMediaItem from "./PaymentCardMediaItem";
const PaymentCards = (props) => {
  const getPaymentTitle = (index) => {
    if (props.paymentType === "FULL_PRE_PAYMENT") {
      return "Full Pre Payment";
    } else if (props.paymentType === "FULL_POST_PAYMENT") {
      return "Full Post Payment";
    } else if (props.paymentType === "HALF_DEPOSIT") {
      return getNumberWithOrdinal(index + 1) + " Invoice";
    } else if (props.paymentType === "END_OF_MONTH_BILLING") {
      return "End Of Month Billing";
    } else {
      return getNumberWithOrdinal(index + 1) + " Invoice";
    }
  };

  const getNumberWithOrdinal = (number) => {
    var suffix = ["th", "st", "nd", "rd"],
      value = number % 100;
    return number + (suffix[(value - 20) % 10] || suffix[value] || suffix[0]);
  };

  const handleUpdateInvoice = (invoice) => {
    const invoicesClone = props.invoices.map((i) =>
      i.order === invoice.order ? invoice : i
    );
    props.handleUpdateInvoice(invoicesClone);
  };

  const checkIfContainsMediaCosts = () => {
    // Filter media categories
    const mediaCategories = props.job.jobCategories.filter(
      (j) => j.type === "Media Spend"
    ).map((j) => j.guid);

    // Calculate total job lines
    const totalJobLines = props.job.jobLines.filter(
      (jl) => mediaCategories.includes(jl.jobCategoryGuid)
    ).reduce((total, jl) => total + (jl.total || 0), 0);

    const totalExchangedJobLines = props.job.jobLines.filter(
      (jl) => mediaCategories.includes(jl.jobCategoryGuid)
    ).reduce((total, jl) => total + (jl.exchangedTotal || 0), 0);

    return {totalJobLines, totalExchangedJobLines};
  };

  return (
    <div className="payment-cards-root">
      {props.job?.isRetainer ? (
        <RetainerPaymentCardItem
          job={props.job}
          title={
            getPaymentTitle(1) + `${props.job.isRetainer ? " - Retainer" : ""}`
          }
          isReadOnly={props.isReadOnly}
          invoices={props.invoices}
          currency={props.job.currency}
        />
      ) : (
        props.invoices?.map((invoice, index) => (
          <PaymentCardItem
            key={index}
            title={
              getPaymentTitle(index) +
              `${props.job.isRetainer ? " - Retainer" : ""}`
            }
            minWidth={props.paymentType === "ONE_THIRD_DEPOSIT" && "auto"}
            isReadOnly={props.isReadOnly}
            invoice={invoice}
            currency={props.job.currency}
            isBalance={props.paymentType === "HALF_DEPOSIT" && index === 1}
            isTwoThirds={
              index + 1 === 2 && props.paymentType === "ONE_THIRD_DEPOSIT"
            }
            handleUpdateInvoice={handleUpdateInvoice}
          />
        ))
      )}

      {checkIfContainsMediaCosts().totalJobLines > 0 && (
        <PaymentCardMediaItem
        title={
          `Media Costs`
        }
        totalJobLines={checkIfContainsMediaCosts().totalJobLines}
        totalExchangedJobLines={checkIfContainsMediaCosts().totalExchangedJobLines}
        currency={props.job.currency}
      />
      )}
    </div>
  );
};

export default PaymentCards;
