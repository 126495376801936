import { combineReducers } from "redux";
import product from "./productReducer";
import data from "./dataReducer";
import company from "../reducers/companyReducer";
import job from "../reducers/jobReducer";
import invoice from "../reducers/invoiceReducer";
import organisation from "../reducers/organisationReducer";
import ThemeOptions from "../reducers/ThemeOptions";

export default combineReducers({
  product,
  data,
  company,
  job,
  invoice,
  organisation,
  ThemeOptions
});
