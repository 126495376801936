import React, { Fragment, useState } from "react";
import JobLinesAccordionItem from "./JobLinesAccordionItem";
import { connect } from "react-redux";
import { DragDropContext } from "react-beautiful-dnd";
import {
  reorderJobLinesSameCategory,
  moveJobLinesBetweenCategories,
} from "../../reduxActions/job";
import {
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
} from "@mui/material";
import MarginDisplay from "../Shared/MarginDisplay";
import { Help } from "@mui/icons-material";
import ExchangedTotal from "./total/ExchangedTotal";
import TotalRemaining from "./total/TotalRemaining";

const JobLinesAccordion = (props) => {
  const {
    jobLines,
    reorderJobLinesSameCategory,
    moveJobLinesBetweenCategories,
    currency,
    crossOrganisationProject,
    jobOrganisation
  } = props;

  const calculateGrandTotal = () => {
    return jobLines.reduce((a, b) => a + b.total, 0);
  };

  const calculateGrandTotalMargin = () => {
    return jobLines.reduce((a, b) => a + b.margin, 0);
  };

  const calculateTotalMarginPercentage = () => {
    const total = calculateGrandTotal();
    const marginProfit = calculateGrandTotalMargin();
    const grossProfitPercentage = total > 0 ? marginProfit / total : 0;
    return grossProfitPercentage;
  };

  const onDragEnd = (result) => {
    if (!props.isReadOnly) {
      const { source, destination } = result;
      // dropped outside the list
      if (!destination) {
        return;
      }
      const fromCategory = source.droppableId;
      const toCategory = destination.droppableId;

      // same category
      if (fromCategory === toCategory) {
        reorderJobLinesSameCategory(
          fromCategory,
          source.index,
          destination.index
        );
      } else {
        moveJobLinesBetweenCategories(
          fromCategory,
          toCategory,
          source,
          destination
        );
      }
    }
  };

  const getItems = () => {
    return [...props.jobCategories]
      .sort((a, b) => a.order - b.order)
      .map((c) => (
        <JobLinesAccordionItem
          key={c.guid}
          category={c}
          handleCollapseAccordionItem={props.handleCollapseAccordionItem}
          jobLines={jobLines && jobLines.filter((j) => j.category === c.name)}
          products={props.products}
          handleChangeProduct={props.handleChangeProduct}
          handleOnChangeJobLine={props.handleOnChangeJobLine}
          handleAddNewJobLineButton={props.handleAddNewJobLineButton}
          handleDeleteJobLine={props.handleDeleteJobLine}
          getMargin={props.getMargin}
          handleChangeProductRateType={props.handleChangeProductRateType}
          isReadOnly={props.isReadOnly}
          jobOrganisation={jobOrganisation}
        />
      ));
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div >
        {/* className="table-responsive" */}
        <Table className="table text-nowrap mb-0 job-line-table">
          <TableHead>
            <TableRow className="production-table-header job-lines-table-row-header">
              <TableCell></TableCell>
              <TableCell>Product</TableCell>
              <TableCell>Rate</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Min Price</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Margin</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
        </Table>
        <Table className="table text-nowrap mb-0 production-job-lines job-line-table">
          <TableBody>{getItems()}</TableBody>
        </Table>
        {/* <table style={{width: "100%"}}>
          <thead>
            <th style={{textAlign: "end", width: "82.5%"}}>Grand Total:</th>
            <th width="5%" style={{textAlign: "end"}}>
              {calculateGrandTotal()?.toLocaleString("en-NZ", {
                style: "currency",
                currency: "NZD",
                minimumFractionDigits: 2,
              })}
            </th>
            <th width="5%" style={{textAlign: "end"}}>
              {calculateGrandTotalMargin()?.toLocaleString("en-NZ", {
                style: "currency",
                currency: "NZD",
                minimumFractionDigits: 2,
              })}
            </th>
            <th width="auto" style={{textAlign: "end"}}>
              {calculateTotalMarginPercentage()?.toLocaleString("en-NZ", {
                style: "percent",
              })}
            </th>
          </thead>
        </table> */}
        <div className="grand-total">
          <div>Grand Total:</div>
          <div style={{ marginLeft: "25px" }}>
            {calculateGrandTotal()?.toLocaleString("en-UK", {
              style: "currency",
              currency: currency || "NZD",
              minimumFractionDigits: 2,
            })}
          </div>
          <div style={{ marginLeft: "25px" }}>
            <MarginDisplay
              total={calculateGrandTotal()}
              margin={calculateGrandTotalMargin()}
              currency={currency || "NZD"}
            />
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ marginLeft: "25px" }}>
              {calculateTotalMarginPercentage()?.toLocaleString("en-NZ", {
                style: "percent",
              })}
            </div>
            <div style={{ position: "relative" }}>
              <div className="percentage-tooltip-icon">
                <Tooltip
                  className="tooltip-css"
                  title="Percentage: Margin / Total"
                  placement="top"
                  arrow
                >
                  <Help />
                </Tooltip>
              </div>
            </div>
          </div>

          {/* <div style={{ marginLeft: "63px" }}></div> */}
        </div>
        {!!crossOrganisationProject && (<TotalRemaining />)}
      </div>
    </DragDropContext>
  );
};
const mapStateToProps = (state) => ({
  crossOrganisationProject: state.job.job.crossOrganisationProject,
  currency: state.job.job.currency,
  jobLines: state.job.job.jobLines,
});

export default connect(mapStateToProps, {
  reorderJobLinesSameCategory,
  moveJobLinesBetweenCategories,
})(JobLinesAccordion);
