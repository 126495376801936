import { Typography } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const InvoiceDateDisplay = ({ invoice }) => {
  return (
    <Fragment>
      <HtmlTooltip
        title={
          <React.Fragment>
            <div>
              <b>{"Due Date: "}</b>{" "}
              {invoice.dueDate
                ? dayjs(invoice.dueDate).format("DD/MM/YYYY")
                : " - "}
            </div>
          </React.Fragment>
        }
      >
        <span>
          {invoice.date ? dayjs(invoice.date).format("DD/MM/YYYY") : " - "}
        </span>
      </HtmlTooltip>
    </Fragment>
  );
};

export default InvoiceDateDisplay;
