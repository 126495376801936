import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import SpellCheckTextField from "../Shared/SpellCheckTextField";
import { getCategoryTypes } from "../../Utils/JobHelpers";
import { connect } from "react-redux";
import "./products.scss";
import { isEmpty, isNumeric } from "../../Utils/GeneralUtils";

const errorState = {
  name: "",
  type: "",
  costPrice: "",
  sellPrice: "",
  minSellPrice: "",
  rateType: false,
  xeroAccountCode: "",
  showOnTop: false,
};

const errorStateTranslation = (state) => {
  switch (state) {
    case "name":
      return "Name";
    case "type":
      return "Type";
    case "costPrice":
      return "Cost Price";
    case "sellPrice":
      return "Sell Price";
    case "minSellPrice":
      return "Minimum Sell Price";
    case "rateType":
      return "Rate Type";
    case "xeroAccountCode":
      return "Account Code";
    default:
      return "";
  }
};

const taskTypes = [
  { name: "Expense", value: "E" },
  { name: "Expense - Catering", value: "ECT" },
  { name: "Expense - Location", value: "ELF" },
  { name: "Task", value: "T" },
];

const AddNewProductPopup = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const {
    open,
    setOpen,
    expenseCodes,
    selectedOrganisation,
    products,
    handleCreateNewProduct,
    productCategories,
  } = props;

  const [newProduct, setNewProduct] = useState({
    type: "",
    name: "",
    rateType: "",
    costPrice: null,
    sellPrice: null,
    minSellPrice: null,
    passThrough: 0,
    taskType: "T",
    organisationId: 2,
    calculationMethod: "Margin",
  });
  const [errorForm, setErrorForm] = useState(errorState);

  const handleClose = () => {
    setNewProduct({
      type: "",
      name: "",
      rateType: "",
      costPrice: null,
      sellPrice: null,
      minSellPrice: null,
      passThrough: 0,
      taskType: "T",
      organisationId: 2,
      calculationMethod: "Margin",
    });
    setErrorForm({
      name: "",
      type: "",
      costPrice: "",
      sellPrice: "",
      minSellPrice: "",
      rateType: false,
      showOnTop: false,
    });
    setOpen(false);
  };

  const errorMessage = () => {
    var propertyArr = [];
    for (const property in errorForm) {
      propertyArr.push(property);
    }
    return (
      <div
        style={{ display: "flex", flexDirection: "column", color: "#d32f2f" }}
      >
        <Typography variant="caption">Missing:</Typography>
        {propertyArr.map((p) => {
          if (errorForm[p] !== "" && p !== "showOnTop") {
            return (
              <Typography variant="caption">
                - {errorStateTranslation(p)}
              </Typography>
            );
          } else {
            return "";
          }
        })}
      </div>
    );
  };

  const validateData = () => {
    const tempError = { ...errorForm };
    let validForm = true;
    if (isEmpty(newProduct.name) === true) {
      tempError.name = "Required field";
      validForm = false;
    } else {
      tempError.name = "";
    }
    if (isEmpty(newProduct.type) === true) {
      tempError.type = "Required field";
      validForm = false;
    } else {
      tempError.type = "";
    }
    if (isEmpty(newProduct.costPrice) === true) {
      tempError.costPrice = "Required field";
      validForm = false;
    } else {
      tempError.costPrice = "";
    }
    if (isEmpty(newProduct.sellPrice) === true) {
      tempError.sellPrice = "Required field";
      validForm = false;
    } else {
      tempError.sellPrice = "";
    }
    if (isEmpty(newProduct.minSellPrice) === true) {
      tempError.minSellPrice = "Required field";
      validForm = false;
    } else {
      tempError.minSellPrice = "";
    }
    if (isEmpty(newProduct.rateType) === true) {
      tempError.rateType = "Required field";
      validForm = false;
    } else {
      tempError.rateType = "";
    }

    if (!validForm) {
      tempError.showOnTop = true;
      setErrorForm(tempError);
    } else {
      tempError.showOnTop = false;
      return validForm;
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="add-new-product-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        id="add-new-product-dialog-title"
        className="new-product-container"
      >
        Add Product
      </DialogTitle>
      <DialogContent className="new-product-container">
        <Grid container spacing={2}>
          {errorForm.showOnTop && (
            <Grid item xs={12} md={12} lg={12}>
              {errorMessage()}
            </Grid>
          )}
          <Grid item xs={4} md={4} lg={4}>
            <Autocomplete
              value={productCategories.find(
                (c) => c.id === newProduct.productCategoryId
              )}
              options={productCategories}
              onChange={(event, newValue) => {
                setNewProduct({
                  ...newProduct,
                  type: newValue.name,
                  productCategoryId: newValue.id,
                });
              }}
              getOptionLabel={(option) => option.name}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Type *"
                  size="small"
                  fullWidth={true}
                  variant="filled"
                  error={errorForm.type}
                />
              )}
            />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <SpellCheckTextField
              id="new-product-name"
              label="Name *"
              size="small"
              fullWidth={true}
              value={newProduct.name}
              handleOnChange={(newValue) =>
                setNewProduct({ ...newProduct, name: newValue })
              }
              error={errorForm.name}
              errorMessage={errorForm.name}
            />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <Autocomplete
              value={newProduct.rateType}
              options={["Hour", "Day", "Half Day", "Open Budget", "Per Person"]}
              onChange={(event, newValue) => {
                setNewProduct({
                  ...newProduct,
                  rateType: newValue,
                  calculationMethod:
                    newValue === "Open Budget" ? "Margin" : "Markup",
                });
              }}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Rate Type *"
                  size="small"
                  fullWidth={true}
                  variant="filled"
                  error={errorForm.rateType}
                />
              )}
              disableClearable
            />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <Autocomplete
              options={["Margin", "Markup"]}
              value={newProduct.calculationMethod}
              onChange={(e, newValue) => {
                setNewProduct({ ...newProduct, calculationMethod: newValue });
              }}
              disableClearable
              disabled={newProduct.rateType !== "Open Budget"}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  size="small"
                  fullWidth
                  label="Margin or Markup %"
                />
              )}
            />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <SpellCheckTextField
              id="product-costPrice"
              label={`${
                newProduct.rateType === "Open Budget"
                  ? `Default ${newProduct.calculationMethod} %`
                  : "Cost Price *"
              }`}
              size="small"
              fullWidth={true}
              value={newProduct.costPrice}
              handleOnChange={(newValue) => {
                if (isNumeric(newValue)) {
                  setNewProduct({
                    ...newProduct,
                    costPrice: parseFloat(newValue),
                  });
                } else if (newValue === "") {
                  setNewProduct({ ...newProduct, costPrice: null });
                }
              }}
              type="number"
              error={errorForm.costPrice}
              errorMessage={errorForm.costPrice}
            />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <SpellCheckTextField
              id="product-sell-price"
              label="Sell Price *"
              size="small"
              fullWidth={true}
              value={newProduct.sellPrice}
              handleOnChange={(newValue) => {
                if (isNumeric(newValue)) {
                  setNewProduct({
                    ...newProduct,
                    sellPrice: parseFloat(newValue),
                  });
                } else if (newValue === "") {
                  setNewProduct({ ...newProduct, sellPrice: null });
                }
              }}
              type="number"
              error={errorForm.sellPrice}
              errorMessage={errorForm.sellPrice}
            />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <SpellCheckTextField
              id="product-min-sell-price"
              label={`${
                newProduct.rateType === "Open Budget"
                  ? `Minimum ${newProduct.calculationMethod} %`
                  : "Minimum Sell Price *"
              }`}
              size="small"
              fullWidth={true}
              value={newProduct.minSellPrice}
              handleOnChange={(newValue) => {
                if (isNumeric(newValue)) {
                  setNewProduct({
                    ...newProduct,
                    minSellPrice: parseFloat(newValue),
                  });
                } else if (newValue === "") {
                  setNewProduct({ ...newProduct, minSellPrice: null });
                }
              }}
              type="number"
              error={errorForm.minSellPrice}
              errorMessage={errorForm.minSellPrice}
            />
          </Grid>

          <Grid item xs={4} md={4} lg={4}>
            <Autocomplete
              value={taskTypes.find((t) => t.value === newProduct.taskType)}
              options={taskTypes}
              onChange={(event, newValue) => {
                setNewProduct({ ...newProduct, taskType: newValue?.value });
              }}
              getOptionLabel={(option) => option?.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Task Type"
                  size="small"
                  fullWidth={true}
                  variant="filled"
                />
              )}
              disableClearable
            />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <Autocomplete
              value={newProduct.allowPassThrough === 1 ? "Yes" : "No"}
              options={["Yes", "No"]}
              onChange={(event, newValue) => {
                var newPassThrough = 0;
                if (newValue === "Yes") {
                  newPassThrough = 1;
                }
                setNewProduct({
                  ...newProduct,
                  allowPassThrough: newPassThrough,
                });
              }}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Allow Pass Through"
                  size="small"
                  fullWidth={true}
                  variant="filled"
                />
              )}
              disableClearable
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="new-product-container">
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (validateData()) {
              handleCreateNewProduct(newProduct);
            }
          }}
          autoFocus
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  expenseCodes: state.data.expenseCodes,
  selectedOrganisation: state.organisation.selectedOrganisation,
  products: state.product.products,
  productCategories: state.product.productCategories,
});

export default connect(mapStateToProps, {})(AddNewProductPopup);
